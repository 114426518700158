import {
    CreateIdentityRequest,
    CreateStairRequest, DeleteIdentityRequest, GetJobStatusRequest,
    GetStairRequest, GetStairVariablesRequest,
    SendJobToStairRequest, SetStairVariableRequest,
    UpdateIdentityRequest,
    UpdateStairRequest
} from "../requests/stairsService";
import {methodDelete, methodGet, methodPost, methodPut, verticalFetch} from "./httpRequests";
import {rowsPerTablePage, StairsServiceUrl} from "../constants";
import { Calibration } from "../../components/types/jobs/jobs";

export const CreateStair = (r: CreateStairRequest) => {
    return verticalFetch(methodPost, StairsServiceUrl, JSON.stringify(r))
}

export const GetAllStairs = (page: string, sort: string, search: string) => {
    return verticalFetch(methodGet, `${StairsServiceUrl}?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`)
}

export const UpdateStair = (r: UpdateStairRequest) => {
    return verticalFetch(methodPut, `${StairsServiceUrl}/${r.id}`, JSON.stringify(r))
}

export const UpdateStairAlarm = (id: string, r: any) => {
    return verticalFetch(methodPut, `${StairsServiceUrl}/${id}/alarms`, JSON.stringify(r))
}

export const getStair = (r: GetStairRequest) => {
    return verticalFetch(methodGet, `${StairsServiceUrl}/${r.id}`)
}

export const maintenanceStair = (stair_id: string, note: string) => {
    return verticalFetch(methodPut, `${StairsServiceUrl}/${stair_id}/maintenance`, JSON.stringify({notes: note}))
}

export const SendJobToStair = (r: SendJobToStairRequest) => {
    return verticalFetch(methodPost, `${StairsServiceUrl}/${r.id}/jobs/${r.job_name}`, JSON.stringify(r))
}

export const GetJobStatus = (r: GetJobStatusRequest) => {
    return verticalFetch(methodGet, `${StairsServiceUrl}/${r.id}/jobs/${r.job_name}`)
}

export const SetStairVariable = (r: SetStairVariableRequest) => {
    return verticalFetch(methodPost, `${StairsServiceUrl}/${r.id}/vars/${r.variable_name}`, JSON.stringify(r))
}

export const GetStairVariable = (r: GetStairVariablesRequest) => {
    return verticalFetch(methodGet, `${StairsServiceUrl}/${r.id}/vars`)
}

//Set signals calibration
export const SetSignalsCalibration = (id: string, calibration: Calibration) => {
    const body = JSON.stringify({signals: calibration})
    return verticalFetch(methodPost, `${StairsServiceUrl}/${id}/calibrations`, body)
}
//Get signals calibration
export const GetSignalsCalibration = (id: string) => {
    return verticalFetch(methodGet, `${StairsServiceUrl}/${id}/calibrations`)
}

//Identity
export const CreateIdentity = (r: CreateIdentityRequest, stair_id: string) => {
    return verticalFetch(methodPost, `${StairsServiceUrl}/${stair_id}/identities`, JSON.stringify(r))
}

export const GetIdentity = (stair_id: string) => {
    return verticalFetch(methodGet, `${StairsServiceUrl}/${stair_id}/identities`)
}

export const UpdateIdentity = (r: UpdateIdentityRequest, stair_id: string, dcn: string) => {
    return verticalFetch(methodPut, `${StairsServiceUrl}/${stair_id}/identities/${dcn}`, JSON.stringify(r))
}

export const DeleteIdentity = (r: DeleteIdentityRequest) => {
    return verticalFetch(methodDelete, `${StairsServiceUrl}/${r.stair_id}/identities/${r.dcn}`)
}

//Steps
export const GetSteps = (stair_id: string) => {
    return verticalFetch(methodGet, `${StairsServiceUrl}/${stair_id}/steps`)
}

export const GetStep = (stair_id: string, step_id: string) => {
    return verticalFetch(methodGet, `${StairsServiceUrl}/${stair_id}/steps/${step_id}`)
}