import "./UpdateSubscription.css"
import 'antd/dist/antd.css';
import '../../../App.css'
import {companyInfo} from "./inputs"
import ButtonItem from "../../ButtonItem"
import ButtonConfItem from "../../ButtonConfItem"
import {Col, DatePicker, Divider, Form, Row} from 'antd';
import React, {useEffect, useState} from "react";
import TitleWithBack from "../../../containers/TitleWithBack"
import {
    getSubscription,
    updateSubscriptionExpDate,
    updateSubscriptionInformation
} from "../../../api/services/subscriptionService"
import {Subscription, UpdateSubscriptionInfoRequest} from "../../../api/requests/subscription"
import {roles} from "../costants";
import CustomerSummary from "../../../containers/pages/CustomerSummary";
import moment from 'moment';
import FormInput from "../../input/FormInput";
import {useHistory} from "react-router";
import {countries} from "../../countries";
import FormSelect from "../../select/FormSelect";
import OperationResult from "../../OperationResult";


type UpdateSubscriptionProps = {
    subscriptionId: string,
    role: string
}

const UpdateSubscription: React.FC<UpdateSubscriptionProps> = ({ role, subscriptionId }) => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [selectedSubscription, setSelectedSubscription] = useState<Subscription|null>(null);
    const [status, setStatus] = useState<"success" | "error" | null>(null);
    const goBack = () => {
        setStatus(null)
    }

    useEffect( () => {
        getSubscription({subscription_id: subscriptionId}).then(
            res => {
                if (res && res.subscription) {
                    setSelectedSubscription(res.subscription)
                }
            }
        )
    }, [])
    
    const [width, setWidth] = useState<number>(window.innerWidth)
    const [heigth, setHeigth] = useState<number>(window.innerHeight)
	function handleWindowSizeChange() {
			setWidth(window.innerWidth)
            setHeigth(window.innerHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, []);

    const formItemLayout = {
        labelCol: {
            sm: {
                span: 8
            }
        },
        wrapperCol: {
            sm: {
                span: 20
            }
        }
    };

    const handleSubmit = () => {
        form.validateFields().then(
            values => {
                const req: UpdateSubscriptionInfoRequest = {
                    subscription_id: subscriptionId,
                    company_info: {
                        name: values.company_name,
                        sdi: values.company_sdi,
                        pec: values.company_pec,
                        vat_number: values.company_vat_number,
                        phone: values.company_phone,
                        address: values.company_address,
                        city: values.company_city,
                        province: values.company_province,
                        country: values.company_country,
                        zip_code: values.company_zip_code
                    }
                }
                updateSubscriptionInformation(req).then(res => {
                    if(res && !res.err){
                        setStatus("success");
                        history.goBack();
                    }else{
                        setStatus("error");
                    }
                })

                if (role === roles.admin) {
                    updateSubscriptionExpDate({
                        subscription_id: subscriptionId,
                        exp_date: values.expiry_date
                    }).then(() => {})
                }
            }
        )
    }

    if (!selectedSubscription) {
        return null
    }

    if(status) {
        return (
            <OperationResult
                onClickProp={status === "error" ? goBack : undefined}
                status={status}
                operation={"update"}
                entity={"subscription"}
            />
        )
    }


    return(
        <>
            <TitleWithBack title={"Modifica Abbonamento"} key={"update_subscription_title"} />
            <CustomerSummary customer_id={selectedSubscription.customer_id} key={"update_subscription_summary"} />

            <div className="my-form-container my-form-container-responsive" key={"update_subscription_div"}>
                <Form {...formItemLayout} key={1} form={form} name="user_panel">
                    <Row gutter={24} key={"first_row"}>
                        {
                            companyInfo.map((el)=>{
                                return (
                                    <Col span={(width <= 575 || heigth <= 414) ? 12 : 8} key={el.key + "col"}>
                                        {
                                            el.type !== "select" ?
                                            <FormInput key={el.key + "forminput"} keyValue={el.key} name={el.name} value={ (selectedSubscription as any)["company_info"][el?.path || el.key]} placeholder={el.label} type={(el.type === undefined)? "text" : el.type} /> :
                                            <FormSelect key={el.key + "forminput"} placeholder={el.label} keyValue={el.key} name={el.name} options={countries} value={ (selectedSubscription as any)["company_info"][el?.path || el.key]} />
                                        }
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    {
                        role === roles.admin ?
                            <>
                                <Divider orientation={"left"} className={"divider"} key={"third_divider"}>Scadenza abbonamento</Divider>
                                <Form.Item key={"expiry_date"} name={"expiry_date"} label={"Scadenza"} initialValue={moment(selectedSubscription.expiry_date)}>
                                    <DatePicker key={"datepicker"}/>
                                </Form.Item>
                            </>
                            : null
                    }

                    <div className="btn-container" key={"btn_container"}>
                        <ButtonConfItem buttonLabel="Reset" buttonOnConfirm={() => { form.resetFields(); }} buttonOnCancel={() => {}} questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?" />
                        <ButtonItem buttonType="primary" label="Invio" buttonOnClick={handleSubmit} />
                    </div>
                </Form>
            </div>
        </>
    )
    
}

export default UpdateSubscription;
