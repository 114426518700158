import { Badge, Descriptions, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Stair } from "../../../api/requests/stairsService";
import { getStair } from "../../../api/services/stairsService";
import TitleWithBack from "../../../containers/TitleWithBack";
import ButtonItem from "../../ButtonItem";
import ResponsiveTable from "../../responsiveTable";
import { updateAlarmPageUrl } from "../costants";
import "./AlarmConfiguration.css"
import { alarmsConfigurationListColumns } from "./TableColum";

type AlarmConfigurationProps = {
    installationId: string
}

const AlarmConfiguration: React.FC<AlarmConfigurationProps> = ({ installationId }) => {

    document.body.style.background = "#f5f5f5c7"
    
    const [stair, setStair] = useState<Stair | null>(null)
    useEffect(() => {
        getStair({id: installationId}).then((res) => {
            if(res && !res.err){
                setStair(res.stair)
            }
        })
    }, [])

    const history = useHistory()

    const switchColor = (last_set_status: string) => {
        let result: string = ""
        switch(last_set_status){
            case "":
                result = "geekblue"
                break
            case "failed":
                result = "red"
                break
            case "pending":
                result = "yellow"
                break
            case "done":
                result = "green"
                break
        }
        return result
    }

    const checkLastStatus = (last_set_status: string) => {
        switch (last_set_status) {
            case "done":
                return "Eseguito Correttamente"
            case "pendng":
                return "In Esecuzione"
            case "failed":
                return "Fallito"
            default:
                return "Mai Effettuato"
        }
    }

    if(!stair){
        return null
    }

    return(
        <>
            <TitleWithBack title={"Calibrazione Allarmi"} key={"alarmConfiguration"} />
            <Descriptions bordered>
                <Descriptions.Item label="Stato calibrazione allarmi">
                    <Badge 
                        status="success" 
                        color={switchColor(stair.alarms[0]?.last_set_status || "")} 
                        text={checkLastStatus(stair?.alarms[0]?.last_set_status || "")}
                    />  
                </Descriptions.Item>
            </Descriptions>
            <div className="button-container">
                <ButtonItem buttonType="primary" label="Modifica" key="alarm_calibration_button" buttonOnClick={() => {history.push(updateAlarmPageUrl(installationId))}} />
            </div>
            <ResponsiveTable icon={false} columns={alarmsConfigurationListColumns} data={ stair?.alarms || [] } />
        </>
    )
}

export default AlarmConfiguration;