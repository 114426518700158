import {ResponsiveTableCell, ResponsiveTableColumnType} from "../../../responsiveTable";
import "../../../../App.css"
import {dateType, numberType} from "../../../../utilities/utilities";

export const tabValuesColumns: ResponsiveTableColumnType[] = [
    {
        label: "Data",
        name: "ts",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={dateType}
                    value={value}
                />
        }
    },
    {
        label: "Valore",
        name: "value",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={numberType}
                    value={value}
                />
        }
    }
]
