import { connect } from 'react-redux'
import { Store } from '../../../reducers'

import {setDataStartDate, setPeriod, startUpdateChartData} from '../../../actions/lineChart'

import {bindActionCreators} from "redux";
import InstallationPage from '../../../components/pages/installation/InstallationPage';

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
           setDataStartDate,
           setPeriod,
           startUpdateChartData,
           setPanelTemperatureAggregations: (aggregations: string[]) => dispatch({type:'SET_PANEL_TEMPERATURE_AGGREGATIONS', aggregations: aggregations}),
           setTempAggregations: (aggregations: string[]) => dispatch({type:'SET_VOLTAGE80_AGGREGATIONS', aggregations: aggregations}),
           setPowerAggregations: (aggregations: string[]) => dispatch({type:'SET_VOLTAGE5_AGGREGATIONS', aggregations: aggregations}),
           clearDataInterval: (reset: boolean) => dispatch({type: 'CLEAR_INTERVAL', reset: reset}),
           setStartHour: (h: number) => dispatch({type: 'SET_START_HOUR', h: h})
        },
        dispatch,
    );
}

const mapStateToProps = (state: Store) => {
    return {
        role: state.info.role,
        startHour: state.chart.startHour,
        panel_temperature_data: state.chart.panel_temperature_data,
        panel_temperature_zoom: state.chart.panel_temperature_zoom,
        panel_temperature_aggregations: state.chart.panel_temperature_aggregations,
        voltage80_data: state.chart.voltage80_data,
        voltage80_zoom: state.chart.voltage80_zoom,
        voltage80_aggregations: state.chart.voltage80_aggregations,
        power80_data: state.chart.power80_data,
        power80_zoom: state.chart.power80_zoom,
        power80_aggregations: state.chart.power80_aggregations,
        voltage5_data: state.chart.voltage5_data,
        voltage5_zoom: state.chart.voltage5_zoom,
        voltage5_aggregations: state.chart.voltage5_aggregations,
        power5_data: state.chart.power5_data,
        power5_zoom: state.chart.power5_zoom,
        power5_aggregations: state.chart.power5_aggregations,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationPage)