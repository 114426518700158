export const countries = [
    {"Afghanistan" : "Afghanistan"}, 
    {"Albania" : "Albania"},
    {"Algeria" : "Algeria"},
    {"Samoa Americane" : "Samoa Americane"},
    {"Andorra" : "Andorra"},
    {"Angola" : "Angola"},
    {"Anguilla" : "Anguilla"},
    {"Antartide" : "Antartide"},
    {"Antigua And Barbuda" : "Antigua e Barbuda"},
    {"Argentina" : "Argentina"},
    {"Armenia" : "Armenia"},
    {"Aruba" : "Aruba"},
    {"Australia" : "Australia"},
    {"Austria" : "Austria"},
    {"Azerbaigian" : "Azerbaigian"},
    {"Bahamas" : "Bahamas"},
    {"Bahrein" : "Bahrein"},
    {"Bangladesh" : "Bangladesh"},
    {"Barbados" : "Barbados"},
    {"Bielorussia" : "Bielorussia"},
    {"Belgio" : "Belgio"},
    {"Belize" : "Belize"},
    {"Benin" : "Benin"},
    {"Bermuda" : "Bermuda"},
    {"Bhutan" : "Bhutan"},
    {"Bolivia" : "Bolivia"},
    {"Bosnia Erzegovina" : "Bosnia Erzegovina"},
    {"Botswana" : "Botswana"},
    {"Bouvet" : "Bouvet"},
    {"Brasile" : "Brasile"},
    {"Territorio britannico dell'Oceano Indiano" : "Territorio britannico dell'Oceano Indiano"},
    {"Brunei" : "Brunei"},
    {"Bulgaria" : "Bulgaria"},
    {"Burkina Faso" : "Burkina Faso"},
    {"Burundi" : "Burundi"},
    {"Cambogia" : "Cambogia"},
    {"Camerun" : "Camerun"},
    {"Canada" : "Canada"},
    {"Capo Verde" : "Capo Verde"},
    {"Isole Cayman" : "Isole Cayman"},
    {"Repubblica Centrafricana" : "Repubblica Centrafricana"},
    {"Chad" : "Chad"},
    {"Cile" : "Cile"},
    {"Cina" : "Cina"},
    {"Colombia" : "Colombia"},
    {"Comore" : "Comore"},
    {"Congo" : "Congo"},
    {"Isole Cook" : "Isole Cook"},
    {"Costa Rica" : "Costa Rica"},
    {"Costa d'Avorio" : "Costa d'Avorio"},
    {"Croazia" : "Croazia"},
    {"Cuba" : "Cuba"},
    {"Curacao" : "Curacao"},
    {"Cipro" : "Cipro"},
    {"Repubblica Ceca" : "Repubblica Ceca"},
    {"Repubblica Democratica del Congo": "Repubblica Democratica del Congo"},
    {"Danimarca": "Danimarca"},
    {"Gibuti": "Gibuti"},
    {"Repubblica Dominicana": "Repubblica Dominicana"},
    {"Ecuador": "Ecuador"},
    {"Egitto": "Egitto"},
    {"El Salvador": "El Salvador"},
    {"Guinea equatoriale": "Guinea equatoriale"},
    //"Eritrea" : ​​"Eritrea"},
    {"Estonia": "Estonia"},
    {"Etiopia": "Etiopia"},
    {"Isole Falkland": "Isole Falkland"},
    {"Isole Faroe": "Isole Faroe"},
    {"Figi": "Figi"},
    {"Finlandia": "Finlandia"},
    {"Francia": "Francia"},
    {"Guyana francese": "Guyana francese"},
    {"Polinesia francese": "Polinesia francese"},
    {"Territori francesi meridionali": "Territori francesi meridionali"},
    {"Gabon": "Gabon"},
    {"Gambia": "Gambia"},
    {"Georgia": "Georgia"},
    {"Germania": "Germania"},
    {"Ghana": "Ghana"},
    {"Gibraltar": "Gibraltar"},
    {"Grecia": "Grecia"},
    {"Groenlandia": "Groenlandia"},
    {"Grenada": "Grenada"},
    {"Guadalupa": "Guadalupa"},
    {"Guam": "Guam"},
    {"Guatemala": "Guatemala"},
    {"Guernsey": "Guernsey"},
    {"Guinea": "Guinea"},
    {"Guinea-Bissau": "Guinea-Bissau"},
    {"Guyana": "Guyana"},
    {"Haiti": "Haiti"},
    {"Isole Heard e Mc Donald": "Isole Heard e Mc Donald"},
    {"Honduras": "Honduras"},
    {"Hong Kong": "Hong Kong"},
    {"Islanda": "Islanda"},
    {"India": "India"},
    {"Indonesia": "Indonesia"},
    {"Iran": "Iran"},
    {"Iraq": "Iraq"},
    {"Irlanda": "Irlanda"},
    {"Isle of Man": "Isola di Man"},
    {"Israele": "Israele"},
    {"Italia": "Italia"},
    {"Jamaica": "Jamaica"},
    {"Giappone": "Giappone"},
    {"Jersey": "Jersey"},
    {"Giordania": "Giordania"},
    {"Kazakistan": "Kazakistan"},
    {"Kenya": "Kenya"},
    {"Kiribati": "Kiribati"},
    {"Kuwait": "Kuwait"},
    {"Kirghizistan": "Kirghizistan"},
    {"Laos": "Laos"},
    {"Lettonia": "Lettonia"},
    {"Lebanon": "Lebanon"},
    {"Lesotho": "Lesotho"},
    {"Liberia": "Liberia"},
    {"Libia": "Libia"},
    {"Liechtenstein": "Liechtenstein"},
    {"Lituania": "Lituania"},
    {"Lussemburgo": "Lussemburgo"},
    {"Macao": "Macao"},
    {"Macedonia": "Macedonia"},
    {"Madagascar": "Madagascar"},
    {"Malawi": "Malawi"},
    {"Malesia": "Malesia"},
    {"Maldive": "Maldive"},
    {"Mali": "Mali"},
    {"Malta": "Malta"},
    {"Isole Marshall": "Isole Marshall"},
    {"Martinica": "Martinica"},
    {"Mauritania": "Mauritania"},
    {"Mauritius": "Mauritius"},
    {"Mayotte": "Mayotte"},
    {"Messico": "Messico"},
    {"Micronesia": "Micronesia"},
    {"Moldova": "Moldova"},
    {"Monaco": "Monaco"},
    {"Mongolia": "Mongolia"},
    {"Montenegro": "Montenegro"},
    {"Montserrat": "Montserrat"},
    {"Marocco": "Marocco"},
    {"Mozambico": "Mozambico"},
    {"Myanmar": "Myanmar"},
    {"Namibia": "Namibia"},
    {"Nauru": "Nauru"},
    {"Nepal": "Nepal"},
    {"Paesi Bassi": "Paesi Bassi"},
    {"Antille olandesi": "Antille olandesi"},
    {"Nuova Caledonia": "Nuova Caledonia"},
    {"Nuova Zelanda": "Nuova Zelanda"},
    {"Niger": "Niger"},
    {"Nigeria": "Nigeria"},
    {"Niue": "Niue"},
    {"Norfolk Island": "Norfolk Island"},
    {"Corea del Nord": "Corea del Nord"},
    {"Isole Marianne settentrionali": "Isole Marianne settentrionali"},
    {"Norvegia": "Norvegia"},
    {"Oman": "Oman"},
    {"Pakistan": "Pakistan"},
    {"Palau": "Palau"},
    {"Palestina": "Palestina"},
    {"Panama": "Panama"},
    {"Papua Nuova Guinea": "Papua Nuova Guinea"},
    {"Paraguay": "Paraguay"},
    {"Perù": "Perù"},
    {"Filippine": "Filippine"},
    {"Pitcairn": "Pitcairn"},
    {"Polonia": "Polonia"},
    {"Portugal": "Portugal"},
    {"Porto Rico": "Porto Rico"},
    {"Qatar": "Qatar"},
    {"Repubblica del Kosovo": "Kosovo"},
    {"Romania": "Romania"},
    {"Russia": "Federazione russa"},
    {"Ruanda": "Ruanda"},
    {"Saint Kitts e Nevis": "St Kitts e Nevis"},
    {"Santa Lucia": "Santa Lucia"},
    {"Saint Vincent e Grenadine": "Saint Vincent e Grenadine"},
    {"Samoa": "Samoa"},
    {"San Marino": "San Marino"},
    {"Sao Tome e Principe": "Sao Tome e Principe"},
    {"Arabia Saudita": "Arabia Saudita"},
    {"Senegal": "Senegal"},
    {"Serbia": "Serbia"},
    {"Seychelles": "Seychelles"},
    {"Sierra Leone": "Sierra Leone"},
    {"Singapore": "Singapore"},
    {"Sint Maarten": "Sint Maarten"},
    {"Slovacchia": "Slovacchia"},
    {"Slovenia": "Slovenia"},
    {"Isole Salomone": "Isole Salomone"},
    {"Somalia": "Somalia"},
    {"Sud Africa": "Sud Africa"},
    {"Georgia del Sud e isole Sandwich meridionali": "Georgia del Sud e isole Sandwich meridionali"},
    {"Corea del Sud": "Corea del Sud"},
    {"Sud Sudan": "Sud Sudan"},
    {"Spagna": "Spagna"},
    {"Sri Lanka": "Sri Lanka"},
    {"St. Elena" : "St. Elena"},
    {"St. Pierre e Miquelon" : "St. Pierre e Miquelon"},
    {"Sudan" : "Sudan"},
    {"Suriname" : "Suriname"},
    {"Isole Svalbard e Jan Mayen" : "Isole Svalbard e Jan Mayen"},
    {"Siria" : "Siria"}, 
    {"Emirati Arabi Uniti" : "Emirati Arabi Uniti"},
    {"Regno Unito" : "Regno Unito"},
    {"Stati Uniti" : "Stati Uniti"},
    {"Svezia": "Svezia"},
    {"Svizzera" : "Svizzera"},
    {"Swaziland" : "Swaziland"},
    {"Taiwan" : "Taiwan"},
    {"Tagikistan" : "Tagikistan"},
    {"Tanzania" : "Tanzania"},
    {"Thailandia" : "Thailandia"},
    {"Timor Est" : "Timor Est"},
    {"Togo" : "Togo"},
    {"Tokelau" : "Tokelau"},
    {"Tonga" : "Tonga"},
    {"Trinidad e Tobago" : "Trinidad e Tobago"},
    {"Tunisia" : "Tunisia"},
    {"Turchia" : "Turchia"},
    {"Turkmenistan" : "Turkmenistan"},
    {"Turks e Caicos" : "Turks e Caicos"},
    {"Tuvalu" : "Tuvalu"},
    {"Ucraina": "Ucraina"},
    {"Uganda" : "Uganda"},
    {"Ungheria": "Ungheria"},
    {"Uruguay" : "Uruguay"},
    {"Uzbekistan" : "Uzbekistan"},
    {"Vanuatu" : "Vanuatu"},
    {"Città del Vaticano" : "Città del Vaticano"},
    {"Venezuela" : "Venezuela"},
    {"Vietnam" : "Vietnam"},
    {"Wallis e Futuna" : "Wallis e Futuna"},
    {"Yemen" : "Yemen"},
    {"Zambia" : "Zambia"},
    {"Zimbabwe" : "Zimbabwe"}
]