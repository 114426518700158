import './DeviceConnection.css'
import ButtonItem from "../../ButtonItem"
import { Form, Input, Progress, Result, Select, Typography } from 'antd';
import React, {useEffect, useState} from "react";
import TitleWithBack from '../../../containers/TitleWithBack';
import { CreateIdentity, DeleteIdentity, GetIdentity, getStair } from '../../../api/services/stairsService';
import { BackendUrl } from '../../../api/constants';
import { gsm, receiveChannels, select, sendChannels, wifi } from './costant';
import { Stair } from '../../../api/requests/stairsService';

const { Option } = Select;

type DeviceConnectionConfigurationProps = {
    stair_id: string
}

const DeviceConnectionConfiguration: React.FC<DeviceConnectionConfigurationProps> = ({ stair_id }) => {
    const totalEstimatedSeconds = 100;
    const tenPercentRemainingSeconds = 98;
    const twentyPercentRemainingSeconds = 93;
    const thirtyPercentRemainingSeconds = 48;
    const fortyPercentRemainingSeconds = 46;
    const sixtyPercentRemainingSeconds = 30;
    const seventyPercentRemainingSeconds = 28;
    const seventyfivePercentRemainingSeconds = 27;
    const eightyPercentRemainingSeconds = 26;
    const hundredPercentRemainingSeconds = 0;

    const [formItem, setFormItem] = useState("gsm");
    const [form] = Form.useForm();
    const [result, setResult] = useState<boolean | null>(null);
    const [progress, setProgress] = useState<boolean>(false);
    const [percent, setPercent] = useState<number>(0);
    const [checkDevice, setCheckDevice] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    const [remainingSeconds, setRemainingSeconds] = useState<number>(totalEstimatedSeconds);
    const [stair, setStair] = useState<Stair | null> (null)

    useEffect(() => {
        getStair({id: stair_id}).then(
            res => {
                if (res && res.stair) {
                    setStair(res.stair)
                }
            }
        )
    }, [])

    const secondsToTimer = (r: number) => {
        let minutes, seconds = "";

        let m = Math.floor(r % 3600 / 60);
        let s = Math.floor(r % 3600 % 60);

        if (m < 10) { minutes = "0"+m;} else {minutes = m.toString()}
        if (s < 10) { seconds = "0"+s;} else {seconds = s.toString()}
        return `${minutes}:${seconds}`;
    }

    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        if (progress){
            intervalId = setInterval(() => {
                //console.log("REMAINING", remainingSeconds)
                setRemainingSeconds(remainingSeconds-1);
                if (remainingSeconds <= 0) {
                    setRemainingSeconds(0);
                    clearInterval(intervalId);
                }
                if (percent >= 100) {
                    setRemainingSeconds(0);
                    clearInterval(intervalId);
                }
            }, 1000);
        }
        return () => {
            clearInterval(intervalId);
        }
    }, [remainingSeconds, progress, percent])

    let board: string = ""
    let uid: string = ""        
    let phys_id: string = ""        
    let path: string = ""

    const firmware_download = (result: string) => {
        console.log("firmware_download")
        if(result !== ""){
            path = result
            if(stair){
                const json = {
                    ...data,
                    num_steps: stair.num_steps
                }
                window.api.send(sendChannels.ztc_change_connection, [json, path])
            }else{
                window.api.send(sendChannels.ztc_change_connection, [data, path])
            }
        }else{
            setResult(false)
        }
    }

    const ztc_change_connection = (result: boolean) => {
        console.log("ztc_change_connection")
        if(result){
            if(remainingSeconds !== tenPercentRemainingSeconds) {
                setRemainingSeconds(tenPercentRemainingSeconds)
            }
            setPercent(10)
            window.api.send(sendChannels.ztc_discover, "")
        }else{
            setResult(false)
        }
    }

    const ztc_discover = (devices: any[]) => {
        board = ""
        uid = ""
        console.log("ztc_discover")
        if(devices.length > 0){
            let tmp_board: string | null = null
            let tmp_uid: string | null = null
            devices.map((el) => {
                if(!board && !uid){
                    tmp_board = el.board
                    tmp_uid = el.uid
                }
            })
            if(!tmp_board || !tmp_uid){
                setResult(false)
            }else{
                board = tmp_board
                uid = tmp_uid
                setPercent(20)
                if(remainingSeconds !== twentyPercentRemainingSeconds) {
                    setRemainingSeconds(twentyPercentRemainingSeconds)
                }
                window.api.send(sendChannels.ztc_erase, [board, uid])   
            }          
        }else{
            setResult(false)
        }
    }

    const ztc_erase = (result: boolean) => {
        console.log("ztc_erase")
        if(result){
            setPercent(30)
            if(remainingSeconds !== thirtyPercentRemainingSeconds) {
                setRemainingSeconds(thirtyPercentRemainingSeconds)
            }
            window.api.send(sendChannels.ztc_phys_id, uid)
        }else{
            setResult(false)
        }
    }

    const ztc_phys_id = async(result: any[]) => {
        console.log("ztc_phys_id")
        await window.api.send(sendChannels.ztc_check_discover, [board, uid])
        if(checkDevice){
            if(board === "4zerobox_v9"){
                window.api.send(sendChannels.ztc_provision_prepare, [`${BackendUrl}/firmwares/type/provisioning`, board, uid])
            }else{
                phys_id = String(result)
                window.api.send(sendChannels.ztc_provision_prepare, [`${BackendUrl}/firmwares/type/provisioning`, board, phys_id])
            }
            setPercent(40)
            if(remainingSeconds !== fortyPercentRemainingSeconds) {
                setRemainingSeconds(fortyPercentRemainingSeconds)
            }
        }else{
            setResult(false)
        }        
    }

    const ztc_provision_prepare = async(result: any[]) => {
        console.log("ztc_provision_prepare")
        await window.api.send(sendChannels.ztc_check_discover, [board, uid])
        if(checkDevice){
            if(Boolean(result)){
                setPercent(60);
                if(remainingSeconds !== sixtyPercentRemainingSeconds) {
                    setRemainingSeconds(sixtyPercentRemainingSeconds)
                }
                if(board === "4zerobox_v9"){
                    window.api.send(sendChannels.ztc_provision_command, uid)
                }else{
                    window.api.send(sendChannels.ztc_provision_command, phys_id)
                } 
            }else{
                setResult(false)
            }
        }else{
            setResult(false)
        }
    }

    const ztc_provision_command = async(result: any[]) => {
        const bundle: string = String(result)
        if(bundle.startsWith("sn")){
            console.log("ztc_provision_command")
            await window.api.send(sendChannels.ztc_check_discover, [board, uid])
            if(checkDevice){
                setPercent(70)
                if(remainingSeconds !== seventyPercentRemainingSeconds) {
                    setRemainingSeconds(seventyPercentRemainingSeconds)
                }
                GetIdentity(stair_id).then((res) => {
                    if(res && !res.err){
                        if(res?.identities !== undefined){
                            //sullo ZDM c'è già una identity
                            let old_dcn = res.identities[0]?.dcn
                            if(bundle.includes(old_dcn)){
                                console.log("aggiornamento firmware")
                                //non si vuole sostituire il device fisico ma solo aggiornare il firmware
                                setPercent(80)
                                if(remainingSeconds !== eightyPercentRemainingSeconds) {
                                    setRemainingSeconds(eightyPercentRemainingSeconds)
                                }
                                window.api.send(sendChannels.ztc_burn, [board, uid, path])
                            }else{
                                console.log("sostituzione device fisico")
                                //si vuole sostituire il device fisico
                                DeleteIdentity({stair_id: stair_id, dcn: old_dcn}).then((res) => {
                                    if(res && !res.err){
                                        setPercent(75);
                                        if(remainingSeconds !== seventyfivePercentRemainingSeconds) {
                                            setRemainingSeconds(seventyfivePercentRemainingSeconds)
                                        }
                                        CreateIdentity({phys_id: phys_id, bundle: bundle}, stair_id).then((res: any) => {
                                            if(res && !res.err){
                                                setPercent(80)
                                                if(remainingSeconds !== eightyPercentRemainingSeconds) {
                                                    setRemainingSeconds(eightyPercentRemainingSeconds)
                                                }
                                                window.api.send(sendChannels.ztc_burn, [board, uid, path])
                                            }else{
                                                setResult(false)
                                            }
                                        })
                                    }else{
                                        setResult(false)
                                    }
                                })
                            }     
                        }else{
                            console.log("primo collegamento")
                            //sullo ZDM non c'è nessuna identity quindi è il primo collegamento
                            CreateIdentity({phys_id: phys_id, bundle: String(result)}, stair_id).then((res: any) => {
                                if(res && !res.err && path !== ""){
                                    setPercent(80)
                                    if(remainingSeconds !== eightyPercentRemainingSeconds) {
                                        setRemainingSeconds(eightyPercentRemainingSeconds)
                                    }
                                    window.api.send(sendChannels.ztc_burn, [board, uid, path])
                                }else{
                                    setResult(false)
                                }
                            })
                        }
                    }else{
                        setResult(false)
                    }
                })
            }else{
                setResult(false)
            }
        }else{
            setResult(false)
        }
    }

    const ztc_burn = async(result: any[]) => {
        console.log("ztc_burn")
        await window.api.send(sendChannels.ztc_check_discover, [board, uid])
        if(checkDevice){
            if(result.length > 0 && !String(result).includes("error")){
                setPercent(100)
                if(remainingSeconds !== hundredPercentRemainingSeconds) {
                    setRemainingSeconds(hundredPercentRemainingSeconds)
                }
                setResult(true)
            }else{
                setResult(false)
            }
        }else{
            setResult(false)
        }
    }

    const ztc_check_discover = (result: any) => {
        setCheckDevice(Boolean(result))
    }

    useEffect( () => {
        const firmware_download_result = window.api.receive(receiveChannels.firmware_download_result, (result: string) => firmware_download(result))
        const ztc_change_connection_result = window.api.receive(receiveChannels.ztc_change_connection_result, (result: boolean) => ztc_change_connection(result))
        const ztc_discover_result = window.api.receive(receiveChannels.ztc_discover_result, (devices: any[]) => ztc_discover(devices))
        const ztc_erase_result = window.api.receive(receiveChannels.ztc_erase_result, (result: boolean) => ztc_erase(result))
        const ztc_phys_id_result = window.api.receive(receiveChannels.ztc_phys_id_result, async(result: any[]) => ztc_phys_id(result) )
        const ztc_provision_prepare_result = window.api.receive(receiveChannels.ztc_provision_prepare_result, async(result: any[]) => ztc_provision_prepare(result) )
        const ztc_provision_command_result = window.api.receive(receiveChannels.ztc_provision_command_result, async(result: any[]) => ztc_provision_command(result) )
        const ztc_burn_result = window.api.receive(receiveChannels.ztc_burn_result, async(result: any[]) => ztc_burn(result) )
        const ztc_check_discover_result = window.api.receive(receiveChannels.ztc_burn_result, (result: any) => ztc_check_discover(result))
        return () => {
            firmware_download_result()
            ztc_change_connection_result()
            ztc_discover_result()
            ztc_erase_result()
            ztc_phys_id_result()
            ztc_provision_prepare_result()
            ztc_provision_command_result()
            ztc_burn_result()
            ztc_check_discover_result()
        }
    }, [data])

    const connect = () => {
        setProgress(true)
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf(' electron/') > -1) {
            // Electron-specific code
            window.api.send("firmware_download", `${BackendUrl}/firmwares/type/latest`)
        }
    }

    const submit = () => {
        setResult(null)
        setPercent(0)
        setCheckDevice(true)
        form.validateFields().then(
            async values => {
                if(values.connection_type === "wifi"){
                    setData({
                        gsm: false,
                        sid: values.sid,
                        password: values.password
                    })
                }
                if(values.connection_type === "gsm"){
                    setData({
                        gsm: true,
                        apn: values.operator
                    })
                }
                connect()
            }
        )
    }

    return(
        <>
            <TitleWithBack title={"Impostare Connessione"} key={"add_connection"}/>
            <div className="my-connection-container">
                <Form layout="vertical" key={1} name="connection_panel" form={form}> 
                <Form.Item label={select.label} name={select.name} initialValue={select.options[1].value}>
                        <Select placeholder={select.placeholder} onChange={(value: string)=>{setFormItem(value)}}>
                            {
                                select.options.map((opt, i) => {
                                    return <Option value={opt.value} key={i} disabled={opt.disabled}> {opt.label} </Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    {
                        formItem === select.options[0].value ?
                        <>
                            <Form.Item label={wifi[0].label} name={wifi[0].name} rules={[ {required: true, message: wifi[0].requiredLabel} ]} >
                                <Input placeholder={wifi[0].placeholder} type={wifi[0].type} />
                            </Form.Item>
                            <Form.Item label={wifi[1].label} name={wifi[1].name} rules={[ {required: true, message: wifi[1].requiredLabel} ]} >
                                <Input placeholder={wifi[1].placeholder} type={wifi[1].type} />
                            </Form.Item>
                        </> :
                        <Form.Item label={gsm.label} rules={[ {required: true, message: gsm.requiredLabel} ]} name={gsm.name}>
                            <Select placeholder={gsm.placeholder} onChange={(value: string)=>{setFormItem(value)}}>
                                {
                                    gsm.options.map((opt, i) => {
                                        return <Option value={opt.value} key={i}> {opt.label} </Option>
                                    })
                                }
                                </Select>
                        </Form.Item>
                    }
                    <div className="btn-container">
                        <ButtonItem
                            buttonType="primary"
                            label="Invio"
                            buttonOnClick={submit}
                            disabled={result === null && percent !== 0}
                        />
                    </div>
                </Form>
            </div>
            {
                progress ?
                    <div className="progress">
                        Stiamo aggiornando il device con le credenziali inserite, l'operazione potrebbe richiedere qualche minuto.
                        <Progress className="progress" strokeColor={{from: '#108ee9', to: '#87d068'}} percent={percent} status="active" />
                        <Typography>Tempo rimanente stimato: {secondsToTimer(remainingSeconds)}</Typography>
                        <Typography>Per favore, rimanere su questa pagina fino al termine dell'operazione.</Typography>
                        {
                            result === true ? 
                                <Result
                                    status="success"
                                    title="L'operazione è stata completata con successo"
                                />
                            : result === false ?
                                <Result
                                    status="error"
                                    title="L'operazione non è andata a buon fine."
                                /> 
                            : null
                        }
                    </div> 
                : null
            }
            
            
        </>
    )
    
}

export default DeviceConnectionConfiguration;
