import { InputType } from "../../types/form/types";

export const installationFields: InputType[] = [
    {
        key: "name",
        name: "name",
        label: "Nome",
        rules: [ { required: true, message: "Inserisci il nome!" } ]
    },
    {
        key: "description",
        name: "description",
        label: "Descrizione"
    },
    {
        key: "num_steps",
        name: "num_steps",
        label: "Numero scalini",
        type: "number"
    }
]


export const stairModels: string[] = ["Multigradino", "Scala pianale", "Portone", "Atrio"]