import {Action} from "../actions"
import {ChartObject, DataObject} from "../api/requests/timeSeries"
import {Aggregation} from "../actions/lineChart";
import { min, max, avg, sum } from "../actions/constants";
import { Zoom } from "../components/types/timeseries/types";

export type GlobalState = {
    startHour: number,

    // temperatura quadro elettrico
    panel_temperature_data: ChartObject,
    panel_temperature_zoom: Zoom,
    panel_temperature_aggregations: string[],
    last_panel_temperature_data_ts: Date | null,

    // tensione 80 vdc chart
    voltage80_data: ChartObject,
    voltage80_zoom: Zoom,
    voltage80_aggregations: string[],
    last_voltage80_data_ts: Date | null,
    // corrente 80 vdc chart
    power80_data: ChartObject,
    power80_zoom: Zoom,
    power80_aggregations: string[],
    last_power80_data_ts: Date | null,
    // tensione 5 vdc chart
    voltage5_data: ChartObject,
    voltage5_zoom: Zoom,
    voltage5_aggregations: string[],
    last_voltage5_data_ts: Date | null,
    // corrente 5 vdc chart
    power5_data: ChartObject,
    power5_zoom: Zoom,
    power5_aggregations: string[],
    last_power5_data_ts: Date | null,
    // motor speed chart
    motor_speed_data: ChartObject,
    motor_speed_zoom: Zoom,
    motor_speed_aggregations: string[],
    last_motor_speed_data_ts: Date | null

    // motor speed chart
    motor_overcurrent_data: ChartObject,
    motor_overcurrent_zoom: Zoom,
    motor_overcurrent_aggregations: string[],
    last_motor_overcurrent_data_ts: Date | null

    interval_id: any
}

let d = new Date();
d.setHours(d.getHours() - 24);

export const initialState: GlobalState = {
    startHour: 24,
    panel_temperature_data: {
        avg: [],
        max: [],
        min: []
    },
    panel_temperature_zoom: {
        voltage_data: {},
        power_data: {},
        left: "",
        right: "",
        refAreaLeft: "",
        refAreaRight: "",
        top: "dataMax+1",
        bottom: "dataMin-1",
        animation: true,
        isZoomed: false,
        isZooming: false
    },
    panel_temperature_aggregations: [avg, min, max],
    last_panel_temperature_data_ts: d,

    voltage80_data: {
        avg: [],
        max: [],
        min: []
    },
    voltage80_zoom: {
        voltage_data: {},
        power_data: {},
        left: "",
        right: "",
        refAreaLeft: "",
        refAreaRight: "",
        top: "dataMax+1",
        bottom: "dataMin-1",
        animation: true,
        isZoomed: false,
        isZooming: false
    },
    voltage80_aggregations: [avg, min, max, sum],
    last_voltage80_data_ts: d,

    power80_data: {
        sum: [],
        max: [],
        min: []
    },
    power80_zoom: {
        data: {},
        left: "",
        right: "",
        refAreaLeft: "",
        refAreaRight: "",
        top: "dataMax+1",
        bottom: "dataMin-1",
        animation: true,
        isZoomed: false,
        isZooming: false
    },
    power80_aggregations: [sum, min, max],
    last_power80_data_ts: d,

    voltage5_data: {
        avg: [],
        max: [],
        min: []
    },
    voltage5_zoom: {
        voltage_data: {},
        power_data: {},
        left: "",
        right: "",
        refAreaLeft: "",
        refAreaRight: "",
        top: "dataMax+1",
        bottom: "dataMin-1",
        animation: true,
        isZoomed: false,
        isZooming: false
    },
    voltage5_aggregations: [avg, min, max, sum],
    last_voltage5_data_ts: d,

    power5_data: {
        sum: [],
        max: [],
        min: []
    },
    power5_zoom: {
        data: {},
        left: "",
        right: "",
        refAreaLeft: "",
        refAreaRight: "",
        top: "dataMax+1",
        bottom: "dataMin-1",
        animation: true,
        isZoomed: false,
        isZooming: false
    },
    power5_aggregations: [sum, min, max],
    last_power5_data_ts: d,

    motor_speed_data: {
        avg: [],
        max: [],
        min: []
    },
    motor_speed_zoom: {
        data: {},
        left: "",
        right: "",
        refAreaLeft: "",
        refAreaRight: "",
        top: "dataMax+1",
        bottom: "dataMin-1",
        animation: true,
        isZoomed: false,
        isZooming: false
    },
    motor_speed_aggregations: [avg, min, max],
    last_motor_speed_data_ts: d,

    motor_overcurrent_data: {
        avg: [],
        max: [],
        min: []
    },
    motor_overcurrent_zoom: {
        data: {},
        left: "",
        right: "",
        refAreaLeft: "",
        refAreaRight: "",
        top: "dataMax+1",
        bottom: "dataMin-1",
        animation: true,
        isZoomed: false,
        isZooming: false
    },
    motor_overcurrent_aggregations: [avg, min, max],
    last_motor_overcurrent_data_ts: d,

    interval_id: 0
}

const chartDataObjectGenerator = (data: ChartObject, tmpData: ChartObject) => {
    /*
    const data_chart = {
        values: [...data?.values || [], ...tmpData?.values || []],
        avg: [...data?.avg || [], ...tmpData?.avg || []],
        min: [...data?.min || [], ...tmpData?.min || []],
        max: [...data?.max || [], ...tmpData?.max || []],
        sum: [...data?.sum || [], ...tmpData?.sum || []],
    }

    for (const [key, value] of Object.entries(data_chart)) {
        data_chart[key as Aggregation] = value.map( el => {
            if(el.value){
                return {
                    ts: new Date(el.ts),
                    value: parseFloat(el.value.toFixed(2))
                }                
            }else{
                return {
                    ts: new Date(el.ts),
                }     
            }
        })
    }
    */
    let tmp_values: DataObject[] = [...data?.values || []]
    tmpData?.values?.map(v => {
        if(v?.value){
            tmp_values = [
                ...tmp_values,
                {ts: new Date(v.ts), value: (v.value).toFixed(2)}
            ]
        }else{
            tmp_values = [
                ...tmp_values,
                {ts: new Date(v.ts)}
            ]
        }
    })

    let tmp_avg = [...data?.avg || []]
    tmpData?.avg?.map(v => {
        if(v?.value){
            tmp_avg = [
                ...tmp_avg,
                {ts: new Date(v.ts), value: (v.value).toFixed(2)}
            ]
        }else{
            tmp_avg = [
                ...tmp_avg,
                {ts: new Date(v.ts)}
            ]
        }
    })

    let tmp_sum = [...data?.sum || []]
    tmpData?.sum?.map(v => {
        if(v?.value){
            tmp_sum = [
                ...tmp_sum,
                {ts: new Date(v.ts), value: (v.value).toFixed(2)}
            ]
        }else{
            tmp_sum = [
                ...tmp_sum,
                {ts: new Date(v.ts)}
            ]
        }
    })

    let tmp_min = [...data?.min || []]
    tmpData?.min?.map(v => {
        if(v?.value){
            tmp_min = [
                ...tmp_min,
                {ts: new Date(v.ts), value: (v.value).toFixed(2)}
            ]
        }else{
            tmp_min = [
                ...tmp_min,
                {ts: new Date(v.ts)}
            ]
        }
    })

    let tmp_max = [...data?.max || []]
    tmpData?.max?.map(v => {
        if(v?.value){
            tmp_max = [
                ...tmp_max,
                {ts: new Date(v.ts), value: (v.value).toFixed(2)}
            ]
        }else{
            tmp_max = [
                ...tmp_max,
                {ts: new Date(v.ts)}
            ]
        }
    })

    return {
        values: tmp_values,
        avg: tmp_avg,
        min: tmp_min,
        max: tmp_max,
        sum: tmp_sum
    }
}

const dataChartReducer = (state: GlobalState = initialState, action: Action) => {
    switch(action.type){
        case "RESET_START_DATE":
            return {
                ...state,
                last_voltage80_data_ts: null,
                last_power80_data_ts: null,
                last_voltage5_data_ts: null,
                last_power5_data_ts: null,
                last_motor_speed_data_ts: null,
                last_motor_overcurrent_data_ts: null,
                last_panel_temperature_data_ts: null
            }
        case 'SET_START_HOUR':
            return {
                ...state,
                startHour: action.h
            }
        case 'SET_PANEL_TEMPERATURE_AGGREGATIONS':
            return {
                ...state,
                panel_temperature_aggregations: action.aggregations
            }
        case 'SET_VOLTAGE80_AGGREGATIONS':
            return {
                ...state,
                voltage80_aggregations: action.aggregations
            }
        case 'SET_POWER80_AGGREGATIONS':
            return {
                ...state,
                power80_aggregations: action.aggregations
            }
        case 'SET_VOLTAGE5_AGGREGATIONS':
            return {
                ...state,
                voltage5_aggregations: action.aggregations
            }
        case 'SET_POWER5_AGGREGATIONS':
            return {
                ...state,
                power5_aggregations: action.aggregations
            }
        case 'SET_MOTOR_SPEED_AGGREGATIONS':
            return {
                ...state,
                motor_speed_aggregations: action.aggregations
            }
        case 'SET_MOTOR_OVERCURRENT_AGGREGATIONS':
            return {
                ...state,
                motor_overcurrent_aggregations: action.aggregations
            }
        case 'ADD_PANEL_TEMPERATURE_DATA':
            let tmp_pt: ChartObject = {}
            const {last_panel_temperature_data_ts, panel_temperature_data} = state

            for (const [key, value] of Object.entries(action.data)) {
                if(value && Array.isArray(value) && value.length > 0){
                    tmp_pt[key as Aggregation] = last_panel_temperature_data_ts ? value.filter(row => new Date(row.ts) > new Date(last_panel_temperature_data_ts)) : value
                }
            }

            const panel_temperature_data_chart = chartDataObjectGenerator(panel_temperature_data, action.data)
            
            return {
                ...state,
                panel_temperature_data: panel_temperature_data_chart,
                last_panel_temperature_data_ts: new Date( action.lastTimestamp )
            }
        case 'ADD_VOLTAGE80_DATA':
            let tmp_voltage80: ChartObject = {}
            const {last_voltage80_data_ts, voltage80_data} = state

            for (const [key, value] of Object.entries(action.data)) {
                if(value && Array.isArray(value) && value.length > 0){
                    tmp_voltage80[key as Aggregation] = last_voltage80_data_ts ? value.filter(row => new Date(row.ts) > new Date(last_voltage80_data_ts)) : value
                }
            }

            const voltage80_data_chart = chartDataObjectGenerator(voltage80_data, action.data)
            
            return {
                ...state,
                voltage80_data: voltage80_data_chart,
                last_voltage80_data_ts: new Date( action.lastTimestamp )
            }
        case 'ADD_POWER80_DATA':
            let tmp_power80: ChartObject = {}
            const {last_power80_data_ts, power80_data} = state

            for (const [key, value] of Object.entries(action.data)) {
                if(value && Array.isArray(value) && value.length > 0){
                    tmp_power80[key as Aggregation] = last_power80_data_ts ? value.filter(row => new Date(row.ts) > new Date(last_power80_data_ts)) : value
                }
            }

            const power80_data_chart = chartDataObjectGenerator(power80_data, action.data)

            return {
                ...state,
                power80_data: power80_data_chart,
                last_power80_data_ts: new Date( action.lastTimestamp )
            }
        case 'ADD_VOLTAGE5_DATA':
            let tmp_voltage5: ChartObject = {}
            const {last_voltage5_data_ts, voltage5_data} = state
    
            for (const [key, value] of Object.entries(action.data)) {
                if(value && Array.isArray(value) && value.length > 0){
                    tmp_voltage5[key as Aggregation] = last_voltage5_data_ts ? value.filter(row => new Date(row.ts) > new Date(last_voltage5_data_ts)) : value
                }
            }
            const voltage5_data_chart = chartDataObjectGenerator(voltage5_data, action.data)
            return {
                ...state,
                voltage5_data: voltage5_data_chart,
                last_voltage5_data_ts: new Date( action.lastTimestamp )
            }
        case 'ADD_POWER5_DATA':
            let tmp_power5: ChartObject = {}
            const {last_power5_data_ts, power5_data} = state
    
            for (const [key, value] of Object.entries(action.data)) {
                if(value && Array.isArray(value) && value.length > 0){
                    tmp_power5[key as Aggregation] = last_power5_data_ts ? value.filter(row => new Date(row.ts) > new Date(last_power5_data_ts)) : value
                }
            }
    
            const power5_data_chart = chartDataObjectGenerator(power5_data, action.data)
    
            return {
                ...state,
                power5_data: power5_data_chart,
                last_power5_data_ts: new Date( action.lastTimestamp )
            }
        case 'ADD_MOTOR_SPEED_DATA':
            let tmpMotorSpeed: ChartObject = {}
            const {last_motor_speed_data_ts, motor_speed_data} = state
    
            for (const [key, value] of Object.entries(action.data)) {
                if(value && Array.isArray(value) && value.length > 0){
                    tmpMotorSpeed[key as Aggregation] = last_motor_speed_data_ts ? value.filter(row => new Date(row.ts) > new Date(last_motor_speed_data_ts)) : value
                }
            }

            const motor_speed_data_chart = chartDataObjectGenerator(motor_speed_data, action.data)

            return {
                ...state,
                motor_speed_data: motor_speed_data_chart,
                last_motor_speed_data_ts: new Date( action.lastTimestamp )
            }
        case 'ADD_MOTOR_OVERCURRENT_DATA':
            let tmpMotorOvercurrent: ChartObject = {}
            const {last_motor_overcurrent_data_ts, motor_overcurrent_data} = state
        
            for (const [key, value] of Object.entries(action.data)) {
                if(value && Array.isArray(value) && value.length > 0){
                    tmpMotorOvercurrent[key as Aggregation] = last_motor_overcurrent_data_ts ? value.filter(row => new Date(row.ts) > new Date(last_motor_overcurrent_data_ts)) : value
                }
            }
    
            const motor_overcurrent_data_chart = chartDataObjectGenerator(motor_overcurrent_data, action.data)
            
            return {
                ...state,
                motor_overcurrent_data: motor_overcurrent_data_chart,
                last_motor_overcurrent_data_ts: new Date( action.lastTimestamp )
            }
        case 'SET_PANEL_TEMPERATURE_ZOOM':
            return {
                ...state,
                panel_temperature_zoom: {
                    ...state.panel_temperature_zoom,
                    ...action.zoomUpd
                }
            }
        case 'SET_VOLTAGE80_ZOOM':
            return {
                ...state,
                voltage80_zoom: {
                    ...state.voltage80_zoom,
                    ...action.zoomUpd
                }
            }
        case 'SET_POWER80_ZOOM':
            return {
                ...state,
                power80_zoom: {
                    ...state.power80_zoom,
                    ...action.zoomUpd
                }
            }
        case 'SET_VOLTAGE5_ZOOM':
            return {
                ...state,
                voltage5_zoom: {
                    ...state.voltage5_zoom,
                    ...action.zoomUpd
                }
            }
        case 'SET_POWER5_ZOOM':
            return {
                ...state,
                power5_zoom: {
                    ...state.power5_zoom,
                    ...action.zoomUpd
                }
            }
        case 'SET_MOTOR_SPEED_ZOOM':
            return {
                ...state,
                motor_speed_zoom: {
                    ...state.motor_speed_zoom,
                    ...action.zoomUpd
                }
            }
        case 'SET_MOTOR_OVERCURRENT_ZOOM':
            return {
                ...state,
                motor_overcurrent_zoom: {
                    ...state.motor_overcurrent_zoom,
                    ...action.zoomUpd
                }
            }
        case 'SET_INTERVAL':
            return {
                ...state,
                interval_id: action.interval
            }
        case 'CLEAR_INTERVAL':
            clearInterval(state.interval_id);
            if(action.reset){
                return {//reset per la scala
                    ...state,
                    panel_temperature_data: {
                        avg: [],
                        max: [],
                        min: []       
                    },
                    voltage80_data: {
                        avg: [],
                        max: [],
                        min: []
                    },
                    power80_data: {
                        sum: [],
                        max: [],
                        min: []
                    },
                    voltage5_data: {
                        avg: [],
                        max: [],
                        min: []
                    },
                    power5_data: {
                        sum: [],
                        max: [],
                        min: []
                    },
                    interval_id: -1
                }
            }else{
                return {//reset per lo scalino
                    ...state,
                    motor_speed_data: {
                        avg: [],
                        max: [],
                        min: []
                    },
                    motor_overcurrent_data: {
                        avg: [],
                        max: [],
                        min: []
                    },
                    interval_id: -1
                }
            }
        case 'SET_DATA_START_DATE':
            clearInterval(state.interval_id);
            return {
                ...state,
                last_voltage80_data_ts: action.start_date,
                last_power80_data_ts: action.start_date,
                last_voltage5_data_ts: action.start_date,
                last_power5_data_ts: action.start_date,
                last_motor_speed_data_ts: action.start_date,
                last_motor_overcurrent_data_ts: action.start_date,
                last_panel_temperature_data_ts: action.start_date
            }
        default:
            return state
    }
}

export default dataChartReducer