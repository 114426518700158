import {MenuType, SubMenuType} from "../types/navbar/types";
import {
    adminPanelIconsUrl,
    alarmsPageUrl,
    createInstallerPageUrl,
    installersAssociationsUrl,
    installersListPageUrl,
    subscriptionsListToDataHistoryPageUrl,
    subscriptionsListToInstallationPageUrl,
    subscriptionsListToUpdateUrl,
    userListAdminUrl
} from "../pages/costants";
import dataRealTime from "../pages/homePages/img/graph_white.png";
import dataHistory from "../pages/homePages/img/cloud-download_white.png";
import alert from "../pages/homePages/img/alert_white.png";
import admin from "../pages/homePages/img/admin_white.png";
import installer from "../pages/homePages/img/installer_white.png";
import people from "../pages/homePages/img/people_white.png";
import change from "../pages/homePages/img/change_white.png";
import list_user from "../pages/homePages/img/list_user_white.png";

const adminItem: SubMenuType[] = [
    {
        key: "create_installer",
        label: "Crea Installatore",
        link: createInstallerPageUrl,
        icon: <img alt={"--"} src={installer} className={"menu-icon"}/>,
        elements: []
    },
    {
        key: "update_installer",
        label: "Modifica Installatore",
        link: installersListPageUrl,
        icon: <img alt={"--"} src={installer} className={"menu-icon"}/>,
        elements: []
    },
    {
        key: "installer_associations",
        label: "Associazioni Installatori",
        link: installersAssociationsUrl,
        icon: <img alt={"--"} src={people} className={"menu-icon"}/>,
        elements: []
    },
    {
        key: "update_subscription",
        label: "Modifica Abbonamento",
        link: subscriptionsListToUpdateUrl,
        icon: <img alt={"--"} src={change} className={"menu-icon"}/>,
        elements: []
    },
    {
        key: "customers_list",
        label: "Lista utenti",
        icon: <img alt={"--"} src={list_user} className={"menu-icon"}/>,
        link: userListAdminUrl,
        elements: []
    }
];

export const adminMenuItems: MenuType[] = [
    {   
        key: "real_time_data",
        label: "Dati Real Time",
        icon: <img alt={"--"} src={dataRealTime} className={"menu-icon"}/>,
        elements: [],
        link: subscriptionsListToInstallationPageUrl
    },
    {   
        key: "data_history",
        label: "Storico Dati",
        icon: <img alt={"--"} src={dataHistory} className={"menu-icon"}/>,
        elements: [],
        link: subscriptionsListToDataHistoryPageUrl
    },
    {   
        key: "alarms", 
        label: "Allarmi",
        link: alarmsPageUrl,
        icon: <img alt={"--"} src={alert} className={"menu-icon"}/>,
        elements: []
    },
    {
        key: "admin",
        label: "Admin",
        icon: <img alt={"--"} src={admin} className={"menu-icon"}/>,
        elements: adminItem,
        link: adminPanelIconsUrl
    }
];