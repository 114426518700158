import { connect } from 'react-redux'
import { Store } from '../../reducers'
import {Dispatch} from 'react'
import {Action} from '../../actions'
import HomePage from "../../components/pages/homePages/HomePage";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
    }
}

const mapStateToProps = (state: Store) => {
    return {
        role: state.info.role
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)