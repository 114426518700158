import { InputType } from "../../types/form/types";

export const corrente_5: InputType[] = [
    {
        key: "enabled_corrente_5",
        name: "enabled__corrente_5",
        label: "radio",
        type: "radio"
    },
    {
        key: "level_corrente_5",
        name: "level__corrente_5",
        label: "Livello",
        type: "select",
        rules: [ { required: true, message: "Inserisci il livello!" } ]
    },
    {
        key: "lower_threshold_corrente_5",
        name: "lower_threshold__corrente_5",
        label: "Soglia inferiore",
        rules: [ { required: true, message: "Inserisci la soglia inferiore!" } ],
        type: "number"
    },
    {
        key: "upper_threshold_corrente_5",
        name: "upper_threshold__corrente_5",
        label: "Soglia superiore",
        rules: [ 
            { required: true, message: "Inserisci la soglia superiore!" } ,
            ({ getFieldValue }: any) => ({
                validator(value: any) {
                const min = Number(getFieldValue('lower_threshold') || "-1") 
                const max = Number(getFieldValue('upper_threshold')) 
                if (!value || min <= max || min === -1) {
                    return Promise.resolve();
                }
                return Promise.reject('La soglia superiore deve essere maggiore della soglia inferiore.');
                },
            })
        ],
        type: "number"
    },
    {
        key: "delay_open_corrente_5",
        name: "delay_open__corrente_5",
        label: "Delay apertura",
        rules: [ { required: true, message: "Inserisci delay apertura!" } ],
        type: "number"
    },
    {
        key: "delay_close_corrente_5",
        name: "delay_close__corrente_5",
        label: "Delay chiusura",
        rules: [ { required: true, message: "Inserisci la delay chiusura!" } ],
        type: "number"
    }
]

export const corrente_80: InputType[] = [
    {
        key: "enabled_corrente_80",
        name: "enabled__corrente_80",
        label: "radio",
        type: "radio"
    },
    {
        key: "level_corrente_80",
        name: "level__corrente_80",
        label: "Livello",
        type: "select",
        rules: [ { required: true, message: "Inserisci il livello!" } ]
    },
    {
        key: "lower_threshold_corrente_80",
        name: "lower_threshold__corrente_80",
        label: "Soglia inferiore",
        rules: [ { required: true, message: "Inserisci la soglia inferiore!" } ],
        type: "number"
    },
    {
        key: "upper_threshold_corrente_80",
        name: "upper_threshold__corrente_80",
        label: "Soglia superiore",
        rules: [ 
            { required: true, message: "Inserisci la soglia superiore!" } ,
            ({ getFieldValue }: any) => ({
                validator(value: any) {
                const min = Number(getFieldValue('lower_threshold') || "-1") 
                const max = Number(getFieldValue('upper_threshold')) 
                if (!value || min <= max || min === -1) {
                    return Promise.resolve();
                }
                return Promise.reject('La soglia superiore deve essere maggiore della soglia inferiore.');
                },
            })
        ],
        type: "number"
    },
    {
        key: "delay_open_corrente_80",
        name: "delay_open__corrente_80",
        label: "Delay apertura",
        rules: [ { required: true, message: "Inserisci delay apertura!" } ],
        type: "number"
    },
    {
        key: "delay_close_corrente_80",
        name: "delay_close__corrente_80",
        label: "Delay chiusura",
        rules: [ { required: true, message: "Inserisci la delay chiusura!" } ],
        type: "number"
    }
]

export const presenza_5: InputType[] = [
    {
        key: "enabled_presenza_5",
        name: "enabled__presenza_5",
        label: "radio",
        type: "radio"
    },
    {
        key: "level_presenza_5",
        name: "level__presenza_5",
        label: "Livello",
        type: "select",
        rules: [ { required: true, message: "Inserisci il livello!" } ]
    },
    {
        key: "upper_threshold_presenza_5",
        name: "upper_threshold__presenza_5",
        label: "Soglia superiore",
        rules: [ 
            { required: true, message: "Inserisci la soglia superiore!" } ,
            ({ getFieldValue }: any) => ({
                validator(value: any) {
                const min = Number(getFieldValue('lower_threshold') || "-1") 
                const max = Number(getFieldValue('upper_threshold')) 
                if (!value || min <= max || min === -1) {
                    return Promise.resolve();
                }
                return Promise.reject('La soglia superiore deve essere maggiore della soglia inferiore.');
                },
            })
        ],
        type: "number"
    },
    {
        key: "delay_open_presenza_5",
        name: "delay_open__presenza_5",
        label: "Delay apertura",
        rules: [ { required: true, message: "Inserisci delay apertura!" } ],
        type: "number"
    },
    {
        key: "delay_close_presenza_5",
        name: "delay_close__presenza_5",
        label: "Delay chiusura",
        rules: [ { required: true, message: "Inserisci la delay chiusura!" } ],
        type: "number"
    }
]

export const presenza_80: InputType[] = [
    {
        key: "enabled_presenza_80",
        name: "enabled__presenza_80",
        label: "radio",
        type: "radio"
    },
    {
        key: "level_presenza_80",
        name: "level__presenza_80",
        label: "Livello",
        type: "select",
        rules: [ { required: true, message: "Inserisci il livello!" } ]
    },
    {
        key: "upper_threshold_presenza_80",
        name: "upper_threshold__presenza_80",
        label: "Soglia superiore",
        rules: [ 
            { required: true, message: "Inserisci la soglia superiore!" } ,
            ({ getFieldValue }: any) => ({
                validator(value: any) {
                const min = Number(getFieldValue('lower_threshold') || "-1") 
                const max = Number(getFieldValue('upper_threshold')) 
                if (!value || min <= max || min === -1) {
                    return Promise.resolve();
                }
                return Promise.reject('La soglia superiore deve essere maggiore della soglia inferiore.');
                },
            })
        ],
        type: "number"
    },
    {
        key: "delay_open_presenza_80",
        name: "delay_open__presenza_80",
        label: "Delay apertura",
        rules: [ { required: true, message: "Inserisci delay apertura!" } ],
        type: "number"
    },
    {
        key: "delay_close_presenza_80",
        name: "delay_close__presenza_80",
        label: "Delay chiusura",
        rules: [ { required: true, message: "Inserisci la delay chiusura!" } ],
        type: "number"
    }
]

export const temperatura_alimentatore: InputType[] = [
    {
        key: "enabled_temperatura_alimentatore",
        name: "enabled__temperatura_alimentatore",
        label: "radio",
        type: "radio"
    },
    {
        key: "level_temperatura_alimentatore",
        name: "level__temperatura_alimentatore",
        label: "Livello",
        type: "select",
        rules: [ { required: true, message: "Inserisci il livello!" } ]
    },
    {
        key: "lower_threshold_temperatura_alimentatore",
        name: "lower_threshold__temperatura_alimentatore",
        label: "Soglia inferiore",
        rules: [ { required: true, message: "Inserisci la soglia inferiore!" } ],
        type: "number"
    },
    {
        key: "upper_threshold_temperatura_alimentatore",
        name: "upper_threshold__temperatura_alimentatore",
        label: "Soglia superiore",
        rules: [ 
            { required: true, message: "Inserisci la soglia superiore!" } ,
            ({ getFieldValue }: any) => ({
                validator(value: any) {
                const min = Number(getFieldValue('lower_threshold') || "-1") 
                const max = Number(getFieldValue('upper_threshold')) 
                if (!value || min <= max || min === -1) {
                    return Promise.resolve();
                }
                return Promise.reject('La soglia superiore deve essere maggiore della soglia inferiore.');
                },
            })
        ],
        type: "number"
    },
    {
        key: "delay_open_temperatura_alimentatore",
        name: "delay_open__temperatura_alimentatore",
        label: "Delay apertura",
        rules: [ { required: true, message: "Inserisci delay apertura!" } ],
        type: "number"
    },
    {
        key: "delay_close_temperatura_alimentatore",
        name: "delay_close__temperatura_alimentatore",
        label: "Delay chiusura",
        rules: [ { required: true, message: "Inserisci la delay chiusura!" } ],
        type: "number"
    }
]

export const attivazione_impianto: InputType[] = [
    {
        key: "enabled_attivazione_impianto",
        name: "enabled__attivazione_impianto",
        label: "radio",
        type: "radio"
    },
    {
        key: "delay_open_attivazione_impianto",
        name: "delay_open__attivazione_impianto",
        label: "Delay apertura",
        rules: [ { required: true, message: "Inserisci delay apertura!" } ],
        type: "number"
    },
    {
        key: "delay_close_attivazione_impianto",
        name: "delay_close__attivazione_impianto",
        label: "Delay chiusura",
        rules: [ { required: true, message: "Inserisci la delay chiusura!" } ],
        type: "number"
    },
    {
        key: "notification_enabled_attivazione_impianto",
        name: "notification_enabled__attivazione_impianto",
        label: "Notifiche",
        type: "checkbox"
    },
    {
        key: "notification_email_attivazione_impianto",
        name: "notification_email__attivazione_impianto",
        label: "Email notifiche"
    }
]

export const levelOptions = [
    {0: "info"}, 
    {1: "warning"}, 
    {2: "error"}, 
    {3: "fatal"}
]