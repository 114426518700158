import {Action} from "../actions"

export type NavbarState = {
    selected: string
    panel_open: boolean
}

export const initialState: NavbarState = {
    selected: "customer",
    panel_open: false
}

const navbar = (state: NavbarState = initialState, action: Action) => {
    switch(action.type){
        case "SET_SELECTED_ITEM":
            return {
                ...state,
                selected: action.item
            }
        case "SET_PANEL_IS_OPEN":
            return {
                ...state,
                panel_open: action.open
            }
        default:
            return state
    }
}

export default navbar