import {message} from 'antd';
import {PoweroffOutlined, UserOutlined} from '@ant-design/icons';
import "./Header.css"
import {logout} from '../../api/services/loginService';
import {useHistory} from 'react-router';
import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import {routes} from "../pages/costants";
import {AppBar, Avatar, IconButton, Menu, MenuItem, Toolbar} from "@material-ui/core";
import {useStyles} from "../../constants/muiStyles";
import logo from '../../logo/logo_intero_trasparente.png';
import logo_parziale from '../../logo/logo_parziale_trasparente.png'

type HeaderItemProps = {
    SetLogged: (b: boolean) => void,
    openNavbar: () => void
}

const HeaderItem: React.FC<HeaderItemProps> = ({SetLogged, openNavbar}) => {
    const [width, setWidth] = useState<number>(window.innerWidth)
    const [heigth, setHeigth] = useState<number>(window.innerHeight)
	function handleWindowSizeChange() {
			setWidth(window.innerWidth)
            setHeigth(window.innerHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);
    
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const history = useHistory();
    const logOut = () => {
		logout().then(res => {
			if(res!== undefined && res.status === "success"){
				history.push(res.location)
			    SetLogged(false);
			}else{
                message.error(res?.err?.message || "Errore durante il logout.")
            }
		})
	};
    const openMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    return(
        <AppBar
            position="fixed"
            className={clsx(classes.appBar)}
        >

            <Toolbar className={"header"}>
                {
                    width > 768 && heigth > 414 ?
                    <img
                        onClick={() => history.push(routes.mainPage)}
                        key="logo" 
                        className={'logo'} 
                        alt="logo" 
                        src={logo}
                        height="81px"
                        width="460px"
                    /> : 
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={openNavbar}
                        style={{marginLeft: '2%'}}
                    >
                        <img 
                            key="logo_parziale" 
                            className="logo_parziale"
                            alt="logo" 
                            src={logo_parziale}
                            height="40px"
                            width="34px"
                        />
                    </IconButton>                    
                }
                <Avatar onClick={openMenu} src="/broken-image.jpg" />
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={closeMenu}
                >
                    <MenuItem
                        onClick={() => {
                            history.push(routes.profile);
                            closeMenu();
                        }}
                    >
                        <UserOutlined/> Profilo
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            closeMenu();
                            logOut();
                        }}
                    >
                        <PoweroffOutlined/> Logout
                    </MenuItem>
                </Menu>

            </Toolbar>
        </AppBar>
    )
}

export default HeaderItem;