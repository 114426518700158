import {GetAlarmsUrl, GetDataStepUrl, GetDataUrl, GetExportInfoUrl, rowsPerTablePage} from "../constants";
import {GetDataRequest, GetExportInfoRequest} from "../requests/timeSeries";
import {methodGet, verticalFetch} from "./httpRequests";

export const getData = (req: GetDataRequest) => {
    return verticalFetch(methodGet, GetDataUrl(req))
}

export const getStepData = (req: GetDataRequest) => {
    return verticalFetch(methodGet, GetDataStepUrl(req))    
}

export const getAlarms = (page: string, sort: string, search: string) => {
    return verticalFetch(methodGet, `${GetAlarmsUrl}?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`)
}

export const getExportInfo = (req: GetExportInfoRequest) => {
    return verticalFetch(methodGet, GetExportInfoUrl(req))
}