import './summary.css'
import React, {} from 'react';
import {Card, Divider} from 'antd';
import { Stair } from '../../../../api/requests/stairsService';
import moment from 'moment';
import Title from "antd/es/typography/Title";
import {Typography} from "@material-ui/core";
import CustomTag from "./customTag";


type StairSummaryProps = {
    selected_stair: Stair | null
}

const StairSummary: React.FC<StairSummaryProps> = ({ selected_stair }) => {

    const checkLastSeenOn = (last_seen_on: string) => {
        if(last_seen_on === "online"){    //5 minuti
            return <CustomTag color="green" text="Online" />
        }else{
            return <CustomTag color="red" text="Offline" /> 
        }
    }

    return(
            <Card
                hoverable
                title={<Title level={3} className={"summaryTitle"}> Installazione </Title>}
                className={"summary-card"}
            >
                <div key={`container_div_1`}>
                    <div className="tag-container">
                        <Typography>Nome:</Typography>
                        <Typography>{selected_stair?.name || ""}</Typography>
                    </div>
                </div>
                <Divider />

                <div key={`container_div_2`}>
                    <div className="tag-container">
                        <Typography>Stato dispositivo:</Typography>
                        { selected_stair ? checkLastSeenOn(selected_stair.status) : ""}
                    </div>
                    <Divider />
                </div>
                <div key={`container_div_3`}>
                    <div className="tag-container">
                        <Typography>Modello:</Typography>
                        <Typography> {selected_stair?.model|| ""} </Typography>
                    </div>
                </div>
                <Divider />
                <div key={`container_div_3`}>
                    <div className="tag-container">
                        <Typography>Data creazione:</Typography>
                        <Typography> {moment(selected_stair?.created_at || "").format('DD/MM/YYYY')} </Typography>
                    </div>
                </div>
            </Card>
    )
}

export default StairSummary