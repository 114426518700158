import {ResponsiveTableCell, ResponsiveTableColumnType} from "../../responsiveTable";
import "../../../App.css";
import { linkType, localeDateTimeFormatter, stringType } from "../../../utilities/utilities";


const diffDateAndToString = (small: Date, big: Date) => {
    const Difference_In_Time = big.getTime() - small.getTime()
    const Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24))
    const Hours = Math.floor(((Difference_In_Time / (60 * 1000)) / 60) - (24 * Days))
    const Mins = Math.floor((Difference_In_Time / (60 * 1000)) - (1440 * Days) - (60 * Hours))
    const diffDate = new Date(Difference_In_Time)
    var result = ''
    if (Mins < 60) {
        result = Mins + 'm'
    } else{
        if (Hours < 24) {
            result = diffDate.getMinutes() + 'h'
        }else{
            result = Days + 'd'
        }
    }
    return { result, Days, Mins, Hours }
}

export const alarmColumns: (link: (id: string) => void) => ResponsiveTableColumnType[] = link => [
    {
        label: "",
        name: "installation_id",
        options: {
            sort: false,
            filter: true,
            display: false,
            customBodyRender: (value) => <ResponsiveTableCell type={stringType} value={value} />
        },
    },
    {
        label: "Installazione",
        name: "installation_name",
        options: {
            sort: true,
            filter: true,
            customBodyRender: (value, data) => <ResponsiveTableCell type={linkType} value={value} link={() => {link(data.rowData[0])}} />
        },
    },
    {
        label: "Inizio",
        name: "start",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value) => <ResponsiveTableCell type={stringType} value={localeDateTimeFormatter(value)}/>
        },
    },
    {
        label: "Fine",
        name: "finish",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value) =>  <ResponsiveTableCell type={stringType} value={value !== "0001-01-01T00:00:00Z" ? localeDateTimeFormatter(value) : ""}/>
        },
    },
    {
        label: "Duration",
        name: "duration",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value, data) => {
                const start = new Date(String(data.tableData[data.rowIndex][2] as string))
                const finish = String(data.tableData[data.rowIndex][3]) !== '0001-01-01T00:00:00Z' ? new Date(String(data.tableData[data.rowIndex][3] as string)) : new Date()
                const difference = diffDateAndToString(start, finish)
                return <ResponsiveTableCell 
                    type={stringType} 
                    value={`${difference.Days} days ${difference.Hours} hours ${difference.Mins} mins`}
                />
            }
        },
    },
    {
        label: "Allarme",
        name: "tag",
        options: {
            sort: true,
            filter: true,
            customBodyRender: (value) =>  <ResponsiveTableCell type={stringType} value={value} />
        },
    },
    {
        label: "Livello",
        name: "level",
        options: {
            sort: true,
            filter: true,
            customBodyRender: (value) => <ResponsiveTableCell type={"tag"} value={levelCheck(Number(value))} color={value === 0 ? "green" : value === 1 ? "orange" : "red"} />
        },
    }
]

const levelCheck = (test: number) => {
    let result: string = ""
    switch(test){
        case 0:
            result = "info"
            break
        case 1:
            result = "warning"
            break
        case 2: 
            result = "error"
            break
        case 3: 
            result = "fatal"
            break
    }
    return result
}

const payloadCheck = (p: string) => {
    let result: string = p;
    if (p.includes("connected_at")){
        let tmpjson = JSON.parse(p)
        result = `Connesso alle ${localeDateTimeFormatter(tmpjson.connected_at)}`
    }
    if(p.includes("disconnected_at")){
        let tmpjson = JSON.parse(p)
        result = `Disconnesso alle ${localeDateTimeFormatter(tmpjson.disconnected_at)}`
    }
    return result
}