import './App.css';
import LoginPanel from './containers/pages/LoginPanel'
import ResetPanel from './containers/pages/ResetPanel'
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import { routes } from './components/pages/costants';
import MainPage from './containers/pages/Mainpage'
import RecoverPassword from './components/pages/recoverPassword/RecoverPassword';
import ResultResetPassword from './components/pages/resetPassword/Result';
import ResultRecoverPassword from './components/pages/recoverPassword/Result';

declare global {
  interface Window {
    _env_: any,
      api: any
  }
}

function App() {
    return (

    <BrowserRouter>
        <Switch>
            <Route exact path={routes.login} component={LoginPanel} />
            <Route exact path={routes.recoverStart} component={ResetPanel} />
            <Route exact path={routes.recoverEnd} component={RecoverPassword} />
            <Route exact path={routes.resetResult} component={ResultResetPassword} />
            <Route exact path={routes.recoverResult} component={ResultRecoverPassword} />
            <Route component={MainPage} />
        </Switch>
    </BrowserRouter>
    );
}

export default App;