import { Badge, Descriptions, message } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { GetSignalsCalibration } from "../../../api/services/stairsService";
import TitleWithBack from "../../../containers/TitleWithBack";
import ButtonItem from "../../ButtonItem";
import ResponsiveTable from "../../responsiveTable";
import { Calibration } from "../../types/jobs/jobs";
import { configurationSignalsPageUrl } from "../costants";
import './ConfigurationList.css'
import { configurationData, configurationsListColumns } from "./tableFields";

type ConfigurationListProps = {
    installation_id: string
}

const ConfigurationList: React.FC<ConfigurationListProps> = ({ installation_id }) => {

    document.body.style.background = "#f5f5f5c7"
    
    const history = useHistory()
    const [calibration, setCalibration] = useState<Calibration | null>(null)
    const [ lastSetStatus, setLastSetStatus ] = useState<string>("")
    useEffect(() => {
        GetSignalsCalibration(installation_id).then((res) => {
            if(res && !res.err){
                setCalibration(res.calibrations.signals)
                setLastSetStatus(res.calibrations.last_set_status)
            }else{
                message.error(res?.err?.message || "Operazione fallita.")
            }
       })
    }, [])

    const checkLastStatus = (last_set_status: string) => {
        switch (last_set_status) {
            case "done":
                return "Eseguito Correttamente"
            case "pendng":
                return "In Esecuzione"
            case "failed":
                return "Fallito"
            default:
                return "Mai Effettuato"
        }
    }

    if(!calibration){
        return null
    }

    //Temperatura alimentatore
    configurationData[0].enabled = calibration?.temp_enabled === 0 ? "Disabilitato" : "Abilitato"
    configurationData[0].value = "Offset: " + calibration?.temp_offset
    configurationData[0].status = lastSetStatus
    //Corrente assorbita alimentatore 80 Vdc
    configurationData[1].enabled = calibration?.c80_enabled === 0 ? "Disabilitato" : "Abilitato"
    configurationData[1].value = "N° avvolgimenti " + calibration?.c80_coils
    configurationData[1].status = lastSetStatus
    //Corrente assorbita alimentatore 5 Vdc
    configurationData[2].enabled = calibration?.c5_enabled=== 0 ? "Disabilitato" : "Abilitato"
    configurationData[2].value = "N° avvolgimenti " + calibration?.c5_coils
    configurationData[2].status = lastSetStatus
    //Tensione alimentazione motori 80 Vdc
    configurationData[3].enabled = calibration?.v80_enabled=== 0 ? "Disabilitato" : "Abilitato"
    configurationData[3].value = "Offset: " + calibration?.v80_offset
    configurationData[3].status = lastSetStatus
    //Tensione alimentazione schede 5 Vdc
    configurationData[4].enabled = calibration?.v5_enabled === 0 ? "Disabilitato" : "Abilitato"
    configurationData[4].value = "Offset: " + calibration?.v5_offset
    configurationData[4].status = lastSetStatus

    return (
        <>
            <TitleWithBack title="Calibrazione Segnali" key="signal_calibration_title" />
            <Descriptions bordered>
                <Descriptions.Item label="Stato calibrazione segnali">
                    <Badge status="success" text={checkLastStatus(lastSetStatus)} color="geekblue" />  
                </Descriptions.Item>
            </Descriptions>
            <div className="button-container">
                <ButtonItem buttonType="primary" label="Modifica" key="signal_calibration_button" buttonOnClick={() => {history.push(configurationSignalsPageUrl(installation_id))}} />
            </div>
            <ResponsiveTable data={configurationData} columns={configurationsListColumns} />
        </>
    )
}

export default ConfigurationList;