import { LockOutlined } from "@ant-design/icons";
import { InputType } from "../../types/form/types";
import { confirmPasswordRules, passwordRules } from "../costants";

export const inputs: InputType[] = [
    {   
        key: "password",
        name: "password",
        label: "Password",
        type: "password",
        rules: passwordRules,
        icon: <LockOutlined className="site-form-item-icon" />            
    },
    {
        key: "confirm_password",
        name: "confirm_password",
        label: "Conferma password",
        type: "password",
        rules: confirmPasswordRules,
        icon: <LockOutlined className="site-form-item-icon" />   
    }
]