import {ChartObject, GetDataRequest} from "../api/requests/timeSeries"
import {getData, getStepData} from "../api/services/timeSeries"
import {Store} from "../reducers"
import {ZoomUpdates} from "../components/types/timeseries/types";
import { avg, sum } from "./constants";

export type SET_PANEL_TEMPERATURE_AGGREGATIONS = 'SET_PANEL_TEMPERATURE_AGGREGATIONS';
export type SET_VOLTAGE80_AGGREGATIONS = 'SET_VOLTAGE80_AGGREGATIONS';
export type SET_POWER80_AGGREGATIONS = 'SET_POWER80_AGGREGATIONS';
export type SET_VOLTAGE5_AGGREGATIONS = 'SET_VOLTAGE5_AGGREGATIONS';
export type SET_POWER5_AGGREGATIONS = 'SET_POWER5_AGGREGATIONS';
export type SET_MOTOR_SPEED_AGGREGATIONS = 'SET_MOTOR_SPEED_AGGREGATIONS';
export type SET_MOTOR_OVERCURRENT_AGGREGATIONS = 'SET_MOTOR_OVERCURRENT_AGGREGATIONS';

export type ADD_PANEL_TEMPERATURE_DATA = 'ADD_PANEL_TEMPERATURE_DATA';
export type ADD_VOLTAGE80_DATA = 'ADD_VOLTAGE80_DATA';
export type ADD_POWER80_DATA = 'ADD_POWER80_DATA';
export type ADD_VOLTAGE5_DATA = 'ADD_VOLTAGE5_DATA';
export type ADD_POWER5_DATA = 'ADD_POWER5_DATA';
export type ADD_MOTOR_SPEED_DATA = 'ADD_MOTOR_SPEED_DATA';
export type ADD_MOTOR_OVERCURRENT_DATA = 'ADD_MOTOR_OVERCURRENT_DATA';

export type SET_PANEL_TEMPERATURE_ZOOM = 'SET_PANEL_TEMPERATURE_ZOOM';
export type SET_VOLTAGE80_ZOOM = 'SET_VOLTAGE80_ZOOM';
export type SET_POWER80_ZOOM = 'SET_POWER80_ZOOM';
export type SET_VOLTAGE5_ZOOM = 'SET_VOLTAGE5_ZOOM';
export type SET_POWER5_ZOOM = 'SET_POWER5_ZOOM';
export type SET_MOTOR_SPEED_ZOOM = 'SET_MOTOR_SPEED_ZOOM';
export type SET_MOTOR_OVERCURRENT_ZOOM = 'SET_MOTOR_OVERCURRENT_ZOOM';

export type SET_INTERVAL = 'SET_INTERVAL';
export type CLEAR_INTERVAL = 'CLEAR_INTERVAL';
export type SET_DATA_START_DATE = 'SET_DATA_START_DATE';

export type Aggregation = "avg" | "max" | "min" | "sum";
export type SET_START_HOUR = 'SET_START_HOUR';
export type RESET_START_DATE = 'RESET_START_DATE';

export interface resetStartDate {
    type: RESET_START_DATE
}

export interface SetStartHour {
    type: SET_START_HOUR,
    h: number
}

//aggregations
export interface SetPanelTemperatureAggregations {
    type: SET_PANEL_TEMPERATURE_AGGREGATIONS
    aggregations: string[]
}

export interface SetVoltage80Aggregations {
    type: SET_VOLTAGE80_AGGREGATIONS
    aggregations: string[]
}

export interface SetPower80Aggregations {
    type: SET_POWER80_AGGREGATIONS
    aggregations: string[]
}

export interface SetVoltage5Aggregations {
    type: SET_VOLTAGE5_AGGREGATIONS
    aggregations: string[]
}

export interface SetPower5Aggregations {
    type: SET_POWER5_AGGREGATIONS
    aggregations: string[]
}

export interface SetMotorSpeedAggregations {
    type: SET_MOTOR_SPEED_AGGREGATIONS
    aggregations: string[]
}

export interface SetMotorOvercurrentAggregations {
    type: SET_MOTOR_OVERCURRENT_AGGREGATIONS
    aggregations: string[]
}

//chart data
export interface AddPanelTemperatureData {
    type: ADD_PANEL_TEMPERATURE_DATA
    data: ChartObject
    lastTimestamp: Date
}

export interface AddVoltage80ChartData {
    type: ADD_VOLTAGE80_DATA
    data: ChartObject
    lastTimestamp: Date
}

export interface AddPower80ChartData {
    type: ADD_POWER80_DATA
    data: ChartObject
    lastTimestamp: Date
}

export interface AddVoltage5ChartData {
    type: ADD_VOLTAGE5_DATA
    data: ChartObject
    lastTimestamp: Date
}

export interface AddPower5ChartData {
    type: ADD_POWER5_DATA
    data: ChartObject
    lastTimestamp: Date
}

export interface AddMotorSpeedChartData {
    type: ADD_MOTOR_SPEED_DATA
    data: ChartObject
    lastTimestamp: Date
}

export interface AddMotorOvercurrentChartData {
    type: ADD_MOTOR_OVERCURRENT_DATA
    data: ChartObject
    lastTimestamp: Date
}

//zoom
export interface SetPanelTemperatureZoom {
    type: SET_PANEL_TEMPERATURE_ZOOM
    zoomUpd: ZoomUpdates
}

export interface SetVoltage80ChartZoom {
    type: SET_VOLTAGE80_ZOOM
    zoomUpd: ZoomUpdates
}

export interface SetPower80ChartZoom {
    type: SET_POWER80_ZOOM
    zoomUpd: ZoomUpdates
}

export interface SetVoltage5ChartZoom {
    type: SET_VOLTAGE5_ZOOM
    zoomUpd: ZoomUpdates
}

export interface SetPower5ChartZoom {
    type: SET_POWER5_ZOOM
    zoomUpd: ZoomUpdates
}

export interface SetMotorSpeedChartZoom {
    type: SET_MOTOR_SPEED_ZOOM
    zoomUpd: ZoomUpdates
}

export interface SetMotorOvercurrentChartZoom {
    type: SET_MOTOR_OVERCURRENT_ZOOM
    zoomUpd: ZoomUpdates
}

export interface SetInterval {
    type: SET_INTERVAL
    interval: NodeJS.Timeout 
}

export interface ClearInterval {
    type: CLEAR_INTERVAL
    reset: boolean
}

export interface setDataStartDate {
    type: SET_DATA_START_DATE,
    start_date: Date
}

const addMinutes = (date: Date, bucket: string) => {
    let minutes: number = 0
    if(bucket === "5m"){
        minutes = 5
    }
    if(bucket === "30m"){
        minutes = 30
    }
    if(bucket === "1h"){
        minutes = 60
    }
    return new Date(date.getTime() + minutes*60000);
}

const getRoundedDate = (bucket: string, d=new Date())=> {
    switch (bucket) {
        case "1m":
            d.setSeconds(0);
            d.setMilliseconds(0);
            break;
        case "2m":
            d.setMinutes(d.getMinutes() - d.getMinutes()% 2 );
            d.setSeconds(0);
            d.setMilliseconds(0);
            break;
        case "5m":
            d.setMinutes(d.getMinutes() - d.getMinutes()% 5 );
            d.setSeconds(0);
            d.setMilliseconds(0);
            break;
        case "30m":
            d.setMinutes(d.getMinutes() - d.getMinutes()% 30 );
            d.setSeconds(0);
            d.setMilliseconds(0);
            break;
        case "1h":
            d.setMinutes(0);
            d.setSeconds(0);
            d.setMilliseconds(0);
            break;
        case "5h":
            d.setHours(d.getHours() - d.getHours() % 5);
            d.setMinutes(0);
            d.setSeconds(0);
            d.setMilliseconds(0);
            break;
        case "10h":
            d.setHours(d.getHours() - d.getHours() % 10);
            d.setMinutes(0);
            d.setSeconds(0);
            d.setMilliseconds(0);
            break;
    }


    return new Date(d)
}

const updateChartData = (
    dev: string,
    a: Aggregation[],
    dispatch: any,
    getState: any,
    tag: string,
    variable: string,
    bucket: string,
    type: ADD_PANEL_TEMPERATURE_DATA | ADD_VOLTAGE80_DATA | ADD_POWER80_DATA | ADD_VOLTAGE5_DATA | ADD_POWER5_DATA | ADD_MOTOR_SPEED_DATA | ADD_MOTOR_OVERCURRENT_DATA,
    step?: string
) => {
    const state: Store = getState()
    const d = new Date()
    d.setHours(d.getHours() - state.chart.startHour)
    let start = new Date(d)
    switch (type) {
        case "ADD_PANEL_TEMPERATURE_DATA":
            if(state.chart.last_panel_temperature_data_ts && !isNaN(state.chart.last_panel_temperature_data_ts.getTime())) {
                start = state.chart.last_panel_temperature_data_ts;
            }
            break;
        case "ADD_VOLTAGE80_DATA":
            if(state.chart.last_voltage80_data_ts && !isNaN(state.chart.last_voltage80_data_ts.getTime())) {
                start = state.chart.last_voltage80_data_ts;
            }
            break;
        case "ADD_POWER80_DATA":
            if(state.chart.last_power80_data_ts && !isNaN(state.chart.last_power80_data_ts.getTime())) {
                start = state.chart.last_power80_data_ts;
            }
            break;
        case "ADD_VOLTAGE5_DATA":
            if(state.chart.last_voltage5_data_ts && !isNaN(state.chart.last_voltage5_data_ts.getTime())) {
                start = state.chart.last_voltage5_data_ts;
            }
            break;
        case "ADD_POWER5_DATA":
            if(state.chart.last_power5_data_ts && !isNaN(state.chart.last_power5_data_ts.getTime())) {
                start = state.chart.last_power5_data_ts;
            }
            break;
        case "ADD_MOTOR_SPEED_DATA":
            if(state.chart.last_motor_speed_data_ts && !isNaN(state.chart.last_motor_speed_data_ts.getTime())) {
                start = state.chart.last_motor_speed_data_ts;
            }
            break;
        case "ADD_MOTOR_OVERCURRENT_DATA":
            if(state.chart.last_motor_overcurrent_data_ts && !isNaN(state.chart.last_motor_overcurrent_data_ts.getTime())) {
                start = state.chart.last_motor_overcurrent_data_ts;
                }
                break;
    }

    const s = addMinutes(getRoundedDate(bucket, start), bucket);
    if(!step){
        if(type !== "ADD_MOTOR_SPEED_DATA" && type !== "ADD_MOTOR_OVERCURRENT_DATA"){
            let req: GetDataRequest = {
                device_id: dev,
                tag: `${tag}.${variable}`,
                start: new Date(s).toISOString(),
                end: getRoundedDate(bucket).toISOString(),
                bucket: bucket,
                sort: "ASC",
                aggregation: a
            }

            getData(req).then(
                (res)=> {
                    if(res && res.result){
                        dispatch({
                            type: type,
                            data: res.result,
                            lastTimestamp: res.result.last_ts
                        })
                    }
                }
            )
        }
    }else{
        let req: GetDataRequest = {
            device_id: dev,
            step_id: step, 
            tag: `${tag}.${variable}`,
            start: new Date(s).toISOString(),
            end: getRoundedDate(bucket).toISOString(),
            bucket: bucket,
            sort: "ASC",
            aggregation: a
        }

        getStepData(req).then(
            (res)=> {
                if(res && res.result){
                    dispatch({
                        type: type,
                        data: res.result,
                        lastTimestamp: res.result.last_ts
                    }) 
                }
            }
        )
    }
    
}

const startDataUpdater = (dispatch: any, dev: string, a: Aggregation[], getState: any, period: string, interval: number, step?: string) => {
    updateChartData(dev, [avg], dispatch, getState, "data", "t", period, "ADD_PANEL_TEMPERATURE_DATA")
    updateChartData(dev, [avg], dispatch, getState, "data", "b80", period, "ADD_VOLTAGE80_DATA")
    updateChartData(dev, [sum], dispatch, getState, "data", "c80", period, "ADD_POWER80_DATA")
    updateChartData(dev, [avg], dispatch, getState, "data", "b5", period, "ADD_VOLTAGE5_DATA")
    updateChartData(dev, [sum], dispatch, getState, "data", "c5", period, "ADD_POWER5_DATA")
    updateChartData(dev, [avg], dispatch, getState, "*", "spd", period, "ADD_MOTOR_SPEED_DATA", step)
    updateChartData(dev, [avg], dispatch, getState, "*", "covr", period, "ADD_MOTOR_OVERCURRENT_DATA", step)
    const intervalId = setInterval( () => {
        updateChartData(dev, [avg], dispatch, getState, "data", "t", period, "ADD_PANEL_TEMPERATURE_DATA")
        updateChartData(dev, [avg], dispatch, getState, "data", "b80", period, "ADD_VOLTAGE80_DATA")
        updateChartData(dev, [sum], dispatch, getState, "data", "c80", period, "ADD_POWER80_DATA")
        updateChartData(dev, [avg], dispatch, getState, "data", "b5", period, "ADD_VOLTAGE5_DATA")
        updateChartData(dev, [sum], dispatch, getState, "data", "c5", period, "ADD_POWER5_DATA")
        updateChartData(dev, [avg], dispatch, getState, "*", "spd", period, "ADD_MOTOR_SPEED_DATA", step)
        updateChartData(dev, [avg], dispatch, getState, "*", "covr", period, "ADD_MOTOR_OVERCURRENT_DATA", step)
    }, interval)
    dispatch({
        type: "SET_INTERVAL",
        interval: intervalId
    })
}

export const startUpdateChartData = (dev: string, a: Aggregation[], period: string, interval: number, step?: string) => async (
    dispatch: any,
    getState: any
) => {
    startDataUpdater(dispatch, dev, a, getState, period, interval, step)
}

export const setDataStartDate = (reset: boolean, dev: string, a: Aggregation[], startDate: Date, period: string, interval: number, step?: string) => async (
    dispatch: any,
    getState: any
) => {
    dispatch({
        type: "CLEAR_INTERVAL",
        reset: reset
    })
    dispatch({
        type: "SET_DATA_START_DATE",
        start_date: startDate
    })
    startDataUpdater(dispatch, dev, a, getState, period, interval, step)
}

export const setPeriod = (reset: boolean, dev: string, a: Aggregation[], period: string, interval: number, step?: string) => async (
    dispatch: any,
    getState: any   
) => {
    dispatch({
        type: "CLEAR_INTERVAL",
        reset: reset
    })
    dispatch({
        type: "RESET_START_DATE"
    })
    startDataUpdater(dispatch, dev, a, getState, period, interval, step)
}