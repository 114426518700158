import { connect } from 'react-redux'
import { Store } from '../../../reducers'

import {setDataStartDate, setPeriod, startUpdateChartData} from '../../../actions/lineChart'

import {bindActionCreators} from "redux";
import Step from '../../../components/pages/installation/steps/step/Step';

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
           setDataStartDate,
           setPeriod,
           startUpdateChartData,
           setMotorSpeedAggregations: (aggregations: string[]) => dispatch({type:'SET_MOTOR_SPEED_AGGREGATIONS', aggregations: aggregations}),
           setMotorOvercurrentAggregations: (aggregations: string[]) => dispatch({type:'SET_MOTOR_OVERCURRENT_AGGREGATIONS', aggregations: aggregations}),
           clearDataInterval: (reset: boolean) => dispatch({type: 'CLEAR_INTERVAL', reset: reset})
        },
        dispatch,
    );
}

const mapStateToProps = (state: Store) => {
    return {
        motor_speed_data: state.chart.motor_speed_data,
        motor_speed_zoom: state.chart.motor_speed_zoom,
        motor_speed_aggregations: state.chart.motor_speed_aggregations,
        motor_overcurrent_data: state.chart.motor_overcurrent_data,
        motor_overcurrent_zoom: state.chart.motor_overcurrent_zoom,
        motor_overcurrent_aggregations: state.chart.motor_overcurrent_aggregations
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Step)