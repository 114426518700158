import React, { useState } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import {getTheme} from "../themes";

// eslint-disable-next-line no-unused-vars
export const CustomThemeContext = React.createContext(
    {
        currentTheme: 'light',
        setTheme: (s: string)=>{
            console.log(s)
        }
        ,
    },
)

type CustomThemeProviderProps = {
    children: any
}

const CustomThemeProvider: React.FC<CustomThemeProviderProps> = ({children}) => {
    // Read current theme from localStorage or maybe from an api
    const currentTheme = 'light';

    // State to hold the selected theme name
    const [themeName, _setThemeName] = useState(currentTheme)

    // Retrieve the theme object by theme name
    const theme = getTheme(themeName)

    // Wrap _setThemeName to store new theme names in localStorage
    const setThemeName = (name: string) => {
        _setThemeName(name)
    }

    const contextValue = {
        currentTheme: themeName,
        setTheme: setThemeName,
    }

    return (
        <CustomThemeContext.Provider value={contextValue}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </CustomThemeContext.Provider>
    )
};

export default CustomThemeProvider;