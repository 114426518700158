import {message, Popconfirm, Switch} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import { Subscription } from "../../../api/requests/subscription";
import {getAllSubscriptions, updateSubscriptionStatus} from "../../../api/services/subscriptionService";
import '../../../App.css'
import { updateSubscriptionUrl} from "../costants";
import CustomTitle from "../../CustomTitle";
import './SelectSubscription.css'
import {useHistory} from "react-router-dom";
import {subscriptionsTableColumns} from "./inputs";
import ServerSideTable from "../../ServerSideTable";

type SelectSubscriptionPanelProps = {
    role: string,
}

const SelectSubscription: React.FC<SelectSubscriptionPanelProps> = ({ role}) => {

    document.body.style.background = "#f5f5f5c7"
    
    const [tableData, setTableData] = useState<Object[]>([]);
    const [totalDataLength, setTotalDataLength] = useState<number>(0);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [currentSort, setCurrentSort] = useState<string>("expiry_date");
    const [currentSearch, setCurrentSearch] = useState<string>('');

    const getSubscriptions = useCallback((page: number, sort: string, search: string) => {
        getAllSubscriptions(page.toString(), sort, search).then(
            res => {
                if(res && res.subscriptions)  {
                    setTableData(res.subscriptions)
                    setTotalDataLength(res?.total_count ?? 0 );
                } else {
                    setTableData([]);
                    setTotalDataLength(0);
                }
            }
        )
    }, [currentPage, currentSort]);

    useEffect(() => {
        getSubscriptions(currentPage, currentSort, currentSearch);
    }, [])

    const changePage = (page: number) => {
        setCurrentPage(page);
        getSubscriptions(page, currentSort, currentSearch);
    }

    const changeSort = (sort: string) => {
        setCurrentSort(sort);
        getSubscriptions(currentPage, sort, currentSearch);
    }

    const changeSearch = (search: string) => {
        setCurrentSearch(search);
        setCurrentPage(0);
        getSubscriptions(0, currentSort, search);
    }

    const history = useHistory();
    const linkFunction: (value: string) => void = (value: string) => { 
        history.push(updateSubscriptionUrl(value))
    }

    const updateState = (record: Subscription) => {
        return <div className="button">
            <Popconfirm 
                title="Sei sicuro?" 
                onConfirm={
                    () => {
                        updateSubscriptionStatus({subscription_id: record.id, status: record.status === "enabled" ? "disabled" : "enabled"}).then(
                            res => {
                                if (res && !res.error) {
                                    message.success("Aggiornamento eseguito correttamente", 4)
                                    getSubscriptions(currentPage, currentSort, currentSearch)
                                } else {
                                    message.error(res?.error || "Operazione fallita", 4)
                                }
                            }
                        )
                    }
                } 
                onCancel={()=>{}}
                okText="Si" 
                cancelText="No"
            >
                <Switch
                    size={"small"}
                    checked={record?.status === "enabled"}
                />
            </Popconfirm>
        </div>
    }

    return(
        <>
            <CustomTitle title={"Modifica Abbonamento"} />
            <ServerSideTable
                columns={subscriptionsTableColumns(linkFunction, role, updateState)}
                data={tableData}
                totalDataLength={totalDataLength}
                changeSort={changeSort}
                changePage={changePage}
                changeSearch={changeSearch}
            />
        </>
    )
    
}

export default SelectSubscription;