import { GetSubscriptionStairsUrl, UpdateSubscriptionExpDateUrl, UpdateSubscriptionInfoUrl, UpdateSubscriptionStatusUrl, SubscriptionsUrl, GetSubscriptionUrl, rowsPerTablePage, changeStatusUrl } from "../constants";
import {
    ChangeStatusRequest,
    CreateSubscriptionRequest,
    GetSubscriptionRequest, ListSubscriptionStairsRequest,
    UpdateSubscriptionExpiryRequest, UpdateSubscriptionInfoRequest, UpdateSubscriptionStatusRequest
} from "../requests/subscription";
import {methodGet, methodPost, methodPut, verticalFetch} from "./httpRequests";

//Get all subscriptions
export const getAllSubscriptions = (page: string, sort: string, search: string) => {
    return verticalFetch(methodGet, `${SubscriptionsUrl}?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`);
}

//Create a new subscription
export const addSubscription = (r: CreateSubscriptionRequest) => {
    return verticalFetch(methodPost, SubscriptionsUrl, JSON.stringify(r));
}

//Get a subscription
export const getSubscription = (r: GetSubscriptionRequest) => {
    return verticalFetch(methodGet, GetSubscriptionUrl(r.subscription_id));
}

//Update subscription information
export const updateSubscriptionInformation = (r: UpdateSubscriptionInfoRequest) => {
    return verticalFetch(methodPut, UpdateSubscriptionInfoUrl(r.subscription_id), JSON.stringify(r));
}

//Update subscription status
export const updateSubscriptionStatus = (r: UpdateSubscriptionStatusRequest) => {
    return verticalFetch(methodPut, UpdateSubscriptionStatusUrl(r.subscription_id), JSON.stringify(r));
}

//Update expiration date of a subscription
export const updateSubscriptionExpDate = (r: UpdateSubscriptionExpiryRequest) => {
    return verticalFetch(methodPut, UpdateSubscriptionExpDateUrl(r.subscription_id), JSON.stringify(r));
}

//Get the stairs of a subscription
export const getSubscriptionStairs = (r: ListSubscriptionStairsRequest, page: string, sort: string, search: string) => {
    return verticalFetch(methodGet, `${GetSubscriptionStairsUrl(r.subscription_id)}?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`);
}

//Richiesta attivazione\disattivazione abbonamento
export const changeStatus = (request: ChangeStatusRequest) => {
    return verticalFetch(methodPost, changeStatusUrl(request.subscription_id), JSON.stringify(request))
}