import React, {useCallback, useEffect, useState} from "react";
import '../../../App.css'
import {updateInstallationTableColumns} from "./inputs";
import {GetAllStairs} from "../../../api/services/stairsService";
import CustomTitle from "../../CustomTitle";
import { useHistory } from "react-router";
import { updateInstallationUrl } from "../costants";
import ServerSideTable from "../../ServerSideTable";

type InstallationListPageProps = {
}

const InstallationsListPage: React.FC<InstallationListPageProps> = () => {
    
    document.body.style.background = "#f5f5f5c7"
    
    const [tableData, setTableData] = useState<Object[]>([]);
    const [totalDataLength, setTotalDataLength] = useState<number>(0);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [currentSort, setCurrentSort] = useState<string>("name");
    const [currentSearch, setCurrentSearch] = useState<string>('');

    const getStairs = useCallback((page: number, sort: string, search: string) => {
        GetAllStairs(page.toString(), sort, search).then(
            res => {
                if(res && res.stairs)  {
                    setTableData(res.stairs)
                    setTotalDataLength(res?.total_count ?? 0 );
                } else {
                    setTableData([]);
                    setTotalDataLength(0);
                }
            }
        )
    }, [currentPage, currentSort]);

    useEffect(() => {
        getStairs(currentPage, currentSort, currentSearch);
    }, [])

    const changePage = (page: number) => {
        setCurrentPage(page);
        getStairs(page, currentSort, currentSearch);
    }

    const changeSort = (sort: string) => {
        setCurrentSort(sort);
        getStairs(currentPage, sort, currentSearch);
    }

    const changeSearch = (search: string) => {
        setCurrentSearch(search);
        setCurrentPage(0);
        getStairs(0, currentSort, search);
    }


    const history = useHistory();
    const linkFunction: (value: string) => void = (value: string) => { 
        history.push(updateInstallationUrl(value))
    }

    return (
        <>
            <CustomTitle title={"Scegli Installazione Da Modificare"} />
            <ServerSideTable
                columns={updateInstallationTableColumns(linkFunction)}
                data={tableData}
                totalDataLength={totalDataLength}
                changeSort={changeSort}
                changePage={changePage}
                changeSearch={changeSearch}
            />
        </>
    )
}

export default InstallationsListPage;