import { ResponsiveTableCell, ResponsiveTableColumnType } from "../../responsiveTable";
import { dateType, stringType } from "../../../utilities/utilities";
import "../../../App.css";
import { Button, Popconfirm } from "antd";

export const tableColumn: (manageSubscription: (subscription_id: string, requested_status: string) => void) => ResponsiveTableColumnType[] = (manageSubscription) => [
    {
        label: "Utenza",
        name: "company_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={data.tableData[data.rowIndex].company_info.name}
                />
        }
    },
    {
        label: "Sdi",
        name: "company_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={data.tableData[data.rowIndex].company_info.sdi}
                />
        }
    },
    {
        label: "Pec",
        name: "company_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={data.tableData[data.rowIndex].company_info.pec}
                />
        }
    },
    {
        label: "Vat",
        name: "company_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={data.tableData[data.rowIndex].company_info.vat_number}
                />
        }
    },
    {
        label: "Telefono",
        name: "company_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={data.tableData[data.rowIndex].company_info.phone}
                />
        }
    },
    {
        label: "Indirizzo",
        name: "company_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={`${data.tableData[data.rowIndex].company_info.address}, ${data.tableData[data.rowIndex].company_info.city}, ${data.tableData[data.rowIndex].company_info.province}`}
                />

        }
    },
    {
        label: "CAP",
        name: "company_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={data.tableData[data.rowIndex].company_info.zip_code}
                />
        }
    },
    {
        label: "Stato",
        name: "status",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value === "enabled" ? "Attivo" : "Disattivo"}
                />
        }
    },
    {
        label: "Scadenza",
        name: "expiry_date",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={dateType}
                    value={data.tableData[data.rowIndex].status === "enabled" ? value : ""}
                />
        }
    },
    {
        label: "Azione",
        name: "status",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => {
                const status: string = value
                const requestedStatus: string = data.tableData[data.rowIndex].requested_status
                return <div style={{display: "flex", justifyContent: "center"}}>
                    {
                        requestedStatus === "activation" ?
                        <Button type="primary" style={{backgroundColor: "orange", border: "None"}}>
                            Attivazione in corso
                        </Button> :
                        requestedStatus === "deactivation" ?
                        <Button type="primary" style={{backgroundColor: "orange", border: "None"}}>
                            Disattivazione in corso
                        </Button> :
                        status === "disabled" ?
                        <Popconfirm
                            title="Sei sicuro?"
                            onConfirm={() => {manageSubscription(data.tableData[data.rowIndex].id, "activation")}}
                            onCancel={() => {}}
                            okText="Si"
                            cancelText="No"
                        >
                            <Button type="primary" style={{backgroundColor: "green", border: "None"}}>
                                Richiedi attivazione
                            </Button> 
                        </Popconfirm> :
                        <Popconfirm
                            title="Sei sicuro?"
                            onConfirm={() => {manageSubscription(data.tableData[data.rowIndex].id, "deactivation")}}
                            onCancel={() => {}}
                            okText="Si"
                            cancelText="No"
                        >
                            <Button type="primary" style={{backgroundColor: "red", border: "None"}}>
                                Richiedi disattivazione
                            </Button>
                        </Popconfirm>
                    }
                </div>
            }

        }
    }
]