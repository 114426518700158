import './Navbar.css'
import {adminMenuItems} from './adminMenuItems'
import {installerMenuItems} from './installerMenuItems'
import {customerMenuItems} from './customerMenuItems'
import React, {useEffect, useState} from "react";
import {GetUserProfile} from '../../api/services/userService';
import {MenuType} from '../types/navbar/types';
import {useHistory} from "react-router-dom";
import {
	Collapse,
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	useMediaQuery,
	useTheme
} from "@material-ui/core";
import {ExpandMore, ExpandLess} from "@material-ui/icons";
import {useStyles} from "../../constants/muiStyles";

type NavbarProps = {
  	SetNavbarIsCollapsed: (collapsed: boolean) => void,
	SetRole: (role: string) => void,
  	navbar_collapsed: boolean,
	panel_open: boolean,
	selected: string,
	SetItem: (s: string) => void,
	SetPanelIsOpen: (o: boolean) => void
}

const Navbar: React.FC<NavbarProps> = ({
	SetNavbarIsCollapsed,
	SetRole,
	navbar_collapsed,
	panel_open,
	selected,
	SetItem,
	SetPanelIsOpen
}) => {
	const [menuItem, setMenuItem] = useState<MenuType[]>([])
	const history = useHistory();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
	const classes = useStyles();

	useEffect(() => {
        GetUserProfile().then(res => {
			if(res && res.user && res.user.roles && res.user.roles.length > 0){
				const role: string = res["user"]["roles"][0]
				if(role.includes("installer")){
					SetRole("installer")
					setMenuItem(installerMenuItems)
				}else{
					if(role.includes("admin")){
						SetRole("admin")
						setMenuItem(adminMenuItems)
					}else{
						SetRole("viewer")
						setMenuItem(customerMenuItems)
					}
				}
			}
		})
    }, [])

		return (
			<Drawer
				onClose={() => SetNavbarIsCollapsed(true)}
				open={isMobile ? !navbar_collapsed : true}
				variant={!isMobile ? "persistent" : undefined}
			>
				<List key={"main_list"} className={classes.drawer}>
					{
						menuItem.map((menuItem: MenuType)=>{
							return(
								<>
								<ListItem
									button
									key={menuItem.key}
									onClick={() =>{
										if (menuItem?.link) {
											history.push(menuItem.link);
											SetNavbarIsCollapsed(true);
										}
										if (menuItem.elements.length > 0) {
											SetPanelIsOpen(!panel_open);
										} else {
											SetItem(menuItem.key);
										}
									}}
									selected={selected === menuItem.key}
								>
									<ListItemIcon key={`${menuItem.key}_icon`}>
										{menuItem.icon}
									</ListItemIcon>
									<ListItemText key={`${menuItem.key}_label`} primary={menuItem.label}/>
									{menuItem.elements.length > 0 ? panel_open ? <ExpandLess /> : <ExpandMore /> : null}
								</ListItem>
								{
									menuItem.elements.length > 0 ?
										<Collapse
											key={`${menuItem.key}_collapse`}
											in={panel_open}
											timeout="auto"
											unmountOnExit
										>
											<List component="div" disablePadding key={`secondary_list`}>
												{
													menuItem.elements.map(item =>
														<ListItem
															button
															key={item.key}
															selected={selected === item.key}
															onClick={() => {
																SetItem(item.key);
																if(item?.link) {
																	history.push(item.link)
																}}
															}
														>
															<ListItemIcon key={`${item.key}_icon`}>
																{item.icon}
															</ListItemIcon>
															<ListItemText
																key={`${item.key}_iconosaod`}
																primary={item.label}
															/>
														</ListItem>
													)
												}
											</List>
										</Collapse>
										:
									null
								}
								</>
							)
						})
					}
				</List>
			</Drawer>
		)
}

export default Navbar;
