import {LoginRequest, ResetPasswordRequest, SetNewPasswordRequest} from "../requests/loginService"
import {methodPost, verticalFetch} from "./httpRequests"
import {LoginUrl, LogoutUrl, ResetPasswordUrl, SetNewPasswordUrl} from "../constants"

export const login = (loginRequest: LoginRequest) => {
    return verticalFetch(methodPost, LoginUrl, JSON.stringify(loginRequest)).then(data => {return data});
}

export const resetPassword = (resetPasswordRequest: ResetPasswordRequest) => {
    return verticalFetch(methodPost, ResetPasswordUrl, JSON.stringify(resetPasswordRequest)).then(data => {return data});
}

export const logout = () => {
    return verticalFetch(methodPost, LogoutUrl);
}

export const recoverPassword = (req: SetNewPasswordRequest) => {
    return verticalFetch(methodPost, SetNewPasswordUrl, JSON.stringify(req))
}