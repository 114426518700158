export const signalConfigurationInput = [
    {
        title: "Temperatura alimentatore",
        name: "temp",
        placeholder: "Offset",
        type: "number",
        backend_name: "temp_offset"
    },
    {
        title: "Corrente assorbita alimentatore 80 Vdc",
        name: "C80",
        placeholder: "N° avvolgimenti",
        type: "number",
        backend_name: "c80_coils",
        rules: [
            ({ getFieldValue }: any) => ({
                validator(value: any) {
                const n_coil = Number(getFieldValue('C80_input') || 0) 
                if (!value || n_coil >= 0) {
                    return Promise.resolve();
                }
                return Promise.reject('Il numero degli avvolgimenti deve essere maggiore di zero.');
                },
            })
        ]
    },
    {
        title: "Corrente assorbita alimentatore 5 Vdc",
        name: "C5",
        placeholder: "N° avvolgimenti",
        type: "number",
        backend_name: "c5_coils",
        rules: [
            ({ getFieldValue }: any) => ({
                validator(value: any) {
                const n_coil = Number(getFieldValue('C5_input') || 0) 
                console.log(value)
                if (!value || n_coil >= 0) {
                    return Promise.resolve();
                }
                return Promise.reject('Il numero degli avvolgimenti deve essere maggiore di zero.');
                },
            })
        ]
    },
    {
        title: "Tensione alimentazione motori 80 Vdc",
        name: "V80",
        placeholder: "Offset",
        backend_name: "v80_offset",
        type: "number"
    },
    {
        title: "Tensione alimentazione schede 5 Vdc",
        name: "V5",
        placeholder: "Offset",
        backend_name: "v5_offset",
        type: "number"
    }
]