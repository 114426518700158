import './charts/lineChart/LineChart.css'
import '../../../App.css'
import { ChartObject } from '../../../api/requests/timeSeries';
import React, {useEffect, useState} from 'react';
import { Col, Form, Input, message, Modal, Row } from 'antd';
import {Aggregation} from "../../../actions/lineChart";
import {avg} from "../../../actions/constants"
import TitleWithBack from "../../../containers/TitleWithBack";
import StairSummary from '../../../containers/pages/installationPage/summaryCards/StairSummary';
import StepSummary from '../../../containers/pages/installationPage/summaryCards/StepSummary';
import AlarmSummary from '../../../containers/pages/installationPage/summaryCards/AlarmSummary';
import {Zoom} from "../../types/timeseries/types";
import {Stair, Step} from "../../../api/requests/stairsService";
import {getStair, GetSteps, maintenanceStair} from "../../../api/services/stairsService";
import ButtonConfItem from '../../ButtonConfItem';
import { dashboardUrl } from '../../../api/constants';

type TimeSeriesProps = {
    role: string,
    stair_id: string,
    setPanelTemperatureAggregations: (aggregations: string[]) => void,
	setTempAggregations: (aggregations: string[]) => void,
    setPowerAggregations: (aggregations: string[]) => void,
    setDataStartDate: (reset: boolean, dev: string, a: Aggregation[], startDate: Date, period: string, interval: number) => void,
    setPeriod: (reset: boolean, dev: string, a: Aggregation[], period: string, interval: number) => void,
    startUpdateChartData: (dev: string, a: Aggregation[], period: string, interval: number) => void,
    clearDataInterval: (reset: boolean) => void,
    setStartHour: (h: number) => void,
    startHour: number,
    panel_temperature_data: ChartObject,
    panel_temperature_zoom: Zoom,
    panel_temperature_aggregations: string[],
    voltage80_data: ChartObject,
    voltage80_zoom: Zoom,
    voltage80_aggregations: string[],
    power80_data: ChartObject,
    power80_zoom: Zoom,
    power80_aggregations: string[],
    voltage5_data: ChartObject,
    voltage5_zoom: Zoom,
    voltage5_aggregations: string[],
    power5_data: ChartObject,
    power5_zoom: Zoom,
    power5_aggregations: string[],
}

const InstallationPage: React.FC<TimeSeriesProps> = ({
    role,
    stair_id,
    setPanelTemperatureAggregations,
    setTempAggregations,
    startUpdateChartData,
    setPowerAggregations,
    setDataStartDate,
    setPeriod,
    clearDataInterval,
    setStartHour,
    startHour,
    panel_temperature_data,
    panel_temperature_zoom,
    panel_temperature_aggregations,
    voltage80_data,
    voltage80_zoom,
    voltage80_aggregations,
    power80_data,
    power80_zoom,
    power80_aggregations,
    voltage5_data,
    voltage5_zoom,
    voltage5_aggregations,
    power5_data,
    power5_zoom,
    power5_aggregations
}) => {
    const [visible, setVisible] = useState<boolean>(false)
    const [selectedStair, setSelectedStair] = useState<Stair | null>(null);
    const [steps, setSteps] = useState<Step[]>([])
    const [maintenanceDone, setMaintenanceDone] = useState<boolean>(true);
    useEffect(() => {
        startUpdateChartData(stair_id || "", [avg], "5m", 300000)
        getStair({id: stair_id}).then(
            res => {
                if (res && res.stair) {
                    setSelectedStair(res.stair);
                    setMaintenanceDone(!res.stair.maintenance_notified);
                    GetSteps(stair_id).then((res) => {
                        if(res && res.steps){
                            setSteps(res.steps)
                        }
                    })
                }
            }
        )
        
        return () => clearDataInterval(true);
    }, []);

    const [note] = Form.useForm()

    if(selectedStair === null || steps === null){
        return null;
    }

    const submit = () => {
        note.validateFields().then(
            values => 
                maintenanceStair(selectedStair.id, values.note).then((res) => {
                    if(res && !res.err){
                        message.success("Manutenzione effettuata con successo.");
                        setMaintenanceDone(!maintenanceDone);
                        setVisible(false)
                    }else{
                        message.error(res.err?.message || "Errore durante il caricamento delle note.")
                    }
                })
        )
    }
    
    document.body.style.background = "#f5f5f5c7"

    return (
        <>
            <TitleWithBack title={"Dati Real Time"} key={"real_time_data"} />
            <div>
                <Row className="cards">
                    <Col xs={24} xl={7} className="card-responsive"> <StairSummary selected_stair={selectedStair}/> </Col>
                    <Col xs={24} xl={7} className="card-responsive"> <StepSummary selected_stair={selectedStair} /> </Col>
                    <Col xs={24} xl={7} className="card-responsive"> <AlarmSummary stair={selectedStair}/> </Col>
                </Row>
            </div>
            {
                !maintenanceDone && role === "installer" ? 
                <div className="maintenance-container">
                    <ButtonConfItem buttonType={'primary'} buttonLabel={"Manutenzione effettuata"} buttonOnCancel={() => {}} buttonOnConfirm={() => setVisible(true)} />
                </div> : 
                null
            }
            <Modal title="Manutenzione installazione" visible={visible} onOk={submit} onCancel={() => setVisible(false)}>
                <Form form={note}>
                    <Form.Item name={"note"} label="Note">
                        <Input.TextArea name="note" />
                    </Form.Item>
                </Form>
            </Modal>
            {
                /*
                Tabella con lista scalini, commentata per demo
                <StepList steps={steps} />
                */
            }
            <iframe
                id={"grafana_iframe"}
                className='responsive-iframe'
                src={dashboardUrl(stair_id)}
                style={{height: "1700px"}}
            />
        </>
    )
}

export default InstallationPage;