export const idParam = ":id"
export const nameParam = ":name"

export const resetPasswordPageUrlResult = `/reset-result`;
export const recoverPageUrlResult = `/recover-result` ;
export const mainUrl = `/`;
export const mainPageUrl = `/profile`;
export const loginPageUrl = `/login`;
export const resetPasswordPageUrl = `/recover`;
export const recoverPageUrl = `/auth/recover/end`;
export const createInstallerPageUrl = `/installers/new`;
export const createCustomerPageUrl = `/customers/new`;
export const customersListPageUrl = `/customers`;
export const installersListPageUrl = `/installers`;
export const updateUserPageUrl = (id: string) => `/users/update/${id}`;
export const createSubscriptionPageUrl = `/subscriptions/new`;
export const installationPageUrl = (id: string) => `/installations/${id}`;
export const subscriptionsListToInstallationPageUrl = `/data/subscriptions`;
export const installationListToLineChartUrl = (id: string) => `/data/subscriptions/${id}/installations`;
export const subscriptionsListToUpdateUrl = `/subscriptions/update`;
export const updateSubscriptionUrl = (id: string) => `/subscriptions/update/${id}`;
export const subscriptionsListToDataHistoryPageUrl = `/history/subscriptions`;
export const installationListToDataHistoryUrl = (id: string) => `/history/subscriptions/${id}/installations`;
export const dataHistoryPanelUrl = (id: string) => `/history/subscriptions/stairs/${id}`;
export const createInstallationUrl = `/new_installation`;
export const installersAssociationsUrl = `/subscriptions/installers`;
export const installationsListToUpdateUrl = `/subscriptions/installations`;
export const updateInstallationUrl = (id: string) => `/installations/${id}/update`;
export const alarmsPageUrl = `/alarms`;
export const configurationAlarmsPageUrl = (id: string) => `/${id}/alarms/configuration`;
export const updateAlarmPageUrl = (id: string) => `/alarms/${id}/update`;
export const deviceConnectionConfigurationUrl = (id: string) => `/installations/${id}/configuration`;
export const successUrl = `/success`;
export const userListAdminUrl = `/users`;
export const configurationSignalsPageUrl = (id: string) => `/${id}/signals/configuration`;
export const configurationListUrl = (id: string) => `/configurations/${id}`;
export const stepUrl = (id: string, step_id: string) => `/stair/${id}/step/${step_id}`;
export const adminPanelIconsUrl = `/adminpanel`;
export const installerPanelIconsUrl = `/installerpanel`;

export const routes = {
    mainPage: mainUrl,
    adminPanel: adminPanelIconsUrl,
    configuratorPanel: installerPanelIconsUrl,
    login:  loginPageUrl,
    recoverStart: resetPasswordPageUrl,
    recoverEnd: recoverPageUrl,
    resetResult: resetPasswordPageUrlResult,
    recoverResult: recoverPageUrlResult,
    profile: mainPageUrl,
    addInstaller: createInstallerPageUrl,
    addCustomer: createCustomerPageUrl,
    installationPage: installationPageUrl(idParam),
    customersList: customersListPageUrl,
    installersList: installersListPageUrl,
    updateUser: updateUserPageUrl(idParam),
    installationsListToLineChart: installationListToLineChartUrl(idParam),
    installationsListToDataHistory: installationListToDataHistoryUrl(idParam),
    addSubscription: createSubscriptionPageUrl,
    subscriptionsListToUpdate: subscriptionsListToUpdateUrl,
    updateSubscription: updateSubscriptionUrl(idParam),
    subscriptionsListToDataHistory: subscriptionsListToDataHistoryPageUrl,
    dataHistoryPanel: dataHistoryPanelUrl(idParam),
    subscriptionsListToInstallationPage: subscriptionsListToInstallationPageUrl,
    installersAssociations: installersAssociationsUrl,
    addInstallation: createInstallationUrl,
    installationsListToUpdate: installationsListToUpdateUrl,
    updateInstallation: updateInstallationUrl(idParam),
    alarms: alarmsPageUrl,
    alarmsConfiguration: configurationAlarmsPageUrl(idParam),
    alarmsUpdate: updateAlarmPageUrl(idParam),
    deviceConnectionConfiguration: deviceConnectionConfigurationUrl(idParam),
    success: successUrl,
    userListAdmin: userListAdminUrl,
    configurationSignals: configurationSignalsPageUrl(idParam),
    configurationList: configurationListUrl(idParam),
    stepUrl: stepUrl(idParam, nameParam)
}

export const roles = {
  	admin: "admin",
  	installer: "installer",
  	customer: "customer"
}

export const tabList = [
    {
      key: 'avg',
      tab: 'Media',
    }
];

export const lineColors = {
    avg: "#8884D8",           //viola
    min: "#008000",           //verde
    max: "#FF6347",           //rosso
    sum: "#096dd9"            //celeste
}

export const otherLinesColors = {
  avg: "#f0a91a",             //giallo
  min: "#096dd9",             //celeste
  max: "#eb2f96",             //rosa
  sum: "#008000"              //verde
}

export const checkbox = [
  	{label: "avg", value: "avg"},
  	{label: "max", value: "max"},
  	{label: "min", value: "min"},
    {label: "sum", value: "sum"}
]

export const confirmPasswordRules = [
	({ getFieldValue }: any) => ({
		validator(value: any) {
		if (!value || getFieldValue('password') === getFieldValue('confirm_password')) {
			return Promise.resolve();
		}
		return Promise.reject('Le due password non corrispondono!');
		},
	})
]

export const passwordRules = [
	({ getFieldValue }: any) => ({
		validator(value: any) {
		if (!value || (/.*\d.*/.test(getFieldValue('password')) && /.*[A-Z].*/.test(getFieldValue('password')) && /.*[a-z].*/.test(getFieldValue('password')) && /.*[~!].*/.test(getFieldValue('password')))) {
			return Promise.resolve();
		}
		return Promise.reject('La password deve contenere almeno un carattere minuscolo, uno maiuscolo, un numero e un carattere speciale.');
		},
	})
]

export const emailRules = (v: string) => [
  {
      required: true,
      message: `Inserisci ${v}`
  },
  ({ getFieldValue }: any) => ({
      validator(value: any) {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!value || re.test(getFieldValue(v)) ) {
              return Promise.resolve();
          }
          return Promise.reject('L\'email non è valida.');
      },
  })
]