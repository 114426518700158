import {MenuType, SubMenuType} from "../types/navbar/types";
import {SettingOutlined} from "@ant-design/icons";
import {
    alarmsPageUrl,
    createCustomerPageUrl,
    createInstallationUrl,
    createSubscriptionPageUrl,
    customersListPageUrl,
    installationsListToUpdateUrl,
    installerPanelIconsUrl,
    subscriptionsListToDataHistoryPageUrl,
    subscriptionsListToInstallationPageUrl,
    subscriptionsListToUpdateUrl
} from "../pages/costants";
import './Navbar.css'
import {ConfiguratorTitle} from "../pages/title";

import dataRealTime from "../pages/homePages/img/graph_white.png";
import dataHistory from "../pages/homePages/img/cloud-download_white.png";
import user from "../pages/homePages/img/user_white.png";
import change from "../pages/homePages/img/change_white.png";
import device from "../pages/homePages/img/device_white.png";
import alert from "../pages/homePages/img/alert_white.png";

const installer: SubMenuType[] =[
    {
        key: "create_customer",
        label: "Crea Utente",
        link: createCustomerPageUrl,
        icon: <img alt={"--"} src={user} className={"menu-icon"}/>,
        elements: []
    },
    {
        key: "update_customer",
        label: "Modifica Utente",
        link: customersListPageUrl,
        icon: <img alt={"--"} src={user} className={"menu-icon"}/>,
        elements: []
    },
    {
        key: "create_subscription",
        label: "Crea Abbonamento",
        icon: <img alt={"--"} src={change} className={"menu-icon"}/>,
        link: createSubscriptionPageUrl,
        elements: []
    },
    {
        key: "update_subscription",
        label: "Modifica Abbonamento",
        link: subscriptionsListToUpdateUrl,
        icon: <img alt={"--"} src={change} className={"menu-icon"}/>,
        elements: []
    },
    {
        key: "create_installation",
        label: "Crea Installazione",
        link: createInstallationUrl,
        icon: <img alt={"--"} src={device} className={"menu-icon"}/>,
        elements: []
    },
    {
        key: "update_installation",
        label: "Modifica Installazione",
        link: installationsListToUpdateUrl,
        icon: <img alt={"--"} src={device} className={"menu-icon"}/>,
        elements: []
    }
]

export const installerMenuItems: MenuType[] = [
    {
        key: "customer", 
        label: "Dati Real Time",
        icon: <img alt={"--"} src={dataRealTime} className={"menu-icon"}/>,
        elements: [],
        link: subscriptionsListToInstallationPageUrl
    },
    {   
        key: "history", 
        label: "Storico Dati",
        icon: <img alt={"--"} src={dataHistory} className={"menu-icon"}/>,
        elements: [],
        link: subscriptionsListToDataHistoryPageUrl
    },
    {   
        key: "alarms", 
        label: "Allarmi",
        link: alarmsPageUrl,
        icon: <img alt={"--"} src={alert} className={"menu-icon"}/>,
        elements: []
    },
    {
        key: "installer",
        label: ConfiguratorTitle,
        icon: <SettingOutlined />,
        elements: installer,
        link: installerPanelIconsUrl
    }
];