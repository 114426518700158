import {ResponsiveTableCell, ResponsiveTableColumnType} from "../../responsiveTable"
import "../../../App.css"
import "../../Table.css"
import {stringType, tagType, thresholdType} from "../../../utilities/utilities"
import { nameSwitch } from "../../types/form/types"

export const alarmsConfigurationListColumns: ResponsiveTableColumnType[] = [
    {
        label: "Nome",
        name: "name",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => 
                <ResponsiveTableCell 
                    type={stringType} 
                    value={nameSwitch(value)}
                />
        }
    },
    {
        label: "Livello",
        name: "level",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value) => <ResponsiveTableCell type={stringType} value={levelSwitch(value)}/>
        }
    },
    {
        label: "Stato",
        name: "status",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value) => <ResponsiveTableCell type={tagType} color={value === 0 ? "red" : "green"} value={value === 0 ? "Disabilitato" : "Abilitato"}/>
        }
    },
    {
        label: "Soglia Inferiore",
        name: "lower_threshold",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value) => <ResponsiveTableCell type={thresholdType} value={value}/>
        }
    },
    {
        label: "Soglia Superiore",
        name: "upper_threshold",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value) => <ResponsiveTableCell type={thresholdType} value={value}/>
        }
    },
    {
        label: "Delay Apertura",
        name: "delay_open",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value) => <ResponsiveTableCell type={stringType} value={value}/>
        }
    },
    {
        label: "Delay Chiusura",
        name: "delay_close",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value) => <ResponsiveTableCell type={stringType} value={value}/>
        }
    }
]

const levelSwitch = (level: number) => {
    let result: string = ""
    switch(level){
        case 0:
            result = "info"
            break
        case 1:
            result = "warning"
            break
        case 2:
            result = "error"
            break
        case 3:
            result = "fatal"
            break
    }
    return result
}