import { installersListColumns } from './inputs';
import React, { useCallback, useEffect, useState } from 'react';
import { GetInstallers } from '../../../api/services/userService';
import { User } from '../../../api/requests/userService';
import '../../../App.css'
import CustomTitle from "../../CustomTitle";
import { useHistory } from 'react-router';
import { updateUserPageUrl } from '../costants';
import ResponsiveTable from '../../responsiveTable';
import ServerSideTable from '../../ServerSideTable';

type InstallerTableProps = {
    logged: boolean,
}

const InstallersListTable: React.FC<InstallerTableProps> = ({}) => {
    
    document.body.style.background = "#f5f5f5c7"
    
    const [tableData, setTableData] = useState<Object[]>([]);
    const [totalDataLength, setTotalDataLength] = useState<number>(0);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [currentSort, setCurrentSort] = useState<string>("name");
    const [currentSearch, setCurrentSearch] = useState<string>('');

    const getInstallers = useCallback((page: number, sort: string, search: string) => {
        GetInstallers(page.toString(), sort, search).then(
            res => {
                if(res && res.installers)  {
                    setTableData(res.installers)
                    setTotalDataLength(res?.total_count ?? 0 );
                } else {
                    setTableData([]);
                    setTotalDataLength(0);
                }
            }
        )
    }, [currentPage, currentSort]);

    useEffect(() => {
        getInstallers(currentPage, currentSort, currentSearch);
    }, [])

    const changePage = (page: number) => {
        setCurrentPage(page);
        getInstallers(page, currentSort, currentSearch);
    }

    const changeSort = (sort: string) => {
        setCurrentSort(sort);
        getInstallers(currentPage, sort, currentSearch);
    }

    const changeSearch = (search: string) => {
        setCurrentSearch(search);
        setCurrentPage(0);
        getInstallers(0, currentSort, search);
    }

    const history = useHistory();
    const linkFunction: (value: string) => void = (value: string) => { 
        history.push(updateUserPageUrl(value))
    }

    return(
        <>
            <CustomTitle title={"Lista Installatori"} />
            <ServerSideTable
                columns={installersListColumns(linkFunction)}
                data={tableData}
                totalDataLength={totalDataLength}
                changeSort={changeSort}
                changePage={changePage}
                changeSearch={changeSearch}
            />
        </>
    )
}

export default InstallersListTable;