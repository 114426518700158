import { connect } from 'react-redux'
import LineChart from '../../../../../components/pages/installation/charts/lineChart/LineChart'
import { Store } from '../../../../../reducers'
import { ZoomUpdates } from '../../../../../components/types/timeseries/types';
import {Dispatch} from "react";
import {Action} from "../../../../../actions";
import {SET_MOTOR_SPEED_ZOOM, SET_VOLTAGE80_ZOOM, SET_POWER80_ZOOM, SET_VOLTAGE5_ZOOM, SET_POWER5_ZOOM, SET_MOTOR_OVERCURRENT_ZOOM, SET_PANEL_TEMPERATURE_ZOOM} from "../../../../../actions/lineChart";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        SetZoom: (type: SET_PANEL_TEMPERATURE_ZOOM | SET_VOLTAGE80_ZOOM | SET_POWER80_ZOOM | SET_MOTOR_OVERCURRENT_ZOOM | SET_VOLTAGE5_ZOOM | SET_POWER5_ZOOM | SET_MOTOR_SPEED_ZOOM ,ref: ZoomUpdates) => dispatch({type:type, zoomUpd:ref})
    }
}

const mapStateToProps = (state: Store) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LineChart)