import {Action} from "../actions"

export type GlobalState = {
    logged: boolean,
    role: string,
    navbar_collapsed: boolean
}

export const initialState: GlobalState = {
    logged: false,
    role: "",
    navbar_collapsed: true
}

const infoReducer = (state: GlobalState = initialState, action: Action) => {
    switch(action.type){
        case "SET_LOGGED":
            if(!action.logged) {
                return {
                    ...initialState
                }
            } else {
                return {
                    ...state,
                    logged: action.logged
                }
            }
        case "SET_ROLE":
            return {
                ...state,
                role: action.role
            }
        case 'SET_NAVBAR_IS_COLLAPSED':
            return {
                ...state,
                navbar_collapsed: action.collapsed
            }
        default:
            return state
    }
}

export default infoReducer