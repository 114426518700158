import 'antd/dist/antd.css';
import { Select } from 'antd';
import React from "react";

type SelectProps = {
    options: string[],
    id: string,
    onChange: (id: string, event: string) => void,
    placeHolder?: string
}

const { Option } = Select;

const CustomSelect: React.FC<SelectProps> = ({options, id, onChange, placeHolder}) => {
    return(
        <Select
            placeholder={placeHolder}
            onSelect={event => onChange(id, event?.toString() ?? "")}
        >
            {options.map((opt: string, index: number)=>{ 
                return <Option key={index} value={opt}>{opt}</Option>
            })}
        </Select> 
    )
}

export default CustomSelect;