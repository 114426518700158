import './charts/lineChart/LineChart.css'
import '../../../App.css'
import React, {useEffect, useState} from 'react';
import { Col, Row } from 'antd';
import TitleWithBack from "../../../containers/TitleWithBack";
import StairSummary from '../../../containers/pages/installationPage/summaryCards/StairSummary';
import StepSummary from '../../../containers/pages/installationPage/summaryCards/StepSummary';
import AlarmSummary from '../../../containers/pages/installationPage/summaryCards/AlarmSummary';
import {Stair} from "../../../api/requests/stairsService";
import {getStair} from "../../../api/services/stairsService";

type TimeSeriesUserProps = {
    stair_id: string,
}

const InstallationPageUser: React.FC<TimeSeriesUserProps> = ({
    stair_id
}) => {
    const [selectedStair, setSelectedStair] = useState<Stair | null>(null)
    useEffect(() => {
        getStair({id: stair_id}).then(
            res => {
                if (res && res.stair) {
                    setSelectedStair(res.stair);
                }
            }
        )
    }, []);

    if(selectedStair === null){
        return null
    }

    document.body.style.background = "#f5f5f5c7"

    return (
        <>
            <TitleWithBack title={"Dati Real Time"} key={"real_time_data"} />
            <Row className="carduser">
                <Col xs={24} xl={7}> <StairSummary selected_stair={selectedStair}/> </Col>
                <Col xs={24} xl={7}> <StepSummary selected_stair={selectedStair} /> </Col>
                <Col xs={24} xl={7}> <AlarmSummary stair={selectedStair}/> </Col>
            </Row>
        </>
    )
}

export default InstallationPageUser;