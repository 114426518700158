import '../../../../../App.css'
import React, { useCallback, useEffect, useState } from 'react';
import { Step } from '../../../../../api/requests/stairsService';
import { Descriptions, Form, Select } from 'antd';
import { GetStep } from '../../../../../api/services/stairsService';
import { localeDateFormatter } from '../../../../../utilities/utilities';
import { LineChartOutlined } from "@ant-design/icons";
import Chart from '../../Chart';
import { ChartObject } from '../../../../../api/requests/timeSeries';
import { Zoom } from '../../../../types/timeseries/types';
import { Aggregation } from '../../../../../actions/lineChart';
import LineChart from '../../../../../containers/pages/installationPage/charts/lineChart/LineChart';
import { avg, max, min } from '../../../../../actions/constants';
import TitleWithBack from "../../../../../containers/TitleWithBack";
import CloseCircleTwoTone from '@ant-design/icons/lib/icons/CloseCircleTwoTone';
import CheckCircleTwoTone from '@ant-design/icons/lib/icons/CheckCircleTwoTone';

const { Option } = Select;

type TimeSeriesUserProps = {
    setDataStartDate: (reset: boolean, dev: string, a: Aggregation[], startDate: Date, period: string, interval: number, step: string) => void
    setPeriod: (reset: boolean, dev: string, a: Aggregation[], period: string, interval: number, step: string) => void
    startUpdateChartData: (dev: string, a: Aggregation[], period: string, interval: number, step: string) => void
    clearDataInterval: (reset: boolean) => void
    setMotorSpeedAggregations: (aggregations: string[]) => void
    setMotorOvercurrentAggregations: (aggregations: string[]) => void
    motor_speed_data: ChartObject
    motor_speed_zoom: Zoom
    motor_speed_aggregations: string[]
    motor_overcurrent_data: ChartObject
    motor_overcurrent_zoom: Zoom
    motor_overcurrent_aggregations: string[]
    stair_id: string
    step_id: string
}

const StepItem: React.FC<TimeSeriesUserProps> = ({setDataStartDate, setPeriod, startUpdateChartData, clearDataInterval, setMotorSpeedAggregations, setMotorOvercurrentAggregations, motor_speed_data, motor_speed_zoom, motor_speed_aggregations, motor_overcurrent_data, motor_overcurrent_zoom, motor_overcurrent_aggregations, stair_id, step_id }) => {

    const [width, setWidth] = useState<number>(window.innerWidth)
    const [height, setHeight] = useState<number>(window.innerHeight)
	function handleWindowSizeChange() {
	    setWidth(window.innerWidth)
        setHeight(window.innerHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, [])

    const [selectedStep, setSelectedStep] = useState<Step | null>(null)

    const updateStep = useCallback(() => {
        GetStep(stair_id, step_id).then((res) => {
            if(res && res.step){
                setSelectedStep(res.step)
            }
        })
    }, [])

    useEffect(() => {
		startUpdateChartData(stair_id || "", [avg, min, max], "5m", 300000, step_id)
        return clearDataInterval(false);
    }, []);

    useEffect(() => {
        updateStep()
        const intervalId = setInterval(updateStep, 30000)
        return () => clearInterval(intervalId)
    }, [updateStep]);

    const shortPeriod = [
        {value: "300000-5m", label: "5 minuti"},
        {value: "1800000-30m", label: "30 minuti"},
        {value: "3600000-1h", label: "1 ora"}
    ]
    const longPeriod = [
        {value: "3600000-1h", label: "1 ora"},
        {value: "10800000-5h", label: "5 ore"},
        {value: "36000000-10h", label: "10 ore"}
    ]
    const date = [
        {value: 2, label: "Ultime 2 ore", period: shortPeriod}, 
        {value: 4, label: "Ultime 4 ore", period: shortPeriod}, 
        {value: 24, label: "Ultimo giorno", period: shortPeriod},
        {value: 48, label: "Ultimi 2 giorni", period: shortPeriod},
        {value: 168, label: "Ultima settimana", period: longPeriod},
        {value: 720, label: "Ultimo mese", period: longPeriod},
        {value: 1440, label: "Ultimi 2 mesi", period: longPeriod},
        {value: 2160, label: "Ultimi 3 mesi", period: longPeriod}
    ]

    const [form] = Form.useForm()
    const [currentDate, setCurrentDate] = useState<any>(date[2])

    const setChange = useCallback((type: string) => {
        form.validateFields().then(
            values => {      
                let startDate = new Date();
                startDate.setHours(startDate.getHours() - values.date);
                let period: string[] = (values.period).split("-");
                if(type === "period"){
                    setPeriod(false, stair_id || "", [avg, min, max], period[1], Number(period[0]), step_id);
                }
                if(type === "date"){
                    const filter_date: any[] = date.filter(item => String(item.value) === String(values.date))
                    setCurrentDate(filter_date[0])
                    if(filter_date[0].period !== period){
                        period = (filter_date[0].period[0].value).split("-");
                    }
                    form.setFieldsValue({period: filter_date[0].period[0].value})
                    setDataStartDate(false, stair_id || "", [avg, min, max], startDate, period[1], Number(period[0]), step_id);
                }
            }
        )
	}, []);

    const stepDescription = useCallback(() => {
        return <Descriptions style={{marginTop: "2%"}} title="Scalino" bordered>
            <Descriptions.Item label="Posizione" span={1.5}>
                {String(selectedStep?.position)}
            </Descriptions.Item>
            <Descriptions.Item label="Data registrazione" span={1.5}>
                {String(localeDateFormatter(selectedStep?.registered_at || ""))}
            </Descriptions.Item>
            <Descriptions.Item label="Presenza carico" span={1.5}>
                {
                    Number(selectedStep?.last_data.load) === 0 ? 
                    <CloseCircleTwoTone twoToneColor="#5bc3d0" style={{ fontSize: '200%'}} /> :
                    <CheckCircleTwoTone twoToneColor="#5bc3d0" style={{ fontSize: '200%'}} />
                }
            </Descriptions.Item>
            <Descriptions.Item label="ChipID" span={1.5}>
                {String(selectedStep?.id)}  
            </Descriptions.Item>
            <Descriptions.Item label="Versione firmware" span={1.5}>
                {String(selectedStep?.fw_version)}
            </Descriptions.Item>
            <Descriptions.Item label="Stato" span={1.5}>
                {
                    Number(selectedStep?.last_data?.sts) === 1 ?
                    "aperto in basso" : 
                    Number(selectedStep?.last_data?.sts) === 2 ?
                    "aperto in alto" :
                    "chiuso"
                }
            </Descriptions.Item>
            {
                selectedStep?.last_data?.err ?
                <Descriptions.Item label="Errore" span={3}>
                    {String(selectedStep.last_data.err)}
                </Descriptions.Item> :
                null
            }
        </Descriptions>
    }, [selectedStep])

    document.body.style.background = "#f5f5f5c7"

    return (
        <>
            <TitleWithBack title={"Dati Scalino"} key={"title-step-data"} />
            {stepDescription()}
            <Form layout={width > 575? "vertical" : "vertical"} className={ width > 575 ? "my-select-container" : undefined} key={1} form={form} name="installation_panel">
                <Form.Item className={width > 575 ? "my-select" : undefined} label={"Dati"} name={"date"} initialValue={24}>
                    <Select size="large" placeholder={"..."} onChange={() => {setChange("date")}}>
                        {
                            date.map((el, index) => {
                                return <Option value={el.value} key={index}> {el.label} </Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item className={width > 575 ? "my-select" : undefined} label={"Intervallo aggregazioni"} name={"period"} initialValue={currentDate.period[0].value}>
                    <Select size="large" placeholder={"..."} onChange={() => {setChange("period")}}>
                        {
                            (currentDate.period).map((el: any, index: number) => {
                                return <Option value={el.value} key={index}> {el.label} </Option>
                            })
                        }
                    </Select>   
                </Form.Item>
            </Form>
            <Chart 
                width={width} 
                title="Velocità motore (rpm)" 
                icon={<LineChartOutlined />} 
                chart={<LineChart 
                    SetAggregationView={setMotorSpeedAggregations}
                    zoomType={"SET_MOTOR_SPEED_ZOOM"}
                    data_chart={[motor_speed_data]}
                    zoom={motor_speed_zoom}
                    aggregations_view={motor_speed_aggregations}
                    width={width}
                    heigth={height}
                />} 
                datachart={motor_speed_data} 
            />
            <Chart 
                width={width} 
                title="Sovracorrente motore" 
                icon={<LineChartOutlined />} 
                chart={<LineChart 
                    SetAggregationView={setMotorOvercurrentAggregations}
                    zoomType={"SET_MOTOR_OVERCURRENT_ZOOM"}
                    data_chart={[motor_overcurrent_data]}
                    zoom={motor_overcurrent_zoom}
                    aggregations_view={motor_overcurrent_aggregations}
                    width={width}
                    heigth={height}
                />} 
                datachart={motor_overcurrent_data} 
            />
        </>
    )
}

export default StepItem;