import { connect } from 'react-redux'
import InstallerCustomerRelationsPage from '../../components/pages/tab_admin_subscription/InstallerCustomerRelationsPage'
import { Store } from '../../reducers'
import {Dispatch} from 'react'
import {Action} from '../../actions'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {

    }
}

const mapStateToProps = (state: Store) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallerCustomerRelationsPage)