import { Divider, Form, message, Radio, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { GetSignalsCalibration, SetSignalsCalibration } from "../../api/services/stairsService";
import TitleWithBack from "../../containers/TitleWithBack";
import ButtonItem from "../ButtonItem";
import FormInput from "../input/FormInput";
import { Calibration } from "../types/jobs/jobs";
import { signalConfigurationInput } from "./inputs";
import './SignalConfiguration.css'

type SignalConfigurationProps = {
    installation_id: string
}

const SignalConfiguration: React.FC<SignalConfigurationProps> = ({ installation_id }) => {
    const history = useHistory()
    const [form] = Form.useForm()
    const [calibration, setCalibration] = useState<Calibration|null>(null)
    useEffect(() => {
        GetSignalsCalibration(installation_id).then((res) => {
            if(res && !res.err){
                setCalibration(res.calibrations.signals)
            }else{
                message.error(res?.err?.message || "Operazione fallita.")
            }
       })
    }, [])

    const handleSubmit = () => {
        form.validateFields().then(
            values => {
                const c: Calibration = {
                    v5_enabled: Number(values.V5_radio),
                    v80_enabled: Number(values.V80_radio), 
                    c80_enabled: Number(values.C80_radio), 
                    c5_enabled: Number(values.C5_radio), 
                    temp_enabled: Number(values.temp_radio), 
                    v5_offset: Number(values.V5_input),  
                    v80_offset: Number(values.V80_input), 
                    c5_coils: Number(values.C5_input),  
                    c80_coils: Number(values.C80_input),  
                    temp_offset: Number(values.temp_input) 
                }
                SetSignalsCalibration(installation_id, c).then((res) => {
                    if(res && !res.err){
                        message.success("Calibrazione segnali riuscita con successo.")
                        history.push(`/configurations/${installation_id}`)
                    }else{
                        message.error(res?.err?.message || "Operazione fallita.")
                    }
                })
            }   
        )
    }

    if(!calibration){
        return (
            <>
                <TitleWithBack title={"Calibrazione Segnali"} key={"signal_configuration_alarm"}/>
                <div className="spin">
                    <Spin size="large" />
                </div>
            </>
        )
    }

    return (
        <>
            <TitleWithBack title={"Calibrazione Segnali"} key={"signal_configuration_alarm"}/>
            <div className="my-signalconfig-container my-signalconfig-container-responsive" key={"signal_configuration_div"}>
                <Form layout="vertical" key={1} form={form} name="signal_panel">
                    {
                        signalConfigurationInput.map((el, index) => 
                            <div key={`${el.name}_div`}>
                                <Divider orientation="left"> {el.title} </Divider>
                                <Form.Item rules={el.rules || undefined} name={`${el.name}_radio`} initialValue={Boolean((calibration as any)[`${el.name}_enabled`])}>
                                    <Radio.Group 
                                        key={`${el.name}_radio`} 
                                        onChange={(e) => {
                                            let tmp: Calibration = {...calibration};
                                            (tmp as any)[`${el.name}_enabled`] = e.target.value
                                            setCalibration(tmp)
                                        }}
                                    >
                                        <Radio value={true}> Abilitato </Radio>
                                        <Radio value={false}> Disabilitato </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <FormInput 
                                    value={
                                        Boolean((calibration as any)[`${el.name}_enabled`]) ?
                                        (calibration as any)[`${el.backend_name}`] : 
                                        ""
                                    }
                                    keyValue={`${el.name}_input`} 
                                    placeholder={el.placeholder} 
                                    name={`${el.name}_input`} 
                                    type={el.type} 
                                    disabled={Boolean((calibration as any)[`${el.name}_enabled`]) === true ? false : true}
                                />
                            </div>
                        )
                    }    
                </Form>
                <div className="btn-container" key={"btn_div"}>
                    <ButtonItem
                        buttonType="primary"
                        label="Invio"
                        buttonOnClick={handleSubmit}
                    />
                </div>
            </div>
        </>
    )
}

export default SignalConfiguration;