import { connect } from 'react-redux'
import LoginPanel from '../../components/pages/form_login/LoginPanel'
import { Store } from '../../reducers'
import {Dispatch} from 'react'
import {Action} from '../../actions'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        SetLogged: (logged: boolean) => dispatch({type: "SET_LOGGED", logged: logged})
    }
}

const mapStateToProps = (state: Store) => {
    return {
        logged: state.info.logged
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPanel)