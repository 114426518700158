import { connect } from 'react-redux'
import ResetPassword from '../../components/pages/resetPassword/resetPassword'
import { Store } from '../../reducers'
import {Dispatch} from 'react'
import {Action} from '../../actions'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
    }
}

const mapStateToProps = (state: Store) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)