import React, { useCallback, useEffect, useState } from "react";
import '../../navbar/Navbar.css'
import { customersListColumns } from "./input";
import '../../../App.css'
import './UsersListTable.css'
import { GetCustomers } from "../../../api/services/userService";
import CustomTitle from "../../CustomTitle";
import {useHistory} from "react-router-dom";
import { updateUserPageUrl } from "../costants";
import ServerSideTable from "../../ServerSideTable";

type UsersListTableProps = {
}

const UsersListTable: React.FC<UsersListTableProps> = () => {   

    document.body.style.background = "#f5f5f5c7"
    
    const [tableData, setTableData] = useState<Object[]>([]);
    const [totalDataLength, setTotalDataLength] = useState<number>(0);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [currentSort, setCurrentSort] = useState<string>("name");
    const [currentSearch, setCurrentSearch] = useState<string>('');

    const getCustomers = useCallback((page: number, sort: string, search: string) => {
        GetCustomers(page.toString(), sort, search).then(
            res => {
                if(res && res.customers)  {
                    setTableData(res.customers)
                    setTotalDataLength(res?.total_count ?? 0 );
                } else {
                    setTableData([]);
                    setTotalDataLength(0);
                }
            }
        )
    }, [currentPage, currentSort]);

    useEffect(() => {
        getCustomers(currentPage, currentSort, currentSearch);
    }, [])

    const changePage = (page: number) => {
        setCurrentPage(page);
        getCustomers(page, currentSort, currentSearch);
    }

    const changeSort = (sort: string) => {
        setCurrentSort(sort);
        getCustomers(currentPage, sort, currentSearch);
    }

    const changeSearch = (search: string) => {
        setCurrentSearch(search);
        setCurrentPage(0);
        getCustomers(0, currentSort, search);
    }

    const history = useHistory();
    const linkFunction: (value: string) => void = (value: string) => { 
        history.push(updateUserPageUrl(value))
    }

    return(
        <>
            <CustomTitle title={"Modifica Utente"}/>
            <ServerSideTable
                columns={customersListColumns(linkFunction)}
                data={tableData}
                totalDataLength={totalDataLength}
                changeSort={changeSort}
                changePage={changePage}
                changeSearch={changeSearch}
            />
        </>
    )
    
}

export default UsersListTable;