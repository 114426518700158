import "./AddSubscription.css"
import 'antd/dist/antd.css'
import '../../../App.css'
import {Result} from 'antd';
import React from "react";
import TitleWithBack from "../../../containers/TitleWithBack"

type SuccessProps = {
}

const Success: React.FC<SuccessProps> = ({}) => {

    return(
        <>
            <TitleWithBack title={"Crea Abbonamento"} /> 
            <Result 
                status="success"
                title="L'abbonamento è stato creato correttamente."
                subTitle=""             
            />
        </>
    )
    
}

export default Success;
