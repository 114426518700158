import { connect } from 'react-redux'
import { Store } from '../../reducers'
import {Dispatch} from 'react'
import {Action} from '../../actions'
import InstallersListTable from '../../components/pages/installersList/InstallersListTable'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
    }
}

const mapStateToProps = (state: Store) => {
    return {
        logged: state.info.logged,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallersListTable)