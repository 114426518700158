import { tableColumn } from './tableColumns'
import './SubscriptionsListTable.css'
import '../../../App.css'
import { getAllSubscriptions } from '../../../api/services/subscriptionService'
import React, { useCallback, useEffect, useState } from "react";
import CustomTitle from "../../CustomTitle";
import {useHistory} from "react-router-dom";
import ServerSideTable from '../../ServerSideTable';

type SubscriptionTableProps = {
    link: (p: string) => string,
}

const SubscriptionsListTable: React.FC<SubscriptionTableProps> = ({link}) => {

    document.body.style.background = "#f5f5f5c7"
    
    const [tableData, setTableData] = useState<Object[]>([]);
    const [totalDataLength, setTotalDataLength] = useState<number>(0);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [currentSort, setCurrentSort] = useState<string>("expiry_date");
    const [currentSearch, setCurrentSearch] = useState<string>('');

    const getSubscriptions = useCallback((page: number, sort: string, search: string) => {
        getAllSubscriptions(page.toString(), sort, search).then(
            res => {
                if(res && res.subscriptions)  {
                    setTableData(res.subscriptions)
                    setTotalDataLength(res?.total_count ?? 0 );
                } else {
                    setTableData([]);
                    setTotalDataLength(0);
                }
            }
        )
    }, [currentPage, currentSort]);

    useEffect(() => {
        getSubscriptions(currentPage, currentSort, currentSearch);
    }, [])

    const changePage = (page: number) => {
        setCurrentPage(page);
        getSubscriptions(page, currentSort, currentSearch);
    }

    const changeSort = (sort: string) => {
        setCurrentSort(sort);
        getSubscriptions(currentPage, sort, currentSearch);
    }

    const changeSearch = (search: string) => {
        setCurrentSearch(search);
        setCurrentPage(0);
        getSubscriptions(0, currentSort, search);
    }

    
    const history = useHistory();
    const linkFunction: (value: string) => void = (value: string) => { 
        history.push(link(value))
    }
    
    return(
        <>
            <CustomTitle title={"Lista Abbonamenti"} />
            <ServerSideTable
                columns={tableColumn(linkFunction)}
                data={tableData}
                totalDataLength={totalDataLength}
                changeSort={changeSort}
                changePage={changePage}
                changeSearch={changeSearch}
            />
        </>
    )
}

export default SubscriptionsListTable;