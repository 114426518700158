import { connect } from 'react-redux'
import { Store } from '../../../reducers'
import {Dispatch} from 'react'
import {Action} from '../../../actions'
import InstallationsListPage from "../../../components/pages/installationsList/InstallationsListPage";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {

    }
}

const mapStateToProps = (state: Store) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationsListPage)