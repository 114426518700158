import {installationFields, stairModels} from "./inputs"
import ButtonItem from "../../ButtonItem"
import ButtonConfItem from "../../ButtonConfItem"
import {Form, Select} from 'antd';
import React, {useEffect, useState} from "react";
import {CreateStair} from "../../../api/services/stairsService";
import {Subscription} from "../../../api/requests/subscription";
import {Stair} from '../../../api/requests/stairsService';
import CustomSelect from "../../CustomSelect";
import {getAllSubscriptions} from '../../../api/services/subscriptionService';
import CustomTitle from "../../CustomTitle";
import './AddInstallation.css'
import {useHistory} from "react-router-dom";
import FormInput from "../../input/FormInput";
import OperationResult from "../../OperationResult";
import {deviceConnectionConfigurationUrl} from "../costants";

const { Option } = Select;

type AddInstallationProps = {
    SelectedStair: Stair | null,
}

const AddInstallation: React.FC<AddInstallationProps> = () => {

    const [selectedModel, setSelectedModel] = useState<string | null>(null)
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
    const [status, setStatus] = useState<"success" | "error" | null> ();
    const goBack = () => {
        setStatus(null)
    }
    const history = useHistory();

    useEffect( () => {
        getAllSubscriptions("-1", "", "").then(res => {
            if(res && res.subscriptions){
                setSubscriptions(res.subscriptions)
            }
        })
    }, [])
    
    const [form] = Form.useForm();

    const submit = () => {
        form.validateFields().then(
            values => {
                CreateStair({
                    name: values.name,
                    subscription_id: values.subscription_id ?? "",
                    model: selectedModel ?? "",
                    description: values.description,
                    num_steps: Number(values.num_steps) ?? 0
                }).then((res)=>{
                    if(res && res.stair){
                        let userAgent = navigator.userAgent.toLowerCase();
                        if (userAgent.indexOf(' electron/') > -1) {
                            history.push(deviceConnectionConfigurationUrl(res.stair.id));
                        } else {
                            setStatus("success");
                        }
                    }else{
                        setStatus("error");
                    }
                })
            }
        )
    }

    if(status) {
        return (
            <OperationResult
                onClickProp={status === "error" ? goBack : undefined}
                status={status}
                operation={"create"}
                entity={"installation"}
            />
        )
    }

    return(
        <>
            <CustomTitle title={"Crea Installazione"} key={"add_installation_title"} /> 
            <div className="my-addinstallation-container my-addinstallation-container-responsive" key={"add_installation_div"}>
                <Form layout="vertical" key={1} form={form} name="user_panel">
                    <Form.Item label={"Seleziona un abbonamento"} name={"subscription_id"} key={"select_subscription"}>
                        <Select placeholder={"..."} key={"select"}>
                            {
                                subscriptions.map((el, index) => {
                                    return (
                                         <Option value={el.id} key={index}> {el.company_info.name} </Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label={"Seleziona un modello"} name={"model"} key={"select_model"}>
                        <CustomSelect
                            placeHolder={"..."}
                            options={stairModels}
                            id={"select"}
                            onChange={(key, value) => {setSelectedModel(value)}}
                        />
                    </Form.Item>
                    {
                        installationFields.map((el) => {
                            return (
                                <FormInput key={el.key + "forminput"}  name={el.name} keyValue={el.key} placeholder={el.label} type={(el.type === undefined)? "text" : el.type}  rules={el.rules ?? []}/>
                            )
                        })
                    }
                    <div className="btn-container" key={"add_installation_btn"}>
                        <ButtonConfItem buttonLabel="Reset" buttonOnConfirm={() => { form.resetFields(); }} buttonOnCancel={() => {}} questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?" />
                        <ButtonItem buttonType="primary" label="Invio" buttonOnClick={submit} />
                    </div>
                </Form>
            </div>
        </>
    )
    
}

export default AddInstallation;
