import './summary.css'
import React from "react";
import {Descriptions} from "antd";
import { Stair } from "../../api/requests/stairsService";
import { localeDateFormatter } from '../../utilities/utilities';

type StairSummaryProps = {
    selected_stair: Stair | null,
}

const StairSummary: React.FC<StairSummaryProps> = ({selected_stair}) => {
    return (
        <div className="description">
            <Descriptions labelStyle={{fontWeight: "bold"}} style={{marginTop: "2%", marginBottom: "2%"}} title="Installazione" bordered>
                <Descriptions.Item label="Nome" span={1.5}>
                    {(selected_stair as Stair).name}
                </Descriptions.Item>
                <Descriptions.Item label="Descrizione" span={1.5}>
                    {(selected_stair as Stair).description}
                </Descriptions.Item>
                <Descriptions.Item label="Modello" span={1.5}>
                    {(selected_stair as Stair).model}
                </Descriptions.Item>
                <Descriptions.Item label="Data creazione" span={1.5}>
                    {localeDateFormatter((selected_stair as Stair).created_at)}
                </Descriptions.Item>
            </Descriptions>
        </div>
    )
}

export default StairSummary;