import React from "react";
import {LeftCircleTwoTone} from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import '../App.css';
import {Divider} from "antd";
import {useHistory} from "react-router-dom";
import {routes} from "./pages/costants";

type TitleBackProps = {
    title: string
    setSelectedItem: (i: string) => void
    toHomePage?: boolean
    onClickProp?: () => void
}

const TitleWithBack: React.FC<TitleBackProps> = ({ title, setSelectedItem, toHomePage, onClickProp}) => {
    const history = useHistory();
    return (
        <>
            <div className={"titleContainer titleContainerResponsive titleContainerHeigth"}>
                <Title>
                    <LeftCircleTwoTone
                        twoToneColor="#5bc3d0"
                        className="back-button"
                        onClick={
                            () => {
                                if(onClickProp){
                                    onClickProp()
                                }else{
                                    if(toHomePage) {
                                        history.push(routes.mainPage);
                                        setSelectedItem("real_time_data");
                                    } else {
                                        history.goBack();
                                    }                                    
                                }
                            }
                        }
                    />
                </Title>
                <Title className={"titleBack responsiveTitle"} level={2}>
                    {title}
                </Title>
            </div>
            <Divider/>
        </>
    )
}

export default TitleWithBack;