import { connect } from 'react-redux'
import { Store } from '../../reducers'
import {Dispatch} from 'react'
import {Action} from '../../actions'
import TabValues from '../../components/pages/installation/dataTable/TabValues'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
    }
}

const mapStateToProps = (state: Store) => {
    return {
        temperature_data: state.chart.voltage80_data
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabValues)