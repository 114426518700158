import {ResponsiveTableCell, ResponsiveTableColumnType} from "../../responsiveTable";
import { DeleteTwoTone } from "@ant-design/icons";
import { UserRelation } from "../../../api/requests/userService";
import "../../../App.css";
import {iconType, stringType} from "../../../utilities/utilities";

export const installersListColumns: (deleteRelation: (record: UserRelation) => void) => ResponsiveTableColumnType[] = deleteRelation => [
    {
        label: "Nome Installatore",
        name: "installer_name",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value}
                />
        }
    },
    {
        label: "Email Installatore",
        name: "installer_email",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value}
                />
        }
    },
    {
        label: "Nome Cliente",
        name: "customer_name",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value}
                />
        }
    },
    {
        label: "Email Cliente",
        name: "customer_email",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value}
                />
        }
    },
    {
        label: "Disassocia",
        name: "",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={iconType}
                    link={() => deleteRelation(data.tableData[data.rowIndex])}
                    value={""}
                    icon={<DeleteTwoTone/>}
                />
        }
    }
]