import { stringType, tagType } from "../../../utilities/utilities"
import { ResponsiveTableCell, ResponsiveTableColumnType } from "../../responsiveTable"

export const configurationData: any = [
    {
        title: "Temperatura alimentatore",
        enabled: true,
        value: 0,
        status: "pending"
    },
    {
        title: "Corrente assorbita alimentatore 80 Vdc",
        enabled: true,
        value: 0,
        status: "pending"
    },
    {
        title: "Corrente assorbita alimentatore 5 Vdc",
        enabled: true,
        value: 0,
        status: "pending"
    },
    {
        title: "Tensione alimentazione motori 80 Vdc",
        enabled: true,
        value: 0,
        status: "pending"
    },
    {
        title: "Tensione alimentazione schede 5 Vdc",
        enabled: true,
        value: 0,
        status: "pending"
    }
]

export const configurationsListColumns: ResponsiveTableColumnType[] = [
    {
        label: "Nome",
        name: "title",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value}
                />
        }
    },
    {
        label: "Stato",
        name: "enabled",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={tagType}
                    value={value}
                    color={value === "True" ? "green" : "red"}
                />
        }
    },
    {
        label: "Valore",
        name: "value",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value}
                />
        }
    }
]