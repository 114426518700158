import { MenuType } from "../types/navbar/types";
import {
    alarmsPageUrl,
    subscriptionsListToInstallationPageUrl
} from "../pages/costants";
import dataRealTime from "../pages/homePages/img/graph_white.png";
import alert from "../pages/homePages/img/alert_white.png";

export const customerMenuItems: MenuType[] = [
    {   
        key: "customer", 
        label: "Dati Real Time",
        icon: <img alt={"--"} src={dataRealTime} className={"menu-icon"}/>,
        elements: [],
        link: subscriptionsListToInstallationPageUrl
    },
    {   
        key: "alarms", 
        label: "Allarmi",
        link: alarmsPageUrl,
        icon: <img alt={"--"} src={alert} className={"menu-icon"}/>,
        elements: []
    }
];