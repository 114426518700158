import './DataHistoryPanel.css'
import React, {useCallback, useEffect, useState} from 'react';
import { DatePicker, Form, message } from 'antd';
import { Stair } from '../../../api/requests/stairsService';
import ButtonConfItem from '../../ButtonConfItem';
import ButtonItem from '../../ButtonItem';
import TitleWithBack from "../../../containers/TitleWithBack";
import StairSummary from '../../../containers/stairSummary/StairSummary';
import {getStair} from "../../../api/services/stairsService";
import moment from 'moment';
import { getExportInfo } from '../../../api/services/timeSeries';
import { GetExportInfoRequest } from '../../../api/requests/timeSeries';

type DataHistoryPanelProps = {
    stair_id: string
}

const DataHistoryPanel: React.FC<DataHistoryPanelProps> = ({ stair_id}) => {
    const [selectedStair, setSelectedStair] = useState<Stair | null>(null)
    const [form] = Form.useForm()

    useEffect(() => {
        getStair({id: stair_id}).then(
            res => {
                if (res && res.stair) {
                    setSelectedStair(res.stair)
                }
            }
        )
    }, [])

    const downloadFile = useCallback((url: string) => {
        // Create a link element
        const link = document.createElement("a");

        // Set link's href to point to the Blob URL
        link.href = url

        // Append link to the body
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );

        // Remove link from body
        document.body.removeChild(link);
    }, [])

    const dateFormatter = (date: Date) => {
        return moment(date).format('YYYY-MM-DD');
    };

    const handleSubmit = () => {
        form.validateFields().then(
            values => {
                const first = new Date(values.date[0])
                const second = new Date(values.date[1])
                var differenceInTime = second.getTime() - first.getTime();
                var differenceInDays = differenceInTime / (1000 * 3600 * 24);

                if(differenceInDays <= 6){
                    const info: GetExportInfoRequest = {
                        tag: "data",
                        start: dateFormatter(new Date(values.date[0])),
                        end: dateFormatter(new Date(values.date[1])),
                        entity_id: stair_id
                    }
                    getExportInfo(info).then(
                        resp => {
                            if(resp && resp.url) {
                                downloadFile(resp.url)
                            } else if (resp && resp.err) {
                                message.error(resp.err?.message)
                            } else {
                                message.error("failed to download file - maybe no data")
                            }
                        }
                    ).catch(
                        resp => {
                            message.error("failed to download file")
                            console.log(resp)
                        }
                        
                    )
                }else{
                    message.error("Possono essere selezionati massimo 7 giorni.")
                }
            }
        )
    }

    if (!selectedStair) {
        return null
    }

    return (
        <>
            <TitleWithBack title={"Storico Dati"} key={"data_history_title"}/>
            <StairSummary selected_stair={selectedStair} />

            <div className="my-data-container my-data-container-responsive">
                <Form className={"exportForm"} layout="vertical" key={1} form={form} name="datahistory_panel" requiredMark="optional">
                    <Form.Item name="date" label="Seleziona le date per cui scaricare i dati" rules={[ {required: true, message: 'Inserire la data!'} ]} >
                        <DatePicker.RangePicker className="datepicker" size="large" />
                    </Form.Item>
                    <div className="btn-container">
                        <ButtonConfItem buttonLabel="Reset" buttonOnConfirm={() => { form.resetFields(); }} buttonOnCancel={() => {}} questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?" />
                        <ButtonItem buttonType="primary" label="Invio" buttonOnClick={handleSubmit} />
                    </div>
                </Form>
            </div>
        </>
    )
}

export default DataHistoryPanel;