import './summary.css'
import React, {useCallback} from 'react';
import {Card, Divider} from 'antd';
import Title from "antd/es/typography/Title";
import {Stair} from '../../../../api/requests/stairsService';
import CustomTag from "./customTag";
import {nameSwitch} from '../../../types/form/types';
import {useHistory} from 'react-router';

type AlarmSummaryProps = {
    role: string
    stair: Stair | null
}

const AlarmSummary: React.FC<AlarmSummaryProps> = ({ role, stair }) => {
    const history = useHistory()
    const alarmDescriptionFactory = useCallback(() => {
        let descriptions = [] as any[]
        if(stair && stair.alarms) {
            let index = 0
            for (const [key, value] of Object.entries(stair.alarms)) {
                descriptions = [...descriptions,
                    <div key={`container_div_${key}`}>
                        <div className="tag-container">
                            {nameSwitch(value.name)}
                            <CustomTag
                                color={Number(value.status) === 1 ? "green" : "red"}
                                text={Number(value.status) === 1 ? "abilitato" : "disabilitato"}
                            />
                        </div>
                        {
                            index !== Object.entries(stair.alarms).length - 1 ? <Divider/> : null
                        }
                    </div>
                ]
                index ++
            }
        }
        return descriptions
    }, [stair])

	return(
        <Card
            className={"summary-card"}
            hoverable
            title={<Title level={3} className={"summaryTitle"}> Allarmi </Title>}
            bordered
            /*extra={
                role === "installer" ?
                <WarningOutlined
                    onClick={() => {history.push(configurationAlarmsPageUrl(stair?.id || ""))}}
                /> :
                null
            }*/
        >
            {/*alarmDescriptionFactory()*/}
            <div className="tag-container">
                Device offline
                <CustomTag color="green" text="abilitato" />
            </div>
            <Divider/>
        </Card>
    )
}

export default AlarmSummary;