import 'antd/dist/antd.css';
import './FormSelect.css'
import { Form, Select } from 'antd';
import React from "react";

const { Option } = Select;

type FormSelectProps = {
    placeholder: string,
    keyValue: string,
    options: any[]
    name?: string,
    value?: string,
    rules?: any,
}

const FormSelect: React.FC<FormSelectProps> = ({placeholder, keyValue, options, name, value, rules}) => {
    return(
        <div className="form__group field" key={keyValue + "div"}>
            <label key={keyValue + "label"} htmlFor="name" className="form__label">{placeholder}</label>
            <Form.Item key={keyValue + "form"} name={name} initialValue={value} rules={rules ?? undefined}>
                <Select 
                    showSearch
                    key={keyValue} 
                    placeholder={placeholder}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        String(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                        String(optionA.children).toLowerCase().localeCompare(String(optionB.children).toLowerCase())
                    }
                >
                    {
                        options.map((el: any, index: number) => {
                            const keys = Object.keys(el)
                            return (
                                <Option value={keys[0]} key={index}> {el[keys[0]]} </Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
        </div>
    )
}

export default FormSelect;