import { InputType } from "../../types/form/types"

export const userFields: InputType[] = [
    {
        key: "name",
        name: "name",
        label: "Nome"
    },
    {
        key: "email",
        name: "email",
        label: "Email"
    },
    {
        key: "phone",
        name: "phone",
        label: "Telefono",
        path: "phone",
        type: "number"
    },
    {
        key: "address",
        name: "address",
        label: "Indirizzo",
        path: "address"
    },
    {
        key: "city",
        name: "city",
        label: "Città",
        path: "city"
    },
    {
        key: "province",
        name: "province",
        label: "Provincia",
        path: "province"
    },
    {
        key: "country",
        name: "country",
        label: "Paese",
        path: "country",
        type: "select"
    },
    {
        key: "zip_code",
        name: "zip_code",
        label: "CAP",
        path: "zip_code"
    }
]
