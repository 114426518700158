export type InputType = {
    key:string,
    name: string,
    label: string,
    rules?: any,
    type?: string,
    options?: string[],
    icon?: any
    path?: string
}

export const nameSwitch = (name: string) => {
    let result: string = ""
    switch(name){
        case "temp":
            result = "Temperatura alimentatore"
            break
        case "V80":
            result = "Presenza tensione alimentazione motori 80 Vdc"
            break
        case "V5":
            result = "Presenza tensione alimentazione schede 5 Vdc"
            break
        case "C80":
            result = "Corrente assorbita alimentatore 80 Vdc"
            break
        case "C5":
            result = "Corrente assorbita alimentatore 5 Vdc"
            break
        case "activation_alarm":
            result = "Attivazione dell’impianto inizio e fine ciclo"
            break
    }
    return result
}

export const switchLevel = (level: number) => {
    let result: string = ""
    switch(level){
        case 0: 
            result = "info"
            break
        case 1:
            result = "warning"
            break
        case 2:
            result = "error"
            break
        case 3:
            result = "fatal"
            break
    }
    return result
}