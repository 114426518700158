import './summary.css'
import React from 'react';
import {Card, Divider} from 'antd';
import Title from "antd/es/typography/Title";
import {Typography} from "@material-ui/core";
import { Stair } from '../../../../api/requests/stairsService';

type StepSummaryProps = {
    selected_stair: Stair
}

const StepSummary: React.FC<StepSummaryProps> = ({selected_stair}) => {

	return(
        <Card
            hoverable
            title={<Title level={3} className={"summaryTitle"}> Info Real Time </Title>}
            className={"summary-card"}
        >
            <div key={`container_div_1`}>
                <div className="tag-container">
                    <Typography>Numero scalini:</Typography>
                    <Typography> {selected_stair.num_steps} </Typography>
                </div>
            </div>
            <Divider />
            {/*
            <div key={`container_div_2`}>
                <div className="tag-container">
                    <Typography>Tempo funzionamento:</Typography>
                    {}
                </div>
                <Divider />
            </div>
            <div key={`container_div_3`}>
                <div className="tag-container">
                    <Typography>Tempo in allarme:</Typography>
                    <Typography> {} </Typography>
                </div>
            </div>
            <Divider />
            <div key={`container_div_3`}>
                <div className="tag-container">
                    <Typography>Tempo stand-by:</Typography>
                    <Typography> {} </Typography>
                </div>
            </div>            
            */}

        </Card>
    )
}

export default StepSummary;