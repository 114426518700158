import './LineChart.css'
import moment from "moment";
import { ResponsiveContainer, LineChart as Chart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line, ReferenceArea } from "recharts";
import { ChartObject, DataObject } from '../../../../../api/requests/timeSeries';
import React, {useCallback} from 'react';
import { Empty, Button } from 'antd';
import {Aggregation, SET_MOTOR_SPEED_ZOOM, SET_VOLTAGE80_ZOOM, SET_POWER80_ZOOM, SET_VOLTAGE5_ZOOM, SET_POWER5_ZOOM, SET_MOTOR_OVERCURRENT_ZOOM, SET_PANEL_TEMPERATURE_ZOOM} from "../../../../../actions/lineChart";
import { lineColors, otherLinesColors } from '../../../costants';
import { ZoomUpdates, Zoom } from '../../../../types/timeseries/types';
import { ZoomOutOutlined } from '@ant-design/icons';
import { localeDateTimeFormatter } from '../../../../../utilities/utilities';

type LineChartProps = {
    data_chart: ChartObject[],
	aggregations_view: string[],
	zoom: Zoom,
	SetAggregationView: (aggregations: string[]) => void,
	zoomType: SET_PANEL_TEMPERATURE_ZOOM | SET_VOLTAGE80_ZOOM | SET_POWER80_ZOOM | SET_VOLTAGE5_ZOOM | SET_POWER5_ZOOM | SET_MOTOR_SPEED_ZOOM | SET_MOTOR_OVERCURRENT_ZOOM,
	SetZoom: (type: SET_PANEL_TEMPERATURE_ZOOM | SET_VOLTAGE80_ZOOM | SET_POWER80_ZOOM | SET_VOLTAGE5_ZOOM | SET_MOTOR_OVERCURRENT_ZOOM | SET_POWER5_ZOOM | SET_MOTOR_SPEED_ZOOM, ref: ZoomUpdates) => void,
	width: number
	heigth: number
	twoYAxis?: boolean 
}

const LineChart: React.FC<LineChartProps> = ({ data_chart, aggregations_view, zoom, SetAggregationView, SetZoom, zoomType, width, heigth, twoYAxis }) => {

  	const dateFormatter = (date: string) => {
		return moment(date).format('DD/MM/YY HH:mm');
    };

	const mergeDataArrays = (data_chart: ChartObject) => {
		let res : DataObject[] = []
		for (const [key, value] of Object.entries(data_chart)) {
			res = [...res, ...value || []]
		}
		return res
	}

	const chartLines = useCallback((data_chart: ChartObject, index: number, color: string) => {
		let res: any[]=[]
		const finalData = data_chart
		for ( const [ key, value ] of Object.entries(finalData)) {
			if(value && value?.length>0 && aggregations_view.includes(key)) {
				if(twoYAxis){
					if(color === "first"){
						//voltage
						res = res.concat(<Line yAxisId="left" isAnimationActive={false} type="monotone" dataKey="value" data={value} name={"Tensione"} key={key + index} stroke={lineColors[key as Aggregation]}/>)
					}else{
						//power
						res = res.concat(<Line yAxisId="right" isAnimationActive={false} type="monotone" dataKey="value" data={value} name={"Corrente"} key={key + index} stroke={otherLinesColors[key as Aggregation]}/>)
					}
				}else{
					res = res.concat(<Line isAnimationActive={false} type="monotone" dataKey="value" data={value} name={key} key={key + index} stroke={lineColors[key as Aggregation]}/>)
				}
			}
		}
		return res
    }, [data_chart, aggregations_view, twoYAxis, zoom])

	const getAxisYDomain = (data_chart: ChartObject, from: string, to: string, ref: string, offset: number, type?: string) => {
		const refData: DataObject[] = mergeDataArrays(data_chart).filter((data)=>new Date(data.ts) >= new Date(from) && new Date(data.ts) <= new Date(to))
		let filteredData: ChartObject = {}
		for (const [key, value] of Object.entries(data_chart)) {
			filteredData[key as Aggregation] = value?.filter((data)=>new Date(data.ts) >= new Date(from) && new Date(data.ts) <= new Date(to)) || []
		}
		if( refData!==[] ){	
			const bottom = Math.min(...refData.reduce((a, o) => (a.push(o?.value || -1), a), [] as number[] ))
			const top = Math.max(...refData.reduce((a, o) => (a.push(o?.value || -1), a), [] as number[] ))
				if(type === "voltage"){
					SetZoom(zoomType, {
						voltage_data: filteredData
					})
				}else{
					if(type === "power"){
						SetZoom(zoomType, {
							power_data: filteredData
						})
					}else{
						SetZoom(zoomType, {
							data: filteredData,
						})
					}
				}

			return [(bottom | 0) - offset, (top | 0) + offset];
		}
		return [0, 100]
	};

	const do_zoom = (d_chart: ChartObject[])=>{
		if (zoom.refAreaLeft === zoom.refAreaRight || zoom.refAreaRight === '') {
			SetZoom(zoomType,{ refAreaLeft: '', refAreaRight: '' })
			return
		}
		// xAxis domain
		if (zoom.refAreaLeft > zoom.refAreaRight) [zoom.refAreaLeft, zoom.refAreaRight] = [zoom.refAreaRight, zoom.refAreaLeft];
		// yAxis domain
		let [bottom, top] = [0, 0]
		if(!twoYAxis){
			[bottom, top] = getAxisYDomain(d_chart[0], zoom.refAreaLeft, zoom.refAreaRight, 'value', 0);
		}else{
			const [tmp_1_bottom, tmp_1_top] = getAxisYDomain(d_chart[0], zoom.refAreaLeft, zoom.refAreaRight, 'value', 0, 'voltage');
			const [tmp_2_bottom, tmp_2_top] = getAxisYDomain(d_chart[1], zoom.refAreaLeft, zoom.refAreaRight, 'value', 0, 'power');
			bottom = tmp_1_bottom < tmp_2_bottom ? tmp_1_bottom : tmp_2_bottom
			top = tmp_1_top > tmp_2_top ? tmp_1_top : tmp_2_top
		}

		SetZoom(zoomType,{
			refAreaLeft: '',
			refAreaRight: '',
			left: zoom.refAreaLeft,
			right: zoom.refAreaRight,
			bottom: bottom.toString(),
			top: top.toString(),
			isZoomed: true,
			isZooming: false
		})
	}

	const zoomOut = () => {
		SetZoom(zoomType,{
			data: {},
			voltage_data: {},
			power_data: {},
			left: "",
			right: "",
			refAreaLeft: "",
			refAreaRight: "",
			top: "dataMax+1",
			bottom: "dataMin-1",
			animation: true,
			isZoomed: false
		})
	}

	let maxValue = 0;
	data_chart.map((dc: ChartObject) => {
		for (const [key, value] of Object.entries(dc) ) {
			if(value && value.length > 0) {
				maxValue = Math.max.apply(Math, value.map(function(o) { return o.value ?? 0 }))
			}
		}
	})

	return(
			<>
				{  
					(mergeDataArrays(data_chart[0]).length > 0) ?
						<>
							<div className={ width <= 575 ? "chartStyleResponsive" :"chartStyle" }>	
								<ResponsiveContainer width="100%" maxHeight={280} height={280}>
									<Chart
										onMouseDown={(e: any) => e !== null ?  SetZoom(zoomType,{ isZooming:true, refAreaLeft: String(e.activeLabel)}) : null}
										onMouseMove={(e: any) => e !== null ?  zoom.refAreaLeft !== "" && SetZoom(zoomType,{ refAreaRight: String(e.activeLabel) }) : null}
										onMouseUp={() => do_zoom(data_chart) }
									>
										<CartesianGrid />
										{
											aggregations_view.length > 0 ?
											<XAxis 
												dataKey={"ts"} 
												name="Data" 
												tickFormatter={dateFormatter} 
												allowDuplicatedCategory={false} 
											/> :
											null
										}	
										{
											twoYAxis ?
											<YAxis 
												yAxisId="left" 
												type="number" 
												domain={[0, maxValue * 1.2]}
												dataKey={"value"} 
												name="Valore"  
											/> :
											null
										}
										{	
											twoYAxis ?	
											<YAxis 
												yAxisId="right" 
												orientation="right" 
												type="number" 
												domain={[0, maxValue * 1.2]}
												dataKey={"value"} 
												name="Valore" 
											/> :
											<YAxis 
												width={80}
												tickFormatter={
													(value) => {
														return value.toFixed(1)
													}
												}
												allowDataOverflow={true}
												allowDecimals={true}
												type="number"
												domain={[0, maxValue * 1.2]}
												dataKey={"value"}
												name="Valore"
												tickCount={7}
											/>
										} 
											<Tooltip 
												cursor={{ strokeDasharray: "3 3" }} 
												wrapperStyle={{zIndex: 101}}
												formatter={(value: string, name: string) => {return `${value}`}}
												labelFormatter={(value: string, payload: any[]) => {
													let first_data_chart: string[] = []
													let second_data_chart: string[] = []
													if(data_chart.length === 2){
														data_chart[1]?.avg?.map((el, index) => {
															if(String(el.ts) === String(value)){
																second_data_chart.push(el.value)
															}
														})
														data_chart[1]?.sum?.map((el, index) => {
															if(String(el.ts) === String(value)){
																second_data_chart.push(el.value)
															}
														})
														return(
															<div>
																<p> {localeDateTimeFormatter(value)} </p>
																<p style={{color: otherLinesColors.sum}}>Corrente: {second_data_chart[0]}</p>
															</div>
														)
													}else{
														return(
															<div>
																<p style={{color: lineColors.max}}>{first_data_chart[1]}</p>
															</div>
														)
													}
													
												}}
											/>
										{
											zoom.isZoomed && twoYAxis?
											chartLines(zoom.voltage_data || {}, 0, "first") :
											null
										}
										{
											zoom.isZoomed ?
												twoYAxis ?
												chartLines(zoom.power_data || {}, 1, "second") :
												chartLines(zoom.data || {}, 0, "first") :
												data_chart.map((el, index) => {
													if(index === 0){
														return chartLines(el, index, "first")
													}else{
														return chartLines(el, index, "second")
													}
												})
										}
										<Legend className="legend"/>
										{
										zoom.refAreaLeft && zoom.refAreaRight && twoYAxis ? 
											<ReferenceArea yAxisId={"right"} x1={zoom.refAreaLeft} x2={zoom.refAreaRight} strokeOpacity={0.3} /> :
											null
										}
										{
										zoom.refAreaLeft && zoom.refAreaRight && !twoYAxis ? 
											<ReferenceArea x1={zoom.refAreaLeft} x2={zoom.refAreaRight} strokeOpacity={0.3} /> :
											null
										}
									</Chart>
								</ ResponsiveContainer>
							</div>
							{
								(width > 575 && heigth > 414) ?
								<div className="options">
									<Button size="large" type="primary" shape="circle" icon={<ZoomOutOutlined />} disabled={!zoom.isZoomed} onClick={() => zoomOut()}/>
								</div> :
								null
							}
						</> :
					<Empty />
				}
			</>    
		)
    
}

export default LineChart;
