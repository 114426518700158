import './charts/lineChart/LineChart.css'
import '../../../App.css'
import { ChartObject } from '../../../api/requests/timeSeries';
import React, {} from 'react';
import { Tabs } from 'antd';
import DataTable from '../../../containers/pages/TabValues';
import {TableOutlined} from "@ant-design/icons";
import Title from "antd/es/typography/Title";

const { TabPane } = Tabs;

type ChartProps = {
    width: number,
    title: string,
    icon: any,
    chart: any,
    datachart: ChartObject
}

const Chart: React.FC<ChartProps> = ({ title, width, icon, chart, datachart }) => {

    return (
        <div className={"dataDiv"}>
            <Title level={3} className={"tableTitle"}> {title} </Title>
            <Tabs className="tabStyle" defaultActiveKey="chart" tabPosition={width <= 768 ? "top" : "left"} >
                <TabPane tab= { icon } key="chart">
                    { chart }
                </TabPane>
                <TabPane tab={<TableOutlined />} key="table">
                    <DataTable data_chart={datachart} />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default Chart;