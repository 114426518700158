import React, { useCallback, useEffect, useState } from "react"
import '../../navbar/Navbar.css'
import './alarmTable.css'
import '../../../App.css'
import CustomTitle from "../../CustomTitle";
import {alarmColumns} from "./columns";
import {getAlarms} from "../../../api/services/timeSeries";
import ServerSideTable from "../../ServerSideTable";
import { useHistory } from "react-router";
import { installationPageUrl } from "../costants";

type AlarmTableProps = {
}

const AlarmTable: React.FC<AlarmTableProps> = ({}) => {

    document.body.style.background = "#f5f5f5c7"

    const [tableData, setTableData] = useState<Object[]>([]);
    const [totalDataLength, setTotalDataLength] = useState<number>(0);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [currentSort, setCurrentSort] = useState<string>("start");
    const [currentSearch, setCurrentSearch] = useState<string>('');

    const getAllAlarms = useCallback((page: number, sort: string, search: string) => {
        getAlarms(page.toString(), sort, search).then(
            res => {
                if(res && res.alarms)  {
                    setTableData(res.alarms)
                    setTotalDataLength(res?.total_count ?? 0 );
                } else {
                    setTableData([]);
                    setTotalDataLength(0);
                }
            }
        )
    }, [currentPage, currentSort]);

    useEffect(() => {
        getAllAlarms(currentPage, currentSort, currentSearch);
    }, [])

    const changePage = (page: number) => {
        setCurrentPage(page);
        getAllAlarms(page, currentSort, currentSearch);
    }

    const changeSort = (sort: string) => {
        setCurrentSort(sort);
        getAllAlarms(currentPage, sort, currentSearch);
    }

    const changeSearch = (search: string) => {
        setCurrentSearch(search);
        setCurrentPage(0);
        getAllAlarms(0, currentSort, search);
    }

    const history = useHistory()
    const linkFunction: (id: string) => void = (id: string) => { 
        history.push(installationPageUrl(id))
    }

    return(
        <>
            <CustomTitle title={"Allarmi"}/>
            <ServerSideTable
                columns={alarmColumns(linkFunction)}
                data={tableData}
                totalDataLength={totalDataLength}
                changeSort={changeSort}
                changePage={changePage}
                changeSearch={changeSearch}
            />
        </>
    )
}

export default AlarmTable;