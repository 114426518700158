export const verticalFetch = (method: string, url: string, param?: string): Promise<any> => {
    return fetch(url, {
        method: method,
        credentials: "include",
        body: param,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(
        r => r.json()
    )
}

export const methodPost = "POST"
export const methodPut = "PUT"
export const methodGet = "GET"
export const methodDelete = "DELETE"