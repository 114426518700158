import React, {ReactNode} from "react";
import {Tag} from "antd";

import './summary.css';

type CustomTagProps = {
    color: string
    text: string
    icon?: ReactNode
}

const CustomTag:React.FC<CustomTagProps> = ({color, icon, text}) => {
    return (
        <Tag
            className={"custom-tag"}
            color={color}
            icon={icon}
        >
            {text}
        </Tag>
    )
}

export default CustomTag;