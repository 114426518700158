import "./createCustomer.css"
import ButtonItem from "../../ButtonItem"
import ButtonConfItem from "../../ButtonConfItem"
import { Col, Form, message, Row } from 'antd';
import { AddUser as ApiAddUser } from "../../../api/services/userService"
import React, { useEffect, useState } from "react";
import '../../navbar/Navbar.css'
import '../../../App.css'
import CustomTitle from "../../CustomTitle";
import { userFields } from "./inputs";
import {routes, roles} from "../costants";
import {useHistory} from "react-router-dom";
import FormInput from "../../input/FormInput";
import { countries } from "../../countries";
import FormSelect from "../../select/FormSelect";
import OperationResult from "../../OperationResult";


type CreateCustomerProps = {
    SetItem: (s: string) => void
}

const CreateCustomer: React.FC<CreateCustomerProps> = ({SetItem}) => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [height, setHeigth] = useState<number>(window.innerHeight);
    const [status, setStatus] = useState<"success" | "error" | null> (null);
    const goBack = () => {
        setStatus(null)
    }

    function handleWindowSizeChange() {
			setWidth(window.innerWidth)
            setHeigth(window.innerHeight)
	}

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, []);

    const [form] = Form.useForm();
    const history = useHistory();

    const handleSubmit = () => {
        form.validateFields().then(
            values => {
                ApiAddUser({
                    name: values.name,
                    email: values.email,
                    password: values.password,
                    confirm_password: values.confirm_password,
                    role: roles.customer,
                    user_info: { 
                        phone: values.customer_phone,
                        address: values.customer_address,
                        city: values.customer_city,
                        province: values.customer_province,
                        country: values.customer_country,
                        zip_code: values.customer_zip_code
                    }
                }).then(
                    res => {
                        if(res && res.user){
                            setStatus("success");
                            SetItem("create_subscription");
                            history.push(routes.addSubscription);
                        } else {
                            setStatus("error");
                        }
                    }).catch((e)=>{
                        message.error('Creazione utente fallita', 4);
                        console.log(e)
                    })
            }
        )               
    }
    
    const formItemLayout = {
        labelCol: {
            sm: {
                span: 8
            }
        },
        wrapperCol: {
            sm: {
                span: 20
            }
        }
    };

    if(status) {
        return (
            <OperationResult
                onClickProp={status === "error" ? goBack : undefined}
                status={status}
                operation={"create"}
                entity={"user"}
            />
        )
    }

    return(
        <>
            <CustomTitle title={"Crea Nuovo Utente"} key={"title"} />
            <div className="my-adduser-container my-adduser-responsive" key={"div"}>
                <Form {...formItemLayout} layout="vertical" form={form} key={1}  name="user_panel">
                    <Row gutter={24} key={"companyInfo"}>
                        {
                            userFields.map((el, index) => {
                                return (
                                    <Col span={(width <= 575 || height <= 414) ? 12 : 8} key={index}>
                                        {
                                            el.type !== "select" ?
                                                <FormInput key={el.key + "forminput"} placeholder={el.label} type={(el.type === undefined)? "text" : el.type} keyValue={el.key} name={el.name} rules={ el.rules ?? [] } /> :
                                            <FormSelect key={el.key + "forminput"} placeholder={el.label} keyValue={el.key} name={el.name} options={countries} rules={ el.rules ?? [] } />
                                        }
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    <div className="btn-container" key={2}>
                        <ButtonConfItem buttonLabel="Reset" buttonOnConfirm={() => { form.resetFields(); }} buttonOnCancel={() => {}} questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?" /> 
                        <ButtonItem buttonType="primary" label="Invio" buttonOnClick={handleSubmit} />
                    </div>
                </Form>
            </div>
        </>
    )

}

export default CreateCustomer;