import moment from "moment";

// types string constants
export const stringType = 'string';
export const numberType = 'number';
export const linkType = 'link';
export const tagType = 'tag';
export const dateType = 'date';
export const iconType = 'icon';
export const statusDate = 'statusDate';
export const thresholdType = 'threshold';
// Dates formatters
export const italyTimezone = 'it-IT';
export const localeDateFormatter = (value: string) => {
    const timestamp = Date.parse(value);
    if (!isNaN(timestamp)) {


        return moment(new Date(value)).format("DD/MM/YYYY");
    } else {
        return "---"
    }
}

export const localeDateTimeFormatter = (value: string) => {
    const timestamp = Date.parse(value);
    if (!isNaN(timestamp)) {


        return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
    } else {
        return "---"
    }
}