import { changeStatus, getAllSubscriptions } from '../../../api/services/subscriptionService';
import { tableColumn } from './tableColumn';
import './SubscriptionManagement.css';
import { ChangeStatusRequest } from '../../../api/requests/subscription';
import ServerSideTable from '../../ServerSideTable';
import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';

type SubscriptionManagementProps = {
}

const SubscriptionManagement: React.FC<SubscriptionManagementProps> = ({}) => {

    document.body.style.background = "#f5f5f5c7"

    const manageSubscription = (subscription_id: string, requested_status: string) => {
        const request: ChangeStatusRequest = {
            subscription_id: subscription_id,
            requested_status: requested_status
        }
        changeStatus(request).then((res) => {
            if(res && res.subscription){
                window.location.reload()
            }else{
                message.error("Errore. Riprova più tardi.")
            }
        })
    }

    const [tableData, setTableData] = useState<Object[]>([]);
    const [totalDataLength, setTotalDataLength] = useState<number>(0);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [currentSort, setCurrentSort] = useState<string>("expiry_date");
    const [currentSearch, setCurrentSearch] = useState<string>('');

    const getSubscriptions = useCallback((page: number, sort: string, search: string) => {
        getAllSubscriptions(page.toString(), sort, search).then(
            res => {
                if(res && res.subscriptions)  {
                    setTableData(res.subscriptions)
                    setTotalDataLength(res?.total_count ?? 0 );
                } else {
                    setTableData([]);
                    setTotalDataLength(0);
                }
            }
        )
    }, [currentPage, currentSort]);

    useEffect(() => {
        getSubscriptions(currentPage, currentSort, currentSearch);
    }, [])

    const changePage = (page: number) => {
        setCurrentPage(page);
        getSubscriptions(page, currentSort, currentSearch);
    }

    const changeSort = (sort: string) => {
        setCurrentSort(sort);
        getSubscriptions(currentPage, sort, currentSearch);
    }

    const changeSearch = (search: string) => {
        setCurrentSearch(search);
        setCurrentPage(0);
        getSubscriptions(0, currentSort, search);
    }

    return(
        <ServerSideTable
            columns={tableColumn(manageSubscription)}
            data={tableData}
            totalDataLength={totalDataLength}
            changeSort={changeSort}
            changePage={changePage}
            changeSearch={changeSearch}
        />
    )
}

export default SubscriptionManagement;