import { ChangeStatusRequest, Subscription } from "../../../api/requests/subscription";
import {ResponsiveTableCell, ResponsiveTableColumnType} from "../../responsiveTable";
import "../../../App.css";
import React from "react";
import {dateType, linkType, stringType} from "../../../utilities/utilities";
import {roles} from "../costants";
import { Button, message, Popconfirm } from "antd";
import { changeStatus } from "../../../api/services/subscriptionService";


export const subscriptionsTableColumns: (link: (v: string) => void, role: string, updSubState: (record: Subscription) => React.ReactNode) => ResponsiveTableColumnType[] = (
    linkFunction,
    role,
    updSubState
) => [
    {
        label: "Utenza",
        name: "company_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={linkType}
                    value={data.tableData[data.rowIndex].company_info.name}
                    link={() =>{linkFunction(data.tableData[data.rowIndex].id)}}
                />
        }
    },
    {
        label: "SDI",
        name: "company_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={data.tableData[data.rowIndex].company_info.sdi}
                />
        }
    },
    {
        label: "PEC",
        name: "company_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={data.tableData[data.rowIndex].company_info.pec}
                />
        }
    },
    {
        label: "VAT",
        name: "company_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={data.tableData[data.rowIndex].company_info.vat_number}
                />
        }
    },
    {
        label: "Telefono",
        name: "company_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={data.tableData[data.rowIndex].company_info.phone}
                />
        }
    },
    {
        label: "Indirizzo",
        name: "company_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={`${data.tableData[data.rowIndex].company_info.address}, ${data.tableData[data.rowIndex].company_info.city}, ${data.tableData[data.rowIndex].company_info.province}`}
                />

        }
    },
    {
        label: "CAP",
        type: "string",
        name: "company_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={data.tableData[data.rowIndex].company_info.zip_code}
                />
        }
    },
    {
        label: "Scadenza",
        name: "expiry_date",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={dateType}
                    value={value}
                />
        }
    },
    ...statusIfAdmin(role, updSubState)
];

const statusIfAdmin = (role: string, updSubState: (record: Subscription) => React.ReactNode) => {
    if(role === roles.admin) {
        return [
            {
                label: "Stato",
                name: "",
                options: {
                    sort: true,
                    filter: false,
                    customBodyRender: (value: string, data: any) =>
                        <div className="cell"> {updSubState(data.tableData[data.rowIndex])} </div>
                }
            },
            {
                label: "Azione",
                name: "requested_status",
                options: {
                    sort: true,
                    filter: false,
                    customBodyRender: (value: string, data: any) => <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}> 
                        {
                            value === "activation" ?
                            <p> Richiesta attivazione </p> :
                            value === "deactivation" ?
                            <p> Richiesta disattivazione </p> :
                            null
                        }
                        {
                            value !== "" ?
                            <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                <Popconfirm
                                    title="Sei sicuro?"
                                    onConfirm={() => {
                                        const request: ChangeStatusRequest = {
                                            subscription_id: data.tableData[data.rowIndex].id,
                                            response_status: "accept"
                                        }
                                        changeStatus(request).then((res) => {
                                            if(res && res.subscription){
                                                window.location.reload()
                                            }else{
                                                message.error("Errore. Riprova più tardi.")
                                            }
                                        })
                                    }}
                                    onCancel={() => {}}
                                    okText="Si"
                                    cancelText="No"
                                >
                                    <Button type="primary">
                                        Accetta
                                    </Button> 
                                </Popconfirm>
                                <Popconfirm
                                    title="Sei sicuro?" 
                                    onConfirm={() => {
                                        const request: ChangeStatusRequest = {
                                            subscription_id: data.tableData[data.rowIndex].id,
                                            response_status: "decline"
                                        }
                                        changeStatus(request).then((res) => {
                                            if(res && res.subscription){
                                                window.location.reload()
                                            }else{
                                                message.error("Errore. Riprova più tardi.")
                                            }
                                        })
                                    }}
                                    onCancel={() => {}}
                                    okText="Si"
                                    cancelText="No"
                                >
                                    <Button type="default">
                                        Rifiuta
                                    </Button> 
                                </Popconfirm>
                            </div> :
                            null
                        }
                    </div>
                }
            }
        ]
    }
    return []

}