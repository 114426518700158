import { connect } from 'react-redux'
import { Store } from '../reducers'
import {Dispatch} from 'react'
import {Action} from '../actions'
import HeaderItem from '../components/header/Header'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        SetLogged: (logged: boolean) => dispatch({type: "SET_LOGGED", logged: logged}),
        openNavbar: () => dispatch({type: "SET_NAVBAR_IS_COLLAPSED", collapsed: false})
    }
}

const mapStateToProps = (state: Store) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderItem)