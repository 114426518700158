import "./Table.css"
import React from "react";
import MUIDataTable, {Responsive} from "mui-datatables";
import { ResponsiveTableColumnType } from "./responsiveTable";
import { rowsPerTablePage } from "../api/constants";
import { createMuiTheme, MuiThemeProvider, Theme } from '@material-ui/core/styles';

interface ServerSideTableProps {
    columns: ResponsiveTableColumnType[]
    data: Object[],
    totalDataLength: number,
    changeSort: (s: string) => void,
    changePage: (p: number) => void,
    changeSearch: (s: string) => void,
    expandableRows?: boolean,
    search?: boolean
    print?: boolean
    download?: boolean
    viewColumns?: boolean
    pagination?: boolean
}

const ServerSideTable: React.FC<ServerSideTableProps> = ({
    columns,
    data,
    totalDataLength,
    changePage,
    changeSort,
    changeSearch,
    expandableRows,
    search,
    print,
    download,
    viewColumns,
    pagination
}) => {

    const getMuiTheme = () => createMuiTheme({
        overrides: {
          MUIDataTable: {
            root: {
            },
            paper: {
              boxShadow: "none",
            }
          },
          MUIDataTableBodyRow: {
            root: {
              '&:nth-child(odd)': { 
                backgroundColor: '#f5f5f5'
              }
            }
          },
          MUIDataTableBodyCell: {
          }
        }
    } as any)

    let options: Object = {
        selectableRows: 'none' as 'none',
        filter: false,
        serverSide: true,
        responsive: 'standard' as Responsive,
        isRowSelectable: () => false,
        expandableRows: expandableRows ?? false,
        rowsPerPage: rowsPerTablePage,
        count: totalDataLength,
        rowsPerPageOptions: [],
        search: search ?? true,
        print: print ?? false,
        download: download ?? false,
        viewColumns: viewColumns ?? true,
        pagination: pagination ?? true,
        onTableChange: (action: string, tableState: any) => {
            switch (action) {
                case 'changePage':
                    changePage(tableState.page);
                    break;
                case 'sort':
                    let sort;
                    if(tableState.sortOrder.direction === 'asc') {
                        sort = tableState.sortOrder.name
                    } else {
                        sort = `-${tableState.sortOrder.name}`
                    }
                    changeSort(sort);
                    break;
                case 'search':
                    changeSearch(tableState.searchText);
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <MuiThemeProvider theme={(getMuiTheme() as Theme)}>
            <div className="verticalBorder" style={{marginBottom: "2%"}}>
                <MUIDataTable
                    title={""}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </div>
        </MuiThemeProvider>

    )
};

export default ServerSideTable;