import React from "react";
import MUIDataTable, {Responsive} from "mui-datatables";
import "./Table.css"
import CustomTag from "./pages/installation/summaryCards/customTag";
import {
    dateType,
    iconType,
    linkType,
    localeDateFormatter, numberType, statusDate,
    stringType,
    tagType, thresholdType
} from "../utilities/utilities";
import {Tag} from "antd";
import { createMuiTheme, MuiThemeProvider, Theme } from '@material-ui/core/styles';

interface ResponsiveTableProps {
    title?: string
    columns: ResponsiveTableColumnType[]
    data: Object[],
    expandableRows?: boolean,
    renderExpandableRow?: (rowData: any, rowMeta: any) => JSX.Element,
    icon?: boolean,
    count?: number
}

interface ResponsiveTableCellProps {
    type: string
    value: string
    color?: string
    link?: (...args: any[]) => void
    icon?: React.ReactNode
}

export type ResponsiveTableColumnType = {
    name: string,
    label: string,
    options: {
        filter: boolean,
        sort: boolean,
        customBodyRender: (a: any, b: any, c: any) => React.ReactNode
    }
}

export const ResponsiveTableCell: React.FC<ResponsiveTableCellProps> = ({type, value, color, link, icon}) => {
    switch(type) {
        case stringType:
            return <div className="cell"> {value} </div>
        case thresholdType:
            let finalValue;
            const numVal = parseFloat(value);
            if (!isNaN(numVal) && numVal !== -1){
                finalValue = value
            } else {
                finalValue = '--'
            }
            return <div className="cell">{finalValue}</div>
        case tagType:
            return <div className="cell"> <CustomTag text={value} color={color ?? "primary"} /> </div>
        case dateType:
            return <div className="cell"> {localeDateFormatter(value)} </div>
        case linkType:
            return <a className="cell" onClick={link}> {value} </a>
        case iconType:
            return <a className="cell" onClick={link}> {icon} </a>
        case numberType:
            return <div className="cell"> {parseFloat(value).toFixed(2)} </div>
        case statusDate:
            if(new Date(value).getFullYear() === 1) {
                return <div className="cell"> <CustomTag color="orange" text="never" /> </div>
            }
            const minutesDiff = Math.abs((new Date().getTime() - new Date(value).getTime()) / (1000 * 60));
            if(minutesDiff < 10) {
                return <div className="cell"> <Tag color={"green"}>{localeDateFormatter(value)}</Tag> </div>
            } else {
                return <div className="cell"> <Tag color={"red"}>{localeDateFormatter(value)}</Tag> </div>
            }
        default:
            return <div className="cell"> {value} </div>
    }
}


const ResponsiveTable: React.FC<ResponsiveTableProps> = ({ title, columns, data, expandableRows, renderExpandableRow, icon, count }) => {
    
    const getMuiTheme = () => createMuiTheme({
        overrides: {
          MUIDataTable: {
            root: {
            },
            paper: {
              boxShadow: "none",
            }
          },
          MUIDataTableBodyRow: {
            root: {
              '&:nth-child(odd)': { 
                backgroundColor: '#f5f5f5'
              }
            }
          },
          MUIDataTableBodyCell: {
          }
        }
      } as any)

    const options = {
        selectableRows: 'none' as 'none',
        filter: false,
        download: false,
        print: false,
        responsive: 'standard' as Responsive,
        isRowSelectable: () => false,
        expandableRows: expandableRows ?? false,
        renderExpandableRow: expandableRows===true ? renderExpandableRow : undefined,
    }

    const hideIcons = {
        selectableRows: 'none' as 'none',
        isRowSelectable: () => false,
        expandableRows: expandableRows ?? false,
        renderExpandableRow: expandableRows===true ? renderExpandableRow : undefined,
        download: false,
        filter: false,
        print: false,
        search: false,
        viewColumns: false,
        responsive: 'standard' as Responsive
    }
    
    if(icon===false){
        return (
            <MuiThemeProvider theme={(getMuiTheme() as Theme)}>
                <div key="table_div" className="verticalBorder">
                    <MUIDataTable
                        title={title}
                        data={data ?? []}
                        columns={columns}
                        options={hideIcons}
                    />
                </div>            
            </MuiThemeProvider>
        )
    }else{
        return (
            <MuiThemeProvider theme={getMuiTheme()}>
                <div key="table_div" className="verticalBorder">
                    <MUIDataTable
                        title={title}
                        data={data ?? []}
                        columns={columns}
                        options={options}
                    />
                </div>                
            </MuiThemeProvider>
        )
    }  
};

export default ResponsiveTable;