import { createTheme } from "@material-ui/core";
import {lightTheme} from "./light";

const overrides = {
  typography: {
    fontFamily: [
      'Montserrat',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: "1.94rem",
    },
    h2: {
      fontSize: "1.714rem",
    },
    h3: {
      fontSize: "1.44rem",
    },
    h4: {
      fontSize: "1.286rem",
    },
    h5: {
      fontSize: "1.07rem",
    },
    h6: {
      fontSize: "0.98rem",
    },
  },
};

const themes: {[s: string]: any} = {
  light: createTheme({ ...lightTheme, ...overrides } as any),
};

export function getTheme(theme: string) {
  return themes[theme]
}
export default themes;
