import '../../App.css';
import React, {useEffect, useState} from "react";
import Navbar from "../../containers/Navbar";
import HeaderItem from "../../containers/HeaderItem";
import {Route, Switch, useHistory} from "react-router-dom";
import {
    dataHistoryPanelUrl,
    installationListToDataHistoryUrl,
    installationListToLineChartUrl,
    installationPageUrl,
    routes
} from "./costants";
import Profile from "./profile/Profile";
import CreateInstaller from "../../containers/pages/createInstaller/CreateInstaller";
import CreateCustomer from "../../containers/pages/createCustomer/CreateCustomer";
import InstallationPage from "../../containers/pages/installationPage/InstallationPage";
import CustomerListTable from "../../containers/pages/UserListTable";
import UpdateUserForm from "../../containers/pages/updateUser/UpdateUser";
import InstallerListTable from "../../containers/pages/InstallerTable";
import AddSubscription from "../../containers/pages/addSubscription/AddSubscription";
import UpdateSubscription from "../../containers/pages/updateSubscription/UpdateSubscription";
import InstallerCustomerRelationsPage from "../../containers/pages/InstallerCustomerRelationsPage";
import AddInstallation from "../../containers/pages/addInstallation/AddInstallation";
import InstallationListPage from "../../containers/pages/installationsListPages/InstallationListPage";
import UpdateInstallation from "../../containers/pages/updateInstallation/UpdateInstallation";
import AlarmTable from "../../containers/pages/alarmsPage/AlarmTable";
import DataHistoryPanel from "../../containers/pages/dataExportDownload/DataHistoryPanel";
import DeviceConnectionConfiguration
    from "../../containers/pages/deviceConnectionConfiguration/DeviceConnectionConfiguration";
import InstallationListSubscriptionSummary
    from "../../containers/pages/installationsListPages/InstallationsListSubscriptionSummary";
import {installationsListColumns} from "./installationsList/inputs";
import SubscriptionListToUpdate from "../../containers/pages/SelectSubscription";
import SubscriptionTable from "../../containers/pages/subscriptionsListPage/SubscriptionTable";
import Success from './addSubscription/Success';
import {GetUserProfile} from "../../api/services/userService";
import InstallationPageUser from './installation/InstallationPageUser';
import AlarmConfiguration from './alarmConfiguration/AlarmConfiguration';
import UpdateAlarmConfiguration from './updateAlarmConfiguration/UpdateAlarmConfiguration';
import UserListAdmin from './userListAdmin/UserListAdmin';
import SignalConfiguration from '../signalConfiguration/SignalConfiguration';
import ConfigurationList from './configurationList/ConfigurationList';
import Step from '../../containers/pages/installationPage/Step';
import {CircularProgress, useMediaQuery, useTheme} from "@material-ui/core";
import {useStyles} from "../../constants/muiStyles";
import clsx from "clsx";
import HomePage from "../../containers/pages/HomePage";
import AdminPanel from "./homePages/adminPanel";
import ConfiguratorPanel from "./homePages/configuratorPanel";

const InstallationsListToData = (props: any) => {
    const {id} = props.match.params;
    return <InstallationListSubscriptionSummary
        subscription_id={id}
        columns={installationsListColumns}
        redirectUrl={installationPageUrl}
    />
}

const InstallationsListToHistory = (props: any) => {
    const {id} = props.match.params;
    return <InstallationListSubscriptionSummary
        subscription_id={id}
        columns={installationsListColumns}
        redirectUrl={dataHistoryPanelUrl}
    />
}

const InstallationPageView = (props: any) => {
    const {id} = props.match.params;
    return <InstallationPage stair_id={id} />
}

const InstallationPageUserView = (props: any) => {
    const {id} = props.match.params;
    return <InstallationPageUser stair_id={id} />
}

const UpdateInstallationView = (props: any) => {
    const {id} = props.match.params;
    return <UpdateInstallation installationId={id} />
}

const UpdateSubscriptionView = (props: any) => {
    const {id} = props.match.params;
    return <UpdateSubscription subscriptionId={id}/>
}

const SubListToUpdateView = () => {
    return <SubscriptionListToUpdate/>
}

const SubListToInstallationView = () => {
    return <SubscriptionTable link={installationListToLineChartUrl} />
}

const SubListToDataHistory = () => {
    return <SubscriptionTable link={installationListToDataHistoryUrl} />
}

const DataHistoryView = (props: any) => {
    const {id} = props.match.params;
    return <DataHistoryPanel stair_id={id}/>
}

const UpdateUserView = (props: any) => {
    const {id} = props.match.params;
    return <UpdateUserForm user_id={id}/>
}

const AlarmConfigurationView = (props: any) => {
    const {id} = props.match.params;
    return <AlarmConfiguration installationId={id} />
}

const UpdateAlarmConfigurationView = (props: any) => {
    const {id} = props.match.params;
    return <UpdateAlarmConfiguration installationId={id} />
}

const SignalConfigurationView = (props: any) => {
    const {id} = props.match.params;
    return <SignalConfiguration installation_id={id} />
}

const ConfigurationListView = (props: any) => {
    const {id} = props.match.params;
    return <ConfigurationList installation_id={id} />
}

const DeviceConnectionConfigurationView = (props: any) => {
    const {id} = props.match.params;
    return <DeviceConnectionConfiguration stair_id={id} />
}

const StepView = (props: any) => {
    const {id, name} = props.match.params;
    return <Step stair_id={id} step_id={name} />
}

type MainPageProps = {
    role: string,
    navbar_collapsed: boolean
}

const MainPage: React.FC<MainPageProps> = ({ role }) => {
    document.body.style.backgroundColor = "white";
    document.body.style.background = "white";

    const history = useHistory();

    const classes = useStyles();
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        GetUserProfile().then(res => {
            setLoading(false);
            if (!res || !res.user) {
                history.push("/login");
            }
        }).catch(
            () => {
                history.push("/login");
                setLoading(false);
            }
        )
    }, []);

    if(loading) {
        return (
            <div
                style={{width: "100wv", height: "100vh", display: "flex", justifyContent:"center", alignItems: "center"}}
            >
                <CircularProgress
                    size={100}
                    style={{color: "white"}}
                />
            </div>
        )
    }

    return (
        <>
            <Navbar/>
            <HeaderItem/>
            <div
                className={
                    isMobile ?
                        clsx(classes.content) :
                        clsx(classes.content, {[classes.contentShift]: true})
                }
            >
                <Switch>
                    <Route exact path={routes.mainPage} component={HomePage} />
                    <Route exact path={routes.profile} component={Profile} />
                    <Route exact path={routes.adminPanel} component={AdminPanel} />
                    <Route exact path={routes.configuratorPanel} component={ConfiguratorPanel} />
                    <Route exact path={routes.addInstaller} component={CreateInstaller} />
                    <Route exact path={routes.addCustomer} component={CreateCustomer} />
                    <Route exact path={routes.installationPage} component={role==="viewer" ?  InstallationPageUserView : InstallationPageView} />
                    <Route exact path={routes.customersList} component={CustomerListTable} />
                    <Route exact path={routes.updateUser} component={UpdateUserView} />
                    <Route exact path={routes.installersList} component={InstallerListTable} />
                    <Route exact path={routes.installationsListToLineChart} component={InstallationsListToData} />
                    <Route exact path={routes.installationsListToDataHistory} component={InstallationsListToHistory} />
                    <Route exact path={routes.addSubscription} component={AddSubscription} />
                    <Route exact path={routes.subscriptionsListToUpdate} component={SubListToUpdateView} />
                    <Route exact path={routes.updateSubscription} component={UpdateSubscriptionView} />
                    <Route exact path={routes.subscriptionsListToInstallationPage} component={SubListToInstallationView} />
                    <Route exact path={routes.subscriptionsListToDataHistory} component={SubListToDataHistory} />
                    <Route exact path={routes.installersAssociations} component={InstallerCustomerRelationsPage} />
                    <Route exact path={routes.addInstallation} component={AddInstallation} />
                    <Route exact path={routes.installationsListToUpdate} component={InstallationListPage} />
                    <Route exact path={routes.updateInstallation} component={UpdateInstallationView} />
                    <Route exact path={routes.alarms} component={AlarmTable} />
                    <Route exact path={routes.alarmsConfiguration} component={AlarmConfigurationView} />
                    <Route exact path={routes.alarmsUpdate} component={UpdateAlarmConfigurationView} />
                    <Route exact path={routes.dataHistoryPanel} component={DataHistoryView} />
                    <Route exact path={routes.deviceConnectionConfiguration} component={DeviceConnectionConfigurationView} />
                    <Route exact path={routes.success} component={Success} />
                    <Route exact path={routes.userListAdmin} component={UserListAdmin} />
                    <Route exact path={routes.configurationSignals} component={SignalConfigurationView} />
                    <Route exact path={routes.configurationList} component={ConfigurationListView} />
                    <Route exact path={routes.stepUrl} component={StepView} />
                </Switch>
            </div>
        </>
    )
}
 
export default MainPage;