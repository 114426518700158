import { Col, Divider, Form, message, Radio, Row } from "antd";
import { InputType, switchLevel } from "../../types/form/types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AlarmConfiguration, Stair } from "../../../api/requests/stairsService";
import { getStair, UpdateStairAlarm } from "../../../api/services/stairsService";
import TitleWithBack from "../../../containers/TitleWithBack";
import ButtonConfItem from "../../ButtonConfItem";
import ButtonItem from "../../ButtonItem";
import FormInput from "../../input/FormInput";
import FormSelect from "../../select/FormSelect";
import {levelOptions, corrente_5, corrente_80, presenza_5, presenza_80, temperatura_alimentatore, attivazione_impianto} from "./inputs";
import "./UpdateAlarmConfiguration.css"

type UpdateAlarmConfigurationProps = {
    installationId: string
}

const UpdateAlarmConfiguration: React.FC<UpdateAlarmConfigurationProps> = ({ installationId }) => {
    const [checkbox, setCheckbox] = useState(false)
    const [stair, setStair] = useState<Stair | null>(null)
    useEffect(() => {
        getStair({id: installationId}).then((res) => {
            if(res && !res.err){
                setStair(res.stair)
            }
        })
    }, [])
    
    const [form] = Form.useForm();
    const history = useHistory();
    const submit = () => {
        form.validateFields().then(
            values => {
                console.log(values)
                const json = {
                    "temp": {
                        "enabled": Boolean(values.enabled__temperatura_alimentatore) === true ? 1 : 0,
                        "level": Number(values.level__temperatura_alimentatore),
                        "max": Number(values.upper_threshold__temperatura_alimentatore),
                        "min": Number(values.lower_threshold__temperatura_alimentatore),
                        "delay_open": Number(values.delay_open__temperatura_alimentatore),
                        "delay_close": Number(values.delay_close__temperatura_alimentatore)
                    },
                    "V80": {
                        "enabled": Boolean(values.enabled__presenza_80) === true ? 1 : 0,
                        "level": Number(values.level__presenza_80),
                        "max": Number(values.upper_threshold__presenza_80),
                        "min": -1,
                        "delay_open": Number(values.delay_open__presenza_80),
                        "delay_close": Number(values.delay_close__presenza_80)
                    },
                    "V5": {
                        "enabled": Boolean(values.enabled__presenza_5) === true ? 1 : 0,
                        "level": Number(values.level__presenza_5),
                        "max": Number(values.upper_threshold__presenza_5),
                        "min": -1,
                        "delay_open": Number(values.delay_open__presenza_5),
                        "delay_close": Number(values.delay_close__presenza_5)
                    },
                    "C80": {
                        "enabled": Boolean(values.enabled__corrente_80) === true ? 1 : 0,
                        "level": Number(values.level__corrente_80),
                        "max": Number(values.upper_threshold__corrente_80),
                        "min": Number(values.lower_threshold__corrente_80),
                        "delay_open": Number(values.delay_open__corrente_80),
                        "delay_close": Number(values.delay_close__corrente_80)
                    },
                    "C5": {
                        "enabled": Boolean(values.enabled__corrente_5) === true ? 1 : 0,
                        "level": Number(values.level__corrente_5),
                        "max": Number(values.upper_threshold__corrente_5),
                        "min": Number(values.lower_threshold__corrente_5),
                        "delay_open": Number(values.delay_open__corrente_5),
                        "delay_close": Number(values.delay_close__corrente_5)
                    },
                    "activation_alarm": {
                        "enabled": Boolean(values.enabled__attivazione_impianto) === true ? 1 : 0,
                        "delay_open": Number(values.delay_open__attivazione_impianto),
                        "delay_close": Number(values.delay_close__attivazione_impianto),
                        "notification_enabled": checkbox,
                        "notification_email": values.notification_email__attivazione_impianto
                    }
                }
                UpdateStairAlarm(installationId, json).then((res) => {
                    if(res && !res.err){
                        message.success("Allarme modificato correttamente.")
                        history.goBack()
                    }else{
                        message.error(res.err?.message || "Errore.")
                    }
                })
            }
        )
    }

    if(!stair){
        return null
    }

    const onChangeCheckBox = () => {setCheckbox(!checkbox)}

    const formFields = (inputs: InputType[], title: string) => {
        const currentAlarm: AlarmConfiguration[] = stair.alarms.filter(item => String(item.name) === String(title))

        if(currentAlarm.length === 0){
            return null
        }

        return inputs.map((el, index) => {
            return(
                el.type === "radio" ?
                <Col span={24} key={index} >
                    <Form.Item rules={el.rules || undefined} name={el.name} initialValue={Number(currentAlarm[0].status) === 0 ? false : true }>
                        <Radio.Group key={el.name}>
                            <Radio value={true}> Abilitato </Radio>
                            <Radio value={false}> Disabilitato </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                : el.type === "select" ?
                <Col span={12} key={index} >
                    <FormSelect  
                        key={el.key} 
                        name={el.name}
                        keyValue={el.key} 
                        options={levelOptions} 
                        placeholder={el.label} 
                        value={switchLevel(Number(currentAlarm[0].level))}
                    />
                </Col> :
                <Col span={12} key={index} >
                    <FormInput
                        key={el.key}
                        value={el.type === "checkbox" ? checkbox : (currentAlarm[0] as any)[(el.name).split("__")[0]]}
                        name={el.name}
                        keyValue={el.key}
                        placeholder={el.label}
                        type={(el.type === undefined)? "text" : el.type}
                        rules={el.rules ?? []}
                        onchange={el.type === "checkbox" ? onChangeCheckBox : null}
                    />
                </Col>
            )  
        })
    }

    return(
        <>
            <TitleWithBack title={"Calibrazione Allarmi"} key={"UpdateAlarmConfiguration"} />
            <div className="my-update-alarm-container  my-update-alarm-container-responsive " key={"update_alarm_configuration_div"}>
                <Form layout="vertical" key={1} form={form} name="update_alarm_configuration_form">
                    <Divider orientation={"left"} key={"corrente_5"}> Corrente assorbita alimentatore 5 Vdc </Divider>
                    <Row gutter={24} key={"corrente_5_row"}>
                        { formFields(corrente_5, "C5") }
                    </Row>
                    <Divider orientation={"left"} key={"corrente_80"}> Corrente assorbita alimentatore 80 Vdc </Divider>
                    <Row gutter={24} key={"corrente_80_row"}>
                        { formFields(corrente_80, "C80") }
                    </Row>
                    <Divider orientation={"left"} key={"presenza_5"}> Presenza tensione alimentazione schede 5 Vdc </Divider>
                    <Row gutter={24} key={"presenza_5_row"}>
                        { formFields(presenza_5, "V5") }
                    </Row>
                    <Divider orientation={"left"} key={"presenza_80"}> Presenza tensione alimentazione motori 80 Vdc </Divider>
                    <Row gutter={24} key={"presenza_80_row"}>
                        { formFields(presenza_80, "V80") }
                    </Row>
                    <Divider orientation={"left"} key={"temperatura_alimentatore"}> Temperatura alimentatore </Divider>
                    <Row gutter={24} key={"temperatura_alimentatore_row"}>
                        { formFields(temperatura_alimentatore, "temp") }
                    </Row>
                    <Divider orientation={"left"} key={"attivazione_impianto"}> Attivazione dell’impianto inizio e fine ciclo </Divider>
                    <Row gutter={24} key={"attivazione_impianto_row"}>
                        { formFields(attivazione_impianto, "activation_alarm") }
                    </Row>                    
                    <div className="btn-container" key={"add_installation_btn"}>
                        <ButtonItem buttonType="primary" label="Invio" buttonOnClick={submit} />
                    </div>
                </Form>
            </div>
        </>
    )
}

export default UpdateAlarmConfiguration;