import {ResponsiveTableCell, ResponsiveTableColumnType} from "../../responsiveTable";
import "../../../App.css"
import {dateType, linkType, stringType} from "../../../utilities/utilities";
import CustomTag from "../installation/summaryCards/customTag";

export const installationsListColumns: (linkFunction: (value: string) => void) => ResponsiveTableColumnType[] = linkFunction => [
    {
        label: "Nome",
        name: "name",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) =>
                <ResponsiveTableCell
                    type={linkType}
                    value={value}
                    link={() => linkFunction(data.tableData[data.rowIndex].id)}
                />
        }
    },
    {
        label: "Modello",
        name: "model",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value}
                />
        }
    },
    {
        label: "Descrizione",
        name: "description",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value}
                />
        }
    },
    {
        label: "Data Creazione",
        name: "created_at",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={dateType}
                    value={value}
                />
        }
    },
    {
        label: "Stato",
        name: "status",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) => {
                if(value === "online"){
                    return <div className="cell">
                        <CustomTag color="green" text="Online" /> 
                    </div>
                }else{
                    return <div className="cell">
                        <CustomTag color="red" text="Offline" /> 
                    </div>
                }
            }
        }
    },
]

export const updateInstallationTableColumns: (linkFunction: (value: string) => void) => ResponsiveTableColumnType[] = linkFunction => [
    ...installationsListColumns(linkFunction),
    {
        label: "Azienda",
        name: "company",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value}
                />
        },
    },
    {
        label: "Cliente",
        name: "customer",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) =>
                <ResponsiveTableCell
                    type={stringType}
                    value={value}
                />
        },
    }
]