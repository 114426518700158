export const sendChannels = {
    ztc_check_discover: "ztc_check_discover",
    firmware_download: "firmware_download",
    ztc_change_connection: "ztc_change_connection",
    ztc_discover: "ztc_discover",
    ztc_phys_id: "ztc_phys_id",
    ztc_provision_prepare: "ztc_provision_prepare",
    ztc_provision_command: "ztc_provision_command",
    ztc_burn: "ztc_burn",
    ztc_erase: "ztc_device_erase"
}

export const receiveChannels = {
    ztc_check_discover_result: "ztc_check_discover_result",
    firmware_download_result: "firmware_download_result",
    ztc_change_connection_result: "ztc_change_connection_result",
    ztc_discover_result: "ztc_discover_result",
    ztc_phys_id_result: "ztc_phys_id_result",
    ztc_provision_prepare_result: "ztc_provision_prepare_result",
    ztc_provision_command_result: "ztc_provision_command_result",
    ztc_burn_result: "ztc_burn_result",
    ztc_erase_result: "ztc_device_erase_result"
}

export const select = {
    label: "Seleziona la tipologia di connettività",
    name: "connection_type",
    placeholder: "...",
    options: [
        {label: "WiFi", value: "wifi", disabled: false},
        {label: "GSM", value: "gsm", disabled: false}
    ]
}

export const wifi = [
    {
        label: "Nome rete",
        name: "sid",
        requiredLabel: "Inserire Nome rete!",
        placeholder: "Nome rete",
        type: "text"
    },
    {
        label: "Password",
        name: "password",
        requiredLabel: "Inserire Password !",
        placeholder: "Password",
        type: "password"
    }
]

export const gsm = {
    label: "Seleziona l'operatore telefonico",
    requiredLabel: "Inserire operatore telefonico!",
    name: "operator",
    placeholder: "...",
    options: [
        {label: "Vodafone", value: "mobile.vodafone.it"},
        {label: "TIM", value: "ibox.tim.it"},
        {label: "Wind", value: "internet.wind"},
        {label: "Things Mobile", value: "TM"}
    ]
}

export const resultStatus = {
    success: "success",
    error: "error"
}

export const resultTitle = {
    success: "L'operazione è stata completata con successo",
    error: "L'operazione non è andata a buon fine"
}