import { InputType } from "../../types/form/types";

export const customerInfo: InputType[] = [
    {
        key: "customer_name",
        name: "customer_name",
        label: "Nome",
        path: "name"
    },
    {
        key: "customer_phone",
        name: "customer_phone",
        label: "Telefono",
        path: "phone",
        type: "number"
    },
    {
        key: "customer_address",
        name: "customer_address",
        label: "Indirizzo",
        path: "address"
    },
    {
        key: "customer_city",
        name: "customer_city",
        label: "Città",
        path: "city"
    },
    {
        key: "customer_province",
        name: "customer_province",
        label: "Provincia",
        path: "province"
    },
    {
        key: "customer_country",
        name: "customer_country",
        label: "Paese",
        path: "country"
    },
    {
        key: "customer_zip_code",
        name: "customer_zip_code",
        label: "CAP",
        path: "zip_code",
        type: "number"
    }
]

export const companyInfo: InputType[] = [
    {
        key: "company_name",
        name: "company_name",
        path: "name",
        label: "Nome"
    },
    {
        key: "company_sdi",
        name: "company_sdi",
        path: "sdi",
        label: "SDI"
    },
    {
        key: "company_pec",
        name: "company_pec",
        label: "PEC",
        path: "pec"
    },
    {
        key: "company_vat_number",
        name: "company_vat_number",
        label: "VAT",
        path: "vat_number",
        type: "number"
    },
    {
        key: "company_phone",
        name: "company_phone",
        label: "Telefono",
        path: "phone",
        type: "number"
    },
    {
        key: "company_address",
        name: "company_address",
        label: "Indirizzo",
        path: "address",
    },
    {
        key: "company_city",
        name: "company_city",
        label: "Città",
        path: "city",
    },
    {
        key: "company_province",
        name: "company_province",
        label: "Provincia",
        path: "province",
    },
    {
        key: "company_country",
        name: "company_country",
        label: "Paese",
        path: "country",
        type: "select"
    },
    {
        key: "company_zip_code",
        name: "company_zip_code",
        label: "CAP",
        path: "zip_code"
    }
]