import '../charts/lineChart/LineChart.css'
import { ChartObject } from '../../../../api/requests/timeSeries';
import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import {Aggregation} from "../../../../actions/lineChart";
import ResponsiveTable from '../../../responsiveTable';
import { tabValuesColumns } from './inputs';

const { TabPane } = Tabs;

type TabValuesProps = {
    data_chart: ChartObject
}

const TabValues: React.FC<TabValuesProps> = ({ data_chart }) => {

	const [tablist, setTablist] = useState<any[]>([])
	useEffect(() => {
		let tmpTabList: any[] = []
		if(data_chart?.avg && data_chart?.avg.length > 0){
			tmpTabList = [
				...tmpTabList,
				{
					key: "avg",
					tab: "Media"
				}
			]
		}
		if(data_chart?.max && data_chart?.max.length > 0){
			tmpTabList = [
				...tmpTabList,
				{
					key: "max",
					tab: "Massimo"
				}
			]
		}
		if(data_chart?.min && data_chart?.min.length > 0){
			tmpTabList = [
				...tmpTabList,
				{
					key: "min",
					tab: "Minimo"
				}
			]
		}
		if(data_chart?.sum && data_chart?.sum.length > 0){
			tmpTabList = [
				...tmpTabList,
				{
					key: "sum",
					tab: "Somma"
				}
			]
		}
		setTablist(tmpTabList)
	}, [])

	return(
		<Tabs className="tabStyle" defaultActiveKey="chart">
			{
				tablist.map((value, index)=>
					<TabPane tab={value.tab} key={index}>
						<ResponsiveTable columns={tabValuesColumns} data={(data_chart[value.key as Aggregation]||[]).filter(d => d?.value)} icon={false} />
					</TabPane>
				)
			}
		</ Tabs>
    )
}

export default TabValues;