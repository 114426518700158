import { connect } from 'react-redux'
import { Store } from '../../../reducers'
import {Dispatch} from 'react'
import {Action} from '../../../actions'
import CreateCustomer from '../../../components/pages/createCustomer/createCustomer'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        SetItem: (item: string) => dispatch({type: "SET_SELECTED_ITEM", item: item})
    }
}

const mapStateToProps = (state: Store) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomer)