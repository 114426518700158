import infoReducer from "./infoReducer"
import dataChartReducer from "./dataChartReducer"
import {combineReducers} from "redux"
import { StateType } from 'typesafe-actions';
import navbar from "./navbar";

const rootReducer = combineReducers({
    info: infoReducer,
    chart: dataChartReducer,
    navbar
})

//const rootReducer = Reducer

export default rootReducer;
export type Store = StateType<typeof rootReducer>;