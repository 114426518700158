import React, { useCallback, useEffect, useState } from "react";
import '../../navbar/Navbar.css'
import '../../../App.css'
import TitleWithBack from "../../../containers/TitleWithBack";
import SubscriptionSummary from "../../../containers/pages/SubscriptionSummary";
import {ListSubscriptionStairsRequest, Subscription} from "../../../api/requests/subscription";
import {getSubscription, getSubscriptionStairs} from "../../../api/services/subscriptionService";
import {ResponsiveTableColumnType} from "../../responsiveTable";
import { useHistory } from "react-router";
import ServerSideTable from "../../ServerSideTable";

type InstallationsListProps = {
    subscription_id: string
    redirectUrl: (id: string) => string
    columns: (linkFunction: (value: string) => void) => ResponsiveTableColumnType[]
}

const InstallationsListSubscriptionSummary: React.FC<InstallationsListProps> = ({subscription_id, redirectUrl, columns}) => {
    
    document.body.style.background = "#f5f5f5c7"
    
    const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null)
    useEffect( () => {
        getSubscription({subscription_id}).then(
            res => {
                if (res && res.subscription) {
                    setSelectedSubscription(res.subscription)
                }
            }
        )
    }, [])

    const [tableData, setTableData] = useState<Object[]>([]);
    const [totalDataLength, setTotalDataLength] = useState<number>(0);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [currentSort, setCurrentSort] = useState<string>("name");
    const [currentSearch, setCurrentSearch] = useState<string>('');

    const getStairs = useCallback((page: number, sort: string, search: string) => {
        getSubscriptionStairs({subscription_id: subscription_id} as ListSubscriptionStairsRequest, page.toString(), sort, search).then(
            res => {
                if(res && res.stairs)  {
                    setTableData(res.stairs)
                    setTotalDataLength(res?.total_count ?? 0 );
                } else {
                    setTableData([]);
                    setTotalDataLength(0);
                }
            }
        )
    }, [currentPage, currentSort]);

    useEffect(() => {
        getStairs(currentPage, currentSort, currentSearch);
    }, [])

    const changePage = (page: number) => {
        setCurrentPage(page);
        getStairs(page, currentSort, currentSearch);
    }

    const changeSort = (sort: string) => {
        setCurrentSort(sort);
        getStairs(currentPage, sort, currentSearch);
    }

    const changeSearch = (search: string) => {
        setCurrentSearch(search);
        setCurrentPage(0);
        getStairs(0, currentSort, search);
    }

    const history = useHistory();
    const redirectFun: (value: string) => void = (value: string) => {
        history.push(redirectUrl(value))
    }

    return(
        <>
            <TitleWithBack title={"Lista Installazioni"}/>
            <SubscriptionSummary subscription={selectedSubscription}/>
            <ServerSideTable
                columns={columns(redirectFun)}
                data={tableData}
                totalDataLength={totalDataLength}
                changeSort={changeSort}
                changePage={changePage}
                changeSearch={changeSearch}
            />
        </>
    )
}

export default InstallationsListSubscriptionSummary;