export const avg = "avg"
export const max = "max"
export const min = "min"
export const sum = "sum"

export const aggregationsLabels = {
    avg: "Media",
    max: "Massimo",
    min: "Minimo",
    sum: "Somma"
}