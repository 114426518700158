import { InputType } from "../../types/form/types";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

export const inputs: InputType[] = [
    {   
        key: "email", 
        name: "email",
        label: "Email",
        icon: <UserOutlined className="site-form-item-icon" />
    },
    {   
        key: "password",
        name: "password",
        label: "Password",
        type: "password",
        icon: <LockOutlined className="site-form-item-icon" />            
    }
]