import { connect } from 'react-redux'
import SelectSubscription from '../../components/pages/select_subscription/SelectSubscription'
import { Store } from '../../reducers'
import {Dispatch} from 'react'
import {Action} from '../../actions'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
    }
}

const mapStateToProps = (state: Store) => {
    return {
        role: state.info.role,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectSubscription)