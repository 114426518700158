import {
    addInstallationTitle,
    addInstallerTitle,
    addSubscriptionTitle,
    addUserTitle,
    adminPanelTitle, alarmsTitle,
    associationsInstallersTitle, ConfiguratorTitle,
    dataHistoryTitle,
    dataRealTimeTitle,
    updateInstallationTitle,
    updateInstallerTitle,
    updateSubscriptionTitle,
    updateUserTitle,
} from "../title";

import data from "./img/graph.png";
import download from "./img/cloud-download.png";
import alert from "./img/alert.png";
import admin from "./img/admin.png";
import installer from "./img/installer.png";
import user from "./img/user.png";
import people from "./img/people.png";
import change from "./img/change.png";
import device from "./img/device.png";
import configurator from "./img/inst.png"
import list_user from "./img/list_user.png";

import {routes, userListAdminUrl} from "../costants";

export type IconCard = {
    img: string
    text: string[]
    link: string[]
};

export const roleBasedIcons: (r: string) => IconCard[] = (r) => {
    if(r=== "installer" ) {
        return [
            {
                img: configurator,
                text: [ConfiguratorTitle],
                link: [routes.configuratorPanel]
            }
        ]
    } else if (r==="admin") {
        return [
            {
                img: admin,
                text: [adminPanelTitle],
                link: [routes.adminPanel]
            }
        ]
    } else {
        return []
    }
}

export const homePageIconCards: (r: string) => IconCard[] = (r) => [
    {
        img: data,
        text: [dataRealTimeTitle],
        link: [routes.subscriptionsListToInstallationPage]
    },
    {
        img: download,
        text: [dataHistoryTitle],
        link: [routes.subscriptionsListToDataHistory]
    },
    {
        img: alert,
        text: [alarmsTitle],
        link: [routes.alarms]
    },
    ...roleBasedIcons(r)
];


export const adminPanelIconCards: IconCard[] = [
    {
        img: installer,
        text: [addInstallerTitle, updateInstallerTitle],
        link: [routes.addInstaller, routes.installersList]
    },
    {
        img: people,
        text: [associationsInstallersTitle],
        link: [routes.installersAssociations]
    },
    {
        img: change,
        text: [updateSubscriptionTitle],
        link: [routes.subscriptionsListToUpdate]
    },
    {
        img: list_user,
        text: ["Lista Utenti"],
        link: [userListAdminUrl]
    }
    // {
    //     img: wires,
    //     text: [addWireTitle, updateWireTitle],
    //     link: [routes.addWire, routes.wireListToUpdateWire]
    // }
];


export const configuratorPanelIconCards: IconCard[] = [
    {
        img: user,
        text: [addUserTitle, updateUserTitle],
        link: [routes.addCustomer, routes.customersList]
    },
    {
        img: change,
        text: [addSubscriptionTitle, updateSubscriptionTitle],
        link: [routes.addSubscription, routes.subscriptionsListToUpdate]
    },
    {
        img: device,
        text: [addInstallationTitle, updateInstallationTitle],
        link: [routes.addInstallation, routes.installationsListToUpdate]
    }
];