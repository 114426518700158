import "./resetPassword.css"
import "antd/dist/antd.css"
import { Result } from "antd"
import React, { useEffect, useState } from "react"
import logo from '../../../logo/logo_intero_trasparente.png'

type ResultResetPasswordProps = {
}

const ResultResetPassword: React.FC<ResultResetPasswordProps> = () => {
    document.body.style.backgroundColor = "#5bc3d0"
    const [width, setWidth] = useState<number>(window.innerWidth);
	function handleWindowSizeChange() {
			setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

    return (
        <>
            <div className={width <= 768 ? "recover-box-responsive" : "recover-box"}>
                <div className="image-container">
                    <img src={logo} className="image" />
                </div>
                <Result
                    status="success"
                    title="Email inviata correttamente"
                    subTitle="Controlla la tua email per proseguire con il recupero della password."
                />
            </div>
        </>
    )
}

export default ResultResetPassword;