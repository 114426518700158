import { message, Popconfirm } from 'antd'
import { DeleteTwoTone } from "@ant-design/icons";
import { stringType } from "../../../utilities/utilities";
import { ResponsiveTableCell, ResponsiveTableColumnType } from "../../responsiveTable";
import { DeleteUserInfo } from '../../../api/services/userService';

export const customerInfo: (getCustomers: any, currentPage: number, currentSort: string, currentSearch: string) => ResponsiveTableColumnType[] = (getCustomers, currentPage, currentSort, currentSearch) => [
    {
        label: "Nome",
        name: "name",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) => 
                <ResponsiveTableCell 
                    type={stringType}  
                    value={value} 
                />
        }
    },
    {
        label: "Email",
        name: "email",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) => 
                <ResponsiveTableCell 
                    type={stringType} 
                    value={value} 
                />
        }
    },
    {
        label: "Telefono",
        name: "user_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => 
                <ResponsiveTableCell 
                    type={stringType} 
                    value={data.tableData[data.rowIndex].user_info.phone} 
                />
        }
    },
    {
        label: "Indirizzo",
        name: "user_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => 
                <ResponsiveTableCell 
                    type={stringType} 
                    value={data.tableData[data.rowIndex].user_info.address} 
                />
        }
    },
    {
        label: "Città",
        name: "user_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => 
                <ResponsiveTableCell 
                    type={stringType} 
                    value={data.tableData[data.rowIndex].user_info.city} 
                />
        }
    },
    {
        label: "Provincia",
        name: "user_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => 
                <ResponsiveTableCell 
                    type={stringType} 
                    value={data.tableData[data.rowIndex].user_info.province} 
                />
        }
    },
    {
        label: "Paese",
        name: "user_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => 
                <ResponsiveTableCell 
                    type={stringType} 
                    value={data.tableData[data.rowIndex].user_info.country} 
                />
        }
    },
    {
        label: "CAP",
        name: "user_info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => 
                <ResponsiveTableCell 
                    type={stringType} 
                    value={data.tableData[data.rowIndex].user_info.zip_code} 
                />
        }
    },
    {
        label: "Elimina",
        name: "",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string, data: any) => 
                <div className="cell"> 
                    <Popconfirm 
                        placement="topRight"
                        title={<><p>Sei sicuro ?</p><p>L'utente verrà eliminato definitivamente.</p></>} 
                        okText={"Si"} 
                        cancelText={"No"} 
                        onCancel={() => {}} 
                        onConfirm={ () => {
                            const user_id = data.tableData[data.rowIndex].uid
                            DeleteUserInfo(user_id).then((res) => {
                                if(res && !res.err){
                                    message.success("Utente rimosso con successo.")
                                    getCustomers(currentPage, currentSort, currentSearch)
                                }else{
                                    message.error(res?.err?.message || "Errore." )
                                }
                            })
                        }}
                    >
                        <DeleteTwoTone/>
                    </Popconfirm>  
                </div>
        }
    }
]